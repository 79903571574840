export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function displayWithSuffix(num) {
  let number = num.toString();
  if (number.endsWith("11")) return number + "th";
  if (number.endsWith("12")) return number + "th";
  if (number.endsWith("13")) return number + "th";
  if (number.endsWith("1")) return number + "st";
  if (number.endsWith("2")) return number + "nd";
  if (number.endsWith("3")) return number + "rd";
  return number + "th";
}
export function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
} 