import React, { useState } from "react";
import { findIndex } from "lodash";
import clsx from "clsx";
import moment from "moment";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Grid,
  Button,
  makeStyles,
  Input,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  IconButton,
} from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

import { ReactComponent as TagHot } from "../../svgs/Tag-Hot.svg";
import { ReactComponent as TagCold } from "../../svgs/Tag-Cold.svg";
import { ReactComponent as TagWarm } from "../../svgs/Tag-warm.svg";
import {
  StyledButtonDisabled,
  StyledButton,
  StyledButtonGradient,
} from "../components/StyledButtons";
import { useHistory } from "react-router-dom";
import {
  selectActiveUser,
  selectPlans,
  setActiveUser,
} from "../../modules/users";
import { ResetDialog, EmailUpdateDialog, SubscriptionDialog } from "./dialogs";
import { useUserInfo } from "../../modules/users/effects";

const useStyles = makeStyles((theme) => ({
  titleMargin: {
    marginTop: 20,
  },
  tableRow: {
    borderRadius: 8,
  },
  table: {
    minWidth: 730,
    borderSpacing: "1px 1em",
    borderCollapse: "separate",
  },
  tableCell: {
    borderBottom: 0,
    backgroundColor: "white",
  },
  fakeLabel: {
    fontWeight: 600,
    fontSize: "0.8rem",
  },
  minWidthField: {
    minWidth: 350,
    display: "flex",
    justifyContent: "flex-start",
  },
  subscriptionSize: {
    minWidth: 200,
  },
}));

const getTagType = (type) => {
  switch (type) {
    case "hot":
    case "Hot":
      return <TagHot />;
    case "cold":
    case "Cold":
      return <TagCold />;
    case "warm":
    case "Warm":
      return <TagWarm />;
    default:
      return <TagHot />;
  }
};
const getPlan = (plans, planId) => {
  const index = findIndex(plans, { id: planId });
  if (index > -1) {
    return plans[index];
  } else {
    return plans[4]; // Free trial
  }
};

export default () => {
  const user = useSelector(selectActiveUser);
  const plans = useSelector(selectPlans);

  const { details } = useUserInfo(user);

  const payments = details.payments || [];

  const { plan_id } = user;
  const planId = 1;
  const plan = getPlan(plans, planId);

  const [passwordOpen, setPasswordOpen] = useState(false);
  const [emailDialOpen, setEmailOpen] = useState(false);
  const [subscriptionOpen, setSubscriptionOpen] = useState(false);
  const [emailState, setChangeEmailState] = useState({
    email: user.email,
    original: user.email,
    error: "",
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleUpdateEmail = async () => {
    const data = {
      first_name: user.first_name,
      last_name: user.last_name,
      role_id: user.role_id,

      email: emailState.email,
    };
    console.log(data);
    try {
      console.log(axios.defaults);
      const endpoint = `/admin/user/${user.id}`;
      console.log("URL, ", endpoint);
      let res = await axios.put(endpoint, data, {
        headers: {
          Accept: "application/json",
        },
      });
      console.log(res);
      dispatch(setActiveUser(res.data.data));
      setChangeEmailState({ ...emailState, original: emailState.email });
    } catch (e) {
      // e = error 400, admin already has email
    }
  };

  return (
    <Grid container alignItems="flex-start" direction="column" spacing={3}>
      {/* Title Container */}
      <Grid direction="row" container justify="flex-start" alignItems="center">
        <Box>
          <IconButton onClick={() => history.goBack()}>
            <ChevronLeft />
          </IconButton>
        </Box>
        <Box ml={1}>
          <Typography variant="h3">
            {user.first_name + " " + user.last_name}
          </Typography>
        </Box>
        <Box ml={1}>{getTagType(user.temperature)}</Box>
      </Grid>

      {/* Info, Subscription Section */}
      <Grid
        className={classes.titleMargin}
        direction="row"
        container
        align="center"
        justify="space-between"
      >
        {/* General info */}
        <Box>
          <Grid container direction="column" alignItems="flex-start">
            <Typography variant="subtitle1" color="textSecondary">
              User Info
            </Typography>
            <Box mt={1}>
              <Typography align="left" variant="body2" color="textPrimary">
                {`Joined on ${moment(user.created_at).format("L")}`}
              </Typography>
            </Box>
            <Box
              flexDirection="column"
              mt={2}
              mb={2}
              display="flex"
              alignItems="flex-start"
            >
              <Typography className={classes.fakeLabel}>
                Email Address
              </Typography>
              <Paper
                className={classes.minWidthField}
                // className={clsx(errors.email && classes.errorBackground)}
                // elevation={errors.email ? 0 : 1}
              >
                <Input
                  id="email-display"
                  value={emailState.email}
                  onChange={(e) => {
                    const v = e.target.value;
                    setChangeEmailState({ ...emailState, email: v });
                  }}
                ></Input>
              </Paper>
            </Box>
          </Grid>
        </Box>
        {/* subscription */}
        <Box className={classes.subscriptionSize}>
          <Grid container direction="column" alignItems="flex-start">
            <Typography variant="subtitle1" color="textSecondary">
              Subscription Info
            </Typography>
            <Box mt={2}>
              <Typography align="left" variant="body1" color="textPrimary">
                {`${plan.name}: $${plan.amount}`}
              </Typography>
            </Box>
          </Grid>
        </Box>
      </Grid>
      {/* Button Section */}
      <Grid direction="row" container align="center" justify="space-between">
        <Box display="flex" direction="row">
          {emailState.email !== emailState.original ? (
            <StyledButtonGradient onClick={handleUpdateEmail}>
              Update Email
            </StyledButtonGradient>
          ) : (
            <StyledButtonDisabled disabled>Update Email</StyledButtonDisabled>
          )}
          <Box ml={1}>
            <StyledButton onClick={() => setPasswordOpen(true)}>
              Reset Password
            </StyledButton>
          </Box>
        </Box>

        {/* <StyledButton onClick={() => setSubscriptionOpen(true)}>
          Update Subscription
        </StyledButton> */}
      </Grid>

      {/* Payment Section */}
      <Grid container justify="center">
        {payments.length > 0 ? (
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={clsx(classes.tableCell)}>
                    Date
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    Amount
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    Status
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    className={classes.tableCell}
                    align="left"
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.map((payment, index) => {
                  const { date, amount, status } = payment;
                  return (
                    <TableRow
                      elevation={3}
                      component={(props) => <Paper component="tr" {...props} />}
                      className={classes.tableRow}
                      key={index}
                    >
                      <TableCell
                        className={classes.tableCell}
                        component="th"
                        scope="row"
                      >
                        {date}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        {`$${amount}`}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        {status}
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        className={classes.tableCell}
                        align="left"
                      ></TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        <Button>
                          <ArrowDownward />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography color="textSecondary" variant="subtitle1">
            No Payment History
          </Typography>
        )}
      </Grid>

      {/* dialogs */}
      <ResetDialog open={passwordOpen} setOpen={setPasswordOpen} />
      <EmailUpdateDialog open={emailDialOpen} setOpen={setEmailOpen} />
      {/* <SubscriptionDialog
        open={subscriptionOpen}
        setOpen={setSubscriptionOpen}
      /> */}
    </Grid>
  );
};
