import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { sortBy } from "lodash";
import {
  setAutomated,
  setUserPopups,
  addUserPopups,
  updateUserPopups,
  deleteUserPopups,
} from ".";
import moment from "moment";

export const HOME_TYPE = "home guides";
export const GYM_TYPE = "gym guides";

export const useAutomated = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const getAutomated = async () => {
      try {
        const response = await axios.get("/admin/automated-pop-ups", {
          params: {
            page,
          },
        });
        const { data } = response;
        const retrievedItems = data.data;
        // const homeTypes = retrievedItems.filter(
        //   (item) => item.type === SUPPLEMENT_TYPE
        // );
        // const items = retrievedItems.filter(
        //   (item) => item.type === ACCESSORY_TYPE
        // );
        dispatch(setAutomated(retrievedItems));
        // dispatch(setAccessory(items));
        // setTotal(data.meta.total);
      } catch (e) {}
    };

    getAutomated();
  }, [page]);

  return {
    total,
    page,
    setPage,
  };
};

export const useUserPopups = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const getPopups = async () => {
      try {
        const response = await axios.get("/admin/promotional-pop-ups", {
          params: {
            page,
          },
        });
        const { data } = response;
        const retrievedItems = data.data;
        // const homeTypes = retrievedItems.filter(
        //   (item) => item.type === SUPPLEMENT_TYPE
        // );
        // const items = retrievedItems.filter(
        //   (item) => item.type === ACCESSORY_TYPE
        // );
        const fixedDates = retrievedItems.map((item) => ({
          ...item,
          from_date: moment(item.from_date),
          to_date: moment(item.to_date),
        }));
        dispatch(setUserPopups(fixedDates));
        // dispatch(setAccessory(items));
        // setTotal(data.meta.total);
      } catch (e) {}
    };

    getPopups();
  }, [page]);

  return {
    total,
    page,
    setPage,
  };
};

export const createHandleAddPopup = (id, popup, dispatch) => {
  return async () => {
    const {
      id,
      title,
      background_image,
      supporting_image,
      popup_title,
      button_text,
      message,
      to_date,
      from_date,
    } = popup;
    const data = new FormData();

    data.append("popup_title", popup_title);
    data.append("button_text", button_text);
    data.append("message", message);
    data.append("title", title);
    data.append("from_date", from_date.format("YYYY-MM-DD"));
    data.append("to_date", to_date.format("YYYY-MM-DD"));

    // Create New Link if id is undefined
    if (isNaN(id)) {
      data.append("background_image", background_image);
      data.append("supporting_image", supporting_image);
      try {
        const response = await axios.post("/admin/promotional-pop-ups", data, {
          headers: {
            Accept: "application/json",
          },
        });

        const item = response.data.data;
        const fixedItem = {
          ...item,
          from_date: moment(item.from_date),
          to_date: moment(item.to_date),
        };
        dispatch(addUserPopups(fixedItem));
      } catch (e) {
        console.log("Failed to create popup");
      }
    } else {
      try {
        if (typeof background_image !== "string") {
          data.append("background_image", background_image);
        }
        if (typeof supporting_image !== "string") {
          data.append("supporting_image", supporting_image);
        }
        const response = await axios.post(
          `/admin/promotional-pop-ups/${id}`,
          data,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );

        const updatedItem = response.data.data;
        const fixedItem = {
          ...updatedItem,
          from_date: moment(updatedItem.from_date),
          to_date: moment(updatedItem.to_date),
        };
        dispatch(updateUserPopups(id, fixedItem));
      } catch (e) {
        console.log("Failed to update pop up");
      }
    }
  };
};

export const createHandleDeletePopup = (id, index, dispatch, remove) => {
  return async () => {
    // If it is a new field don't send request just remove for list
    if (isNaN(id)) {
      remove(index);
    } else {
      try {
        await axios.delete(`/admin/promotional-pop-ups/${id}`);
        dispatch(deleteUserPopups(id));
      } catch (e) {
        console.log("Failed to delete popup");
      }
    }
  };
};
