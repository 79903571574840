import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { UniqueSection } from "./UniqueSection";
import { TutorialSection } from "./TutorialSection";
import { ImageTypes } from "./constants";
import { useSettingsImages } from "../../modules/setting-images/effects";

export default () => {
  const [imageType, setImageType] = useState(ImageTypes.TUTORIAL);
  useSettingsImages();
  const handleSetTab = (e) => {
    const type = e.target.value;
    setImageType(type);
  };

  return (
    <Grid container justify="space-between" alignItems="center">
      {imageType === ImageTypes.UNIQUE ? (
        <UniqueSection imageType={imageType} handleSetTab={handleSetTab} />
      ) : (
        <TutorialSection imageType={imageType} handleSetTab={handleSetTab} />
      )}
    </Grid>
  );
};
