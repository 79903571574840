import React, { useState, useEffect } from "react";
import { Box, Grid, makeStyles, Typography, Hidden } from "@material-ui/core";
import axios from "axios";

import QuickInfoItem from "./QuickInfoItem";
import { ReactComponent as YoutubeLink } from "../../svgs/YoutubeLinks.svg";
import { ReactComponent as NotificationsSent } from "../../svgs/NotificationsSent.svg";
import { ReactComponent as RegisteredUsers } from "../../svgs/RegisteredUsers.svg";
import { ReactComponent as FitnessTerms } from "../../svgs/FitnessTerms.svg";
import { ReactComponent as GlossayIcon } from "../../svgs/GlossaryIcon.svg";
import { ReactComponent as TotalAdmins } from "../../svgs/TotalAdmins.svg";
import { getToken } from "../../modules/authentication";
import { useSelector } from "react-redux";
import { rehydration } from "../../modules/store";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 60,
    display: "flex",
    flex: 1,
    alignItems: "start",
    flexDirection: "column",
  },
  shiftRight: {
    // marginLeft: theme.spacing(4),
  },
}));

const useInfoCount = () => {
  const token = useSelector(getToken);
  const [counts, setCounts] = useState({});

  useEffect(() => {
    const getInfo = async () => {
      try {
        await rehydration();
        const userStats = await axios.get("/admin/stats/users");
        const contentStats = await axios.get("/admin/stats/content");
        const info = { ...userStats.data, ...contentStats.data };
        setCounts(info);
      } catch (e) {
        console.log("Error fetching counts");
      }
    };
    getInfo();
  }, [token]);
  return counts;
};

export default () => {
  const classes = useStyles();
  const counts = useInfoCount();
  return (
    <Hidden mdDown>
      <Grid
        className={classes.root}
        item
        container
        alignContent="center"
        justify="center"
      >
        <Grid
          className={classes.shiftRight}
          container
          alignItems="center"
          justify="flex-start"
          direction="column"
        >
          <Box p={1} mt={2}>
            <Box my={1}>
              <Typography color="textSecondary" align="left">
                Main Counts
              </Typography>
            </Box>
            <QuickInfoItem
              Icon={RegisteredUsers}
              info="Registered Users"
              count={counts.users}
            />
            <QuickInfoItem
              Icon={NotificationsSent}
              info="Notifications Sent"
              count={counts.notifications_sent}
            />
            <QuickInfoItem
              Icon={TotalAdmins}
              info="Total Admins"
              count={counts.admins}
            />
          </Box>
          <Box mt={2} p={1}>
            <Box my={1}>
              <Typography color="textSecondary" align="left">
                Content Counts
              </Typography>
            </Box>
            <QuickInfoItem
              Icon={YoutubeLink}
              info="Youtube Links"
              count={counts.youtube_links}
            />
            <QuickInfoItem
              Icon={FitnessTerms}
              info="Fitness Terms"
              count={counts.fitness_terms}
            />
            <QuickInfoItem
              Icon={GlossayIcon}
              info="Glossary"
              count={counts.glossary}
            />
          </Box>
        </Grid>
      </Grid>
    </Hidden>
  );
};
