import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { SvgIcon, Box, List, makeStyles, ButtonBase } from "@material-ui/core";
import NavItem from "./NavItem";

// Svgs for nav
import { ReactComponent as Notification } from "../../svgs/Navigation-PN.svg";
import { ReactComponent as NotificationInactive } from "../../svgs/Navigation-PN inactive.svg";
import { ReactComponent as Learn } from "../../svgs/Navigation-Learn Content.svg";
import { ReactComponent as LearnInactive } from "../../svgs/Navigation-Learn inactive.svg";
import { ReactComponent as Popup } from "../../svgs/Navigation-Pop Ups.svg";
import { ReactComponent as PopupInactive } from "../../svgs/Navigation-Pop Ups inactive.svg";
import { ReactComponent as Gym } from "../../svgs/Navigation-Gym.svg";
import { ReactComponent as GymInactive } from "../../svgs/Navigation-Gym inactive.svg";
import { ReactComponent as Settings } from "../../svgs/Navigation-Settings.svg";
import { ReactComponent as SettingsInactive } from "../../svgs/Navigation-Settings inactive.svg";
import { ReactComponent as Shop } from "../../svgs/Navigation-Shop.svg";
import { ReactComponent as ShopInactive } from "../../svgs/Navigation-Shop inactive.svg";
import { ReactComponent as User } from "../../svgs/Navigation-User.svg";
import { ReactComponent as UserInactive } from "../../svgs/Navigation-User inactive.svg";
import { ReactComponent as AdminUser } from "../../svgs/Navigation-AU.svg";
import { ReactComponent as AdminUserInactive } from "../../svgs/Navigation-AU inactive.svg";
import { ReactComponent as Dash } from "../../svgs/Navigation-Dashboard.svg";
import { ReactComponent as DashInactive } from "../../svgs/Navigation-Dashboard inactive.svg";
import { ReactComponent as HomeActive } from "../../svgs/Navigation-home-active.svg";
import { ReactComponent as HomeInactive } from "../../svgs/Navigation-Home-inactive.svg";

const createNavItem = (link, title, activeIcon, inactiveIcon) => {
  return {
    link,
    activeIcon,
    inactiveIcon,
    title,
  };
};
const navItems = [
  // "Login",
  createNavItem("/dashboard", "Dashboard", Dash, DashInactive),
  createNavItem(
    "/notifications",
    "Notifications",
    Notification,
    NotificationInactive
  ),
  createNavItem("/popups", "Pop Ups", Popup, PopupInactive),
  createNavItem("/learning", "Learning", Learn, LearnInactive),
  createNavItem("/shop", "Shopping", Shop, ShopInactive),
  createNavItem("/guides", "Guides", Gym, GymInactive),
  createNavItem("/home-guides", "Home Guides", HomeActive, HomeInactive),
  createNavItem("/users", "users", User, UserInactive),
  createNavItem("/admin", "Admin", AdminUser, AdminUserInactive),
  createNavItem("/settings", "Settings", Settings, SettingsInactive),
];

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 65,
  },
  logo: {
    height: 50,
    width: 50,
    paddingTop: 10,
    paddingBottom: 10,
  },
  addBorder: {
    borderRightWidth: 1,
    borderRightColor: "#DDDDDD",
    borderRightStyle: "solid",
    justifyContent: "center",
    display: "flex",
    padding: 15,
  },
}));

export default () => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  return (
    <List className={classes.root}>
      <Box className={classes.addBorder}>
        <ButtonBase>
          <img src={process.env.PUBLIC_URL + "/img/slay.png"} alt="logo" />
        </ButtonBase>
      </Box>
      {navItems.map((item, index) => {
        const active = location.pathname.includes(item.link);
        return (
          <NavItem
            link={item.link}
            title={item.title}
            Icon={active ? item.activeIcon : item.inactiveIcon}
            active={active}
            key={item.link}
          />
        );
      })}
      <Box className={classes.addBorder}>
        <span></span>
      </Box>
    </List>
  );
};
