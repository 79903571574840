import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const StyledButtonGradient = withStyles({
  root: {
    marginTop: 12,
    background: "linear-gradient(#e93e66 0%, #f54e55 100%)",
    borderRadius: 26,
    border: 0,
    minHeight: 40,
    maxHeight: 48,
    color: "white",
    padding: "0 30px",
  },
  label: {
    // color: "white",
    fontWeight: "bold",
    "&$disabled": {
      color: "#A1A1A1",
    },
  },
  disabled: {
    background: "#D6D6D6",
  },
})(Button);

export default StyledButtonGradient;

const StyledButtonDisabled = withStyles({
  root: {
    marginTop: 12,
    background: "#D6D6D6",
    borderRadius: 26,
    border: 0,
    minHeight: 40,
    maxHeight: 48,
    padding: "0 30px",
  },
  label: {
    color: "#A1A1A1",
    fontWeight: "bold",
  },
})(Button);

const StyledButton = withStyles({
  root: {
    marginTop: 12,
    background: "#F8E7E7",
    borderRadius: 26,
    border: 0,
    minHeight: 40,
    maxHeight: 48,
    padding: "0 30px",
  },
  label: {
    color: "#F54E55",
    fontWeight: "bold",
  },
})(Button);

export { StyledButton, StyledButtonDisabled, StyledButtonGradient };
