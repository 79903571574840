import { createSelector } from "reselect";
import { first, uniq, sortBy } from "lodash";
import { put, all, call, delay, takeLatest, select } from "redux-saga/effects";
import axios from "axios";

/**
 * Constants
 */
export const LEVEL_BEGINNER = "beginner";
export const LEVEL_INTERMEDIATE = "intermediate";
export const LEVEL_ADVANCE = "advance";

export const PLAN_DAYS_THREE = "3-day-plan";
export const PLAN_DAYS_FOUR = "4-day-plan";
export const PLAN_DAYS_FIVE = "5-day-plan";
export const PLAN_DAYS_SIX = "6-day-plan";
export const PLAN_DAYS_SEVEN = "7-day-plan";

/**
 * Actions
 */

export const SET_DAY = "home/set_day";
export const setDay = (day) => ({
  type: SET_DAY,
  day,
});
export const SET_WEEK = "home/set_week";
export const setWeek = (week) => ({
  type: SET_WEEK,
  week,
});

export const SET_LEVEL = "home/set_level";
export const setLevel = (level) => ({
  type: SET_LEVEL,
  level,
});
export const SET_PLAN = "home/set_plan";
export const setPlan = (plan) => ({
  type: SET_PLAN,
  plan,
});

export const GET_ALL_GUIDES = "home/get_all_guides";
export const getAllGuides = () => ({
  type: GET_ALL_GUIDES,
});
export const SET_ALL_GUIDES = "home/set_all_guides";
export const setAllGuides = (guides) => ({
  type: SET_ALL_GUIDES,
  guides,
});

export const GET_GUIDE_TYPES = "home/get_guide_types";
export const getGuideTypes = () => ({
  type: GET_ALL_GUIDES,
});

export const SET_GUIDE_TYPES = "home/set_guide_types";
export const setGuideTypes = (types) => ({
  type: SET_GUIDE_TYPES,
  types,
});

export const GET_EXERCISE_GLOSSARY = "home/get_exercise_glossary";
export const getExerciseGlossary = () => ({
  type: GET_EXERCISE_GLOSSARY,
});

export const SET_EXERCISE_GLOSSARY = "home/set_exercise_glossary";
export const setExerciseGlossary = (exercises) => ({
  type: SET_EXERCISE_GLOSSARY,
  exercises,
});

export const GET_GUIDE_EXERCISES = "home/get_indvidual_exercises";
export const getGuideExercises = () => ({
  type: GET_GUIDE_EXERCISES,
});

export const SET_EXERCISES_FOR_GUIDE = "home/set_exercises_for_guide";
export const setExercisesForGuide = (id, exercises) => ({
  type: SET_EXERCISES_FOR_GUIDE,
  id,
  exercises,
});

export const UPDATE_GUIDE_BY_ID = "home/update_by_id";
export const updateGuideById = (id, guide) => ({
  type: UPDATE_GUIDE_BY_ID,
  id,
  guide,
});

export const ADD_GUIDE = "home/add_guide";
export const addGuide = (guide) => ({
  type: ADD_GUIDE,
  guide,
});

export const ADD_EXERCISE_TO_GUIDE = "home/add_exercise_to_guide";
export const addExerciseToGuide = (guideId, exercise) => ({
  type: ADD_EXERCISE_TO_GUIDE,
  guideId,
  exercise,
});

export const SWAP_EXERCISES_TO_GUIDE = "home/swap_exercise_to_guide";
export const swapGuideExercises = (guideId, moveData) => ({
  type: SWAP_EXERCISES_TO_GUIDE,
  guideId,
  moveData,
});

export const UPDATE_EXERCISE_TO_GUIDE = "home/update_exercise_to_guide";
export const updateExerciseToGuide = (guideId, id, exercise) => ({
  type: UPDATE_EXERCISE_TO_GUIDE,
  guideId,
  id,
  exercise,
});

export const DELETE_EXERCISE_TO_GUIDE = "home/delete_exercise_to_guide";
export const deleteExerciseToGuide = (guideId, id) => ({
  type: DELETE_EXERCISE_TO_GUIDE,
  guideId,
  id,
});

export const SET_OVERVIEW_IMAGES = "home/SET_OVERVIEW_IMAGES";

export const setOverviewImages = (images) => ({
  type: SET_OVERVIEW_IMAGES,
  images,
});

export const ADD_OVERVIEW_IMAGE = "home/ADD_OVERVIEW_IMAGE";
export const addOverviewImage = (image) => ({
  type: ADD_OVERVIEW_IMAGE,
  image,
});

export const SET_HOME_LEVELS = "home/SET_GUIDE_LEVELS";

export const setLevels = (levels) => ({
  type: SET_HOME_LEVELS,
  levels
});

export const ADD_LEVEL = "home/add_level";
export const addLevel = (level) => ({
  type: ADD_LEVEL,
  level,
});

export const UPDATE_OVERVIEW_IMAGE = "home/UPDATE_OVERVIEW_IMAGE";
export const updateOverviewImage = (id, image) => ({
  type: UPDATE_OVERVIEW_IMAGE,
  id,
  image,
});

export const DELETE_WEEK = "home/DELETE_WEEK";
export const deleteWeek = (level, plan, week) => ({
  type: DELETE_WEEK,
  level,
  plan,
  week,
});

export const RESET_DAY = "home/RESET_DAY";
export const resetDay = (id) => ({
  type: RESET_DAY,
  id,
});

export const SET_LOADING = "home/set_loading";
export const setLoading = (loading) => ({
  type: SET_LOADING,
  loading,
});

export const SET_RETRIEVE_COUNT = "home/set_count";
export const setCount = (count) => ({
  type: SET_RETRIEVE_COUNT,
  count,
});
/**
 * Reducer
 */
const initState = {
  // Path
  level: "beginner", // "itermediate", "advance",
  day: 1,
  week: 1,
  plan: "4-day-plan", // "3 day plan", "4 day plan", "5 day plan",

  isLoading: false,

  // Active guide in day builder
  activeGuideId: null,
  activeGuides: [],

  // Overall needed
  overviewImages: [],
  exercises: [],
  guideTypes: [],
  guides: [],
  levels: [],
  retrievedCount: 0,
};

export const homeReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, isLoading: action.loading };
    case SET_RETRIEVE_COUNT:
      return { ...state, retrievedCount: action.count };
    case SET_LEVEL:
      return { ...state, level: action.level };
    case SET_PLAN:
      return { ...state, plan: action.plan };
    case SET_WEEK:
      return { ...state, week: action.week };
    case SET_DAY:
      return { ...state, day: action.day };
    case SET_ALL_GUIDES:
      return { ...state, guides: action.guides };
    case ADD_GUIDE:
      return { ...state, guides: [...state.guides, action.guide] };
    case SET_GUIDE_TYPES:
      return { ...state, guideTypes: action.types };
    case SET_EXERCISE_GLOSSARY:
      return { ...state, exercises: action.exercises };
    case SET_EXERCISES_FOR_GUIDE:
      const updatedGuides = state.guides.map((guide) =>
        guide.id === action.id
          ? { ...guide, exercises: action.exercises }
          : guide
      );
      return { ...state, guides: updatedGuides };
    case SET_OVERVIEW_IMAGES:
      return { ...state, overviewImages: action.images };
    case ADD_OVERVIEW_IMAGE:
      return { ...state, overviewImages: [...state.overviewImages, action.image] }
    case UPDATE_OVERVIEW_IMAGE:
      const newOverviewImages = state.overviewImages.map((image) =>
        image.id === action.id ? action.image : image
      );
      return { ...state, overviewImages: newOverviewImages };
    case UPDATE_GUIDE_BY_ID:
      const updatedGuidesById = state.guides.map((g) => {
        return g.id === action.id ? action.guide : g;
      });
      return {
        ...state,
        guides: updatedGuidesById,
      };
    case ADD_EXERCISE_TO_GUIDE:
      const addedExercisesToGuide = state.guides.map((g) => {
        return g.id === action.guideId
          ? { ...g, exercises: [...g.exercises, action.exercise] }
          : g;
      });
      return {
        ...state,
        guides: addedExercisesToGuide,
      };
    case SWAP_EXERCISES_TO_GUIDE:
      const reorderedExerciseGuide = state.guides.map((g) => {
        const dragItem = action.moveData[0];
        const droppedItem = action.moveData[1];

        return g.id === action.guideId
          ? {
              ...g,
              exercises: sortBy(
                g.exercises.map((e) => {
                  if (e.id === dragItem.id) {
                    return { ...e, order: dragItem.order };
                  }
                  if (e.id === droppedItem.id) {
                    return { ...e, order: droppedItem.order };
                  }
                  return e;
                }),
                "order"
              ),
            }
          : g;
      });
      return {
        ...state,
        guides: reorderedExerciseGuide,
      };

    case UPDATE_EXERCISE_TO_GUIDE:
      const updatedExerciseInGuide = state.guides.map((g) => {
        return g.id === action.guideId
          ? {
              ...g,
              exercises: g.exercises.map((e) =>
                e.id === action.id ? action.exercise : e
              ),
            }
          : g;
      });
      return {
        ...state,
        guides: updatedExerciseInGuide,
      };
    case DELETE_EXERCISE_TO_GUIDE:
      const deletedExerciseInGuide = state.guides.map((g) => {
        return g.id === action.guideId
          ? {
              ...g,
              exercises: g.exercises.filter((e) => e.id !== action.id),
            }
          : g;
      });
      return {
        ...state,
        guides: deletedExerciseInGuide,
      };
    case DELETE_WEEK:
      const deletedWeeksGuides = state.guides.filter((g) => {
        return (
          g.week !== action.week ||
          g.level !== action.level ||
          g.plan !== action.plan
        );
      });
      const updatedWeeks = deletedWeeksGuides.map((g) => {
        if (
          g.level === action.level &&
          g.plan === action.plan &&
          g.week > action.week
        ) {
          return { ...g, week: g.week - 1 };
        }
        return g;
      });
      return {
        ...state,
        guides: updatedWeeks,
      };
    case RESET_DAY:
      const resetDayGuides = state.guides.map((g) => {
        return g.id === action.id
          ? {
              ...g,
              exercises: [],
            }
          : g;
      });
      return {
        ...state,
        guides: resetDayGuides,
      };
    case SET_HOME_LEVELS:
      return {...state, levels: action.levels};
    case ADD_LEVEL:
      return { ...state, levels: [...state.levels, action.level] };

    default:
      return state;
  }
};

/**
 * Sagas
 */

function* handleGetGuides() {
  try {
    const response = yield call(axios.get, "/admin/guides", {
      params: {
        type: "home",
      },
    });
    const { data } = response;
    const guides = data.data;
    yield put(setAllGuides(guides));
    yield put(getGuideExercises());

    const overviewImagesRes = yield call(axios.get, "/admin/guide/settings");

    let images = overviewImagesRes.data.data;
    images = images.filter((i) => i.type === "home");
    let levels = images.filter((i) => i.level !== "N/A");
    levels = levels.map((l) => l.level);
    yield put(setOverviewImages(images));
    yield put(setLevels(levels));
  } catch (e) {
    console.log(e);
  }
}

function* watchGetGuides() {
  yield takeLatest([GET_ALL_GUIDES], handleGetGuides);
}

function* handleGetGuideTypes() {
  try {
    // const response = yield call(axios.get, "/admin/guides/type");
    // const { data } = response;
    // const guides = data.data;
    // yield put(setAllGuides(guides));
  } catch (e) {
    console.log(e);
  }
}
function* watchGetGuideTypes() {
  yield takeLatest([GET_GUIDE_TYPES], handleGetGuideTypes);
}

function* handleGetAllExercises() {
  try {
    const response = yield call(axios.get, "/admin/learn/glossary");
    const { data } = response;
    let exercises = data.data;
    exercises = sortBy(exercises, "title");
    yield put(setExerciseGlossary(exercises));
  } catch (e) {
    console.log(e);
  }
}

function* watchGetAllExercises() {
  yield takeLatest([GET_EXERCISE_GLOSSARY], handleGetAllExercises);
}

function* handleGetExercisesForGuides(action) {
  try {
    const guides = yield select(selectAllGuides);
    for (let i = 0; i < guides.length; i++) {
      const guide = guides[i];
      const { id } = guide;
      yield delay(50);
      const response = yield call(axios.get, `/admin/guide/${id}/exercise`);
      const { data } = response;
      const exercises = data.data || [];
      const sortedExercises = sortBy(exercises, "order");
      // console.log(sortedExercises);
      yield put(setExercisesForGuide(id, sortedExercises));
      yield put(setCount(i + 1));
    }

    yield put(setLoading(false));
    yield put(setCount(0));
  } catch (e) {
    console.log(e);
    console.log("Retrying to retrieve Home Guide Data");
    yield delay(10000);
    yield put(getGuideExercises());
    yield put(setCount(0));
  }
}

function* watchSetWeek() {
  yield takeLatest([GET_GUIDE_EXERCISES], handleGetExercisesForGuides);
}

export function* rootSaga() {
  yield all([
    call(watchGetGuides),
    call(watchGetGuideTypes),
    call(watchGetAllExercises),
    call(watchSetWeek),
  ]);
}

/**
 *  Selectors
 */

export const selectAllGuides = (state) => state.home.guides;
export const selectAllGuidesLength = (state) => {
  if (state.home.guides) {
    return state.home.guides.length;
  }
  return 0;
};

export const selectLevel = (state) => state.home.level;
export const selectWeek = (state) => state.home.week;
export const selectPlan = (state) => state.home.plan;
export const selectDay = (state) => state.home.day;
export const isLoading = (state) => state.home.isLoading;
export const selectRetrieveCount = (state) => state.home.retrievedCount;

export const selectTerms = (state) => state.home.terms;
export const selectExercises = (state) => state.home.exercises;
export const selectGlossaryTypes = (state) => state.home.glossaryTypes;
export const selectActiveCategory = (state) => state.home.category;
export const selectActiveGuides = (state) => state.home.activeGuides;

export const selectExerciseGlossary = (state) => state.home.exercises;
export const selectOverviewImages = (state) => state.home.overviewImages || [];
export const selectLevels = (state) => state.home.levels || [];

export const selectNumberOfWeeks = createSelector(
  [selectAllGuides, selectLevel, selectPlan],
  (guides, level, plan) => {
    const filteredGuides = guides.filter(
      (g) => g.level === level && g.plan === plan
    );
    const weeks = filteredGuides.map((g) => g.week);
    return uniq(weeks) || [];
  }
);

export const selectGuidesForWeek = createSelector(
  [selectAllGuides, selectLevel, selectPlan, selectWeek],
  (guides, level, plan, week) => {
    const filteredGuides = guides.filter((g) => {
      return g.level === level && g.plan === plan && g.week === week;
    });

    return sortBy(filteredGuides, "day") || [];
  }
);

export const selectFilteredExercises = createSelector(
  [selectExercises, selectActiveCategory],
  (exercises, category) => {
    return exercises.filter((e) => e.glossary_type_id === category.id);
  }
);
