import React, { useState } from "react";
import clsx from "clsx";
import { ReactComponent as CheckedAcitve } from "../../svgs/icon-Checkbox Checked.svg";
import { ReactComponent as CheckedDisabled } from "../../svgs/icon-Checkbox Uncheck.svg";

import {
  Dialog,
  DialogTitle,
  Typography,
  makeStyles,
  Grid,
  List,
  DialogContent,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  withStyles,
} from "@material-ui/core";
import {
  StyledButton,
  StyledButtonGradient,
  StyledButtonDisabled,
} from "../components/StyledButtons";

const useStyles = makeStyles({
  resetModal: {
    height: 150,
    width: 300,
  },
  subscriptionModal: {
    height: 425,
    width: 400,
  },
  buttonWidth: {
    minWidth: 120,
  },
  round: {
    borderRadius: 16,
  },
  paper: {
    margin: 32,
    position: "relative",
    overflowY: "auto",
    borderRadius: 16,
  },
  centerList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

export const DeleteDialog = ({
  promptText,
  promptSuccess,
  open,
  setOpen,
  handleDeleteClick,
}) => {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const handleClick = async (e) => {
    let x = await handleDeleteClick();
    let v = await new Promise((resolve) => {
      setTimeout(() => {
        console.log("might have to do more here");
        resolve(true);
      }, 2000);
      setSuccess(true);
    });
  };

  const handleClose = () => setOpen(false);
  const handleSuccessClose = () => {
    setOpen(false);
    setSuccess(false);
  };

  return (
    <Dialog
      transitionDuration={{ exit: 0 }}
      classes={{ paper: classes.paper }}
      className={classes.round}
      open={open}
      onClose={handleClose}
    >
      <DialogContent className={classes.round}>
        <Grid
          className={classes.resetModal}
          container
          alignItems="center"
          direction="column"
          justify="space-between"
        >
          {!success ? (
            <>
              <Grid>
                <Typography align="center" variant="body1">
                  {promptText}
                </Typography>
              </Grid>
              <Grid container justify="space-between">
                <StyledButtonGradient
                  className={classes.buttonWidth}
                  onClick={handleClick}
                >
                  Yes
                </StyledButtonGradient>
                <StyledButton
                  className={classes.buttonWidth}
                  onClick={handleClose}
                >
                  No
                </StyledButton>
              </Grid>
            </>
          ) : (
            <>
              <Grid>
                <Typography align="center" variant="body1">
                  {promptSuccess}
                </Typography>
              </Grid>
              <Grid container justify="center">
                <StyledButtonGradient
                  className={classes.buttonWidth}
                  onClick={handleSuccessClose}
                >
                  Okay
                </StyledButtonGradient>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
