import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { FastField, Formik, Field, FieldArray } from "formik";
import { TextField } from "formik-material-ui";
import axios from "axios";

import { SimpleFileUpload } from "../components/FormikFile";
import DraggableGrid from "../components/DraggableGrid";
import { StyledButtonGradient } from "../components/StyledButtons";
import { NavigationLink } from "../components/TabNavigationLink";
import { getActiveState } from "../helpers";
import { useSelector, useDispatch } from "react-redux";
import {
  selectSupplements,
  selectSupplementBanner,
} from "../../modules/shopping";
import {
  createHandleAddShop,
  SUPPLEMENT_TYPE,
  createHandleDeleteShop,
} from "../../modules/shopping/effects";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  titleMargin: {
    marginTop: 20,
  },
  buttonResize: {
    height: 40,
  },
  spacing: { marginLeft: 24 },
  activeColor: {
    color: "#F54E55",
  },
  inactiveColor: {
    color: "#DDDDDD",
  },
  marginTop: {
    marginTop: 40,
  },
  section: {
    padding: 16,
  },
  sectionBase: {
    backgroundColor: "#F8F8F8",
    borderRadius: 12,
  },
}));

export const createEmptySupplement = () => ({
  title: "",
  link: "",
  image: null,
});

export default ({ parentUrl }) => {
  const classes = useStyles();
  const url = parentUrl;
  const supplements = useSelector(selectSupplements);
  const banner = useSelector(selectSupplementBanner);

  return (
    <>
      <Formik
        key={JSON.stringify(supplements)}
        initialValues={{
          supplements: supplements,
          bannerImage: null,
          bannerMessage: "",
        }}
      >
        {(formikProps) => {
          return (
            <FieldArray name="supplements">
              {(arrayHelpers) => {
                return (
                  <>
                    <Grid
                      className={classes.titleMargin}
                      direction="row"
                      container
                      justify="space-between"
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <NavigationLink
                          to={`${url}/overview`}
                          title={"Shop Overview"}
                        />
                        <Box ml={2}>
                          <NavigationLink
                            to={`${url}/supplements`}
                            title={"Bloomu Supplements"}
                          />
                        </Box>
                        <Box ml={2}>
                          <NavigationLink
                            to={`${url}/accessories`}
                            title={"Slay Accessories"}
                          />
                        </Box>
                      </Box>
                      {/* Need to add button up here for adding product line */}
                      <Box>
                        <StyledButtonGradient
                          onClick={() =>
                            arrayHelpers.push(createEmptySupplement())
                          }
                        >
                          Add Product Line
                        </StyledButtonGradient>
                      </Box>
                    </Grid>
                    <Grid container direction="column">
                      {formikProps.values.supplements.map(
                        (supplement, index) => {
                          const active = getActiveState(
                            formikProps,
                            "supplements",
                            index,
                            ["title", "link", "message"],
                            ["image"]
                          );
                          if (isEmpty(supplement)) {
                            return null;
                          }
                          return (
                            <SupplementForm
                              supplement={supplement}
                              buttonDisabled={!active}
                              moveItem={(dragIndex, hoverIndex) => {
                                // arrayHelpers.swap(dragIndex, hoverIndex)
                              }}
                              key={index}
                              index={index}
                              remove={arrayHelpers.remove}
                            />
                          );
                        }
                      )}
                    </Grid>
                  </>
                );
              }}
            </FieldArray>
          );
        }}
      </Formik>
    </>
  );
};

const BannerForm = ({ nameHelper, handleSave, buttonDisabled }) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
        className={classes.marginTop}
      >
        <Box alignItems="center" display="flex" flexDirection="row">
          <Typography>Promotion Banner</Typography>
        </Box>
        {/* <IconButton
          onClick={() => {
            console.log("delete banner click");
          }}
        >
          <DeleteIcon />
        </IconButton> */}
      </Grid>
      <Grid className={classes.sectionBase}>
        <Grid
          container
          direction="row"
          justify="space-between"
          className={classes.section}
        >
          <Box display="flex" flex={4}>
            <Field
              label={"Banner Image"}
              name={nameHelper("image")}
              component={SimpleFileUpload}
            />
          </Box>
          <Box justifyContent="flex-end" display="flex" flex={1} ml={1}>
            <StyledButtonGradient
              disabled={buttonDisabled}
              onClick={handleSave}
            >
              Save
            </StyledButtonGradient>
          </Box>
        </Grid>
        <Grid container className={classes.section}>
          <Field
            name={nameHelper("message")}
            component={TextField}
            InputProps={{
              disableUnderline: true,
            }}
            label="Description"
            fullWidth
            multiline
            rows="3"
          />
        </Grid>
      </Grid>
    </>
  );
};

const useSupplementStyles = makeStyles({
  section: {
    padding: 16,
  },
  marginTop: {
    marginTop: 20,
  },
  sectionBase: {
    backgroundColor: "#F8F8F8",
    borderRadius: 12,
  },
});
// Todo:: Add drag and drop functionality
const SupplementForm = ({
  index,
  supplement,
  remove,
  moveItem,
  buttonDisabled,
}) => {
  const classes = useSupplementStyles();
  const dispatch = useDispatch();
  const nameHelper = (name) => {
    return `supplements[${index}].${name}`;
  };

  const { title, id } = supplement;
  const handleDeleteClick = createHandleDeleteShop(id, index, dispatch, remove);

  const handleSaveClick = createHandleAddShop(
    id,
    supplement,
    dispatch,
    SUPPLEMENT_TYPE
  );

  if (title === "Promotion Banner") {
    // Render banner form with
    return (
      <BannerForm
        buttonDisabled={buttonDisabled}
        nameHelper={nameHelper}
        handleSave={handleSaveClick}
      />
    );
  }

  return (
    <DraggableGrid
      id={id || index + 100}
      index={index}
      moveItem={moveItem}
      container
      direction="column"
      className={classes.marginTop}
      deleteIcon
      handleDeleteClick={handleDeleteClick}
      TitleComponent={<Typography>{title}</Typography>}
    >
      <Grid className={classes.sectionBase}>
        <Grid
          container
          direction="row"
          justify="space-between"
          className={classes.section}
        >
          <Box display="flex" flex={2}>
            <FastField
              label="Section Name"
              name={`${nameHelper("title")}`}
              component={TextField}
              InputProps={{ disableUnderline: true }}
            />
          </Box>
          <Box display="flex" ml={1} flex={2}>
            <FastField
              label="Supporting Image"
              name={`${nameHelper("image")}`}
              component={SimpleFileUpload}
            />
          </Box>
          <Box justifyContent="flex-end" display="flex" flex={1} ml={1}>
            <StyledButtonGradient
              disabled={buttonDisabled}
              onClick={handleSaveClick}
              type="submit"
            >
              Save
            </StyledButtonGradient>
          </Box>
        </Grid>
        <Grid container className={classes.section}>
          <FastField
            name={`${nameHelper("link")}`}
            component={TextField}
            InputProps={{ disableUnderline: true }}
            label="Linkage"
            fullWidth
          />
        </Grid>
      </Grid>
    </DraggableGrid>
  );
};
