import React from "react";
import { keys, isEmpty } from "lodash";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { ReactComponent as TagHot } from "../../svgs/Tag-Hot.svg";
import { ReactComponent as TagCold } from "../../svgs/Tag-Cold.svg";
import { ReactComponent as TagWarm } from "../../svgs/Tag-warm.svg";
import { Formik, Field } from "formik";

import Clear from "@material-ui/icons/Clear";
import Search from "@material-ui/icons/Search";
import ChevronRight from "@material-ui/icons/ChevronRight";
import {
  Box,
  Grid,
  MenuItem,
  Button,
  makeStyles,
  Select,
  FormControl,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  TextField,
  InputAdornment,
  IconButton,
  TablePagination,
} from "@material-ui/core";
import NakedShiftLeft from "../components/NakedSelect";
import { useHistory } from "react-router-dom";
import { Filters, setActiveUser } from "../../modules/users";

import {
  useSiteUsers,
  handleExportSubmit,
} from "../../modules/users/effects";
import UserOverviewCard from "./UserOverviewCard";
import { DatePicker } from "formik-material-ui-pickers";
import moment from "moment";
import StyledButtonGradient from "../components/StyledButtons";

const useStyles = makeStyles((theme) => ({
  titleMargin: {
    marginTop: 20,
  },
  dropdownMenu: {
    height: 48,
    marginBottom: 40,
    marginTop: 40,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
  select: {
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: "25ch",
    borderBottomWidth: 0,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "flex-start",
  },
  tableRow: {
    borderRadius: 8,
  },
  table: {
    // minWidth: 730,
    borderSpacing: "3px 1em",
    borderCollapse: "separate",
  },
  tableCell: {
    borderBottom: 0,
    backgroundColor: "white",
  },
  selectInput: {
    background: 0,
    padding: "6px 0 7px",
    borderRadius: 0,
    margin: 0,
    minHeight: 0,
  },
  buttonMinWidth: {
    width: 105,
    display: "flex",
    marginTop: 0,
  },
}));

const getTagType = (type) => {
  switch (type) {
    case "hot":
    case "Hot":
      return <TagHot />;
    case "cold":
    case "Cold":
      return <TagCold />;
    case "warm":
    case "Warm":
      return <TagWarm />;
    default:
      return <TagHot />;
  }
};

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    counts,
    filter,
    setFilter,
    search,
    setSearch,
    users,
    total,
    page,
    setPage,
  } = useSiteUsers();

  const countItems = [
    {
      typeTitle: "Hot Users",
      count: counts[0],
      type: "Hot",
    },
    {
      typeTitle: "Warm Users",
      count: counts[1],
      type: "Warm",
    },
    {
      typeTitle: "Cold Users",
      count: counts[2],
      type: "Cold",
    },
  ];
  const handleRangeChange = (e) => {
    setFilter(e.target.value);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const handleResetSearch = () => {
    setSearch("");
  };

  const handleMouseDownSearch = (event) => {
    event.preventDefault();
  };
  const handleChangePage = (e, nextPage) => {
    setPage(nextPage + 1);
  };

  return (
    <Grid container alignItems="flex-start" direction="column" spacing={3}>
      <Grid direction="row" container justify="space-between">
        <Typography variant="h3">{"Users"}</Typography>
        <TextField
          InputProps={{ disableUnderline: true }}
          value={search}
          name="search-input"
          onChange={handleSearchChange}
          placeholder="Search For User"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleResetSearch}
                  onMouseDown={handleMouseDownSearch}
                >
                  <Clear />
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        ></TextField>
      </Grid>
      <Grid
        style={{
          backgroundColor: "#f8f8f8",
          marginTop: 20,
          borderRadius: 8,
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 5,
          paddingRight: 5,
        }}
        mt={1}
        direction="row"
        container
      >
        <Formik
          initialValues={{
            startDate: moment(),
            endDate: moment(),
          }}
          onSubmit={handleExportSubmit}
        >
          {(formikProps) => {
            return (
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <Box display="flex" flex={3}>
                  <Field
                    name={"startDate"}
                    // label="Start"
                    component={DatePicker}
                    labelFunc={(date, invalidString) => {
                      return moment(date).format("L");
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                  <p style={{ marginRight: 10 }}>-</p>
                  <Field
                    name={"endDate"}
                    // label="end"
                    component={DatePicker}
                    labelFunc={(date, invalidString) => {
                      return moment(date).format("L");
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Box>
                <Box display="flex" flex={1}>
                  <StyledButtonGradient
                    onClick={formikProps.handleSubmit}
                    type="submit"
                    className={classes.buttonMinWidth}
                  >
                    Export
                  </StyledButtonGradient>
                </Box>
              </Grid>
            );
          }}
        </Formik>
      </Grid>
      <Grid className={classes.titleMargin} container>
        {countItems.map((item, index) => (
          <UserOverviewCard {...item} key={index} />
        ))}
      </Grid>
      <Grid>
        <FormControl className={classes.dropdownMenu}>
          <Select
            className={classes.select}
            id="select-user-filter"
            value={filter}
            onChange={handleRangeChange}
            input={<NakedShiftLeft />}
            inputProps={{ className: classes.selectInput }}
          >
            {keys(Filters).map((k) => {
              const text = !isEmpty(Filters[k]) ? Filters[k] : "None";
              return (
                <MenuItem key={k} value={Filters[k]}>
                  {text}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={clsx(classes.tableCell)}>
                  User Name
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  Email Address
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  Last Login
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="left"
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => {
                const {
                  last_login,
                  id,
                  first_name,
                  last_name,
                  email,
                  temperature,
                } = user;
                const lastLogin = last_login
                  ? moment(last_login).format("L")
                  : "N/A";
                return (
                  <TableRow
                    elevation={3}
                    component={(props) => <Paper component="tr" {...props} />}
                    className={classes.tableRow}
                    key={id}
                  >
                    <TableCell
                      className={classes.tableCell}
                      component="th"
                      scope="row"
                    >
                      {first_name + " " + last_name}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left">
                      {email}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left">
                      {lastLogin}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left">
                      {getTagType(temperature)}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left">
                      <Button
                        onClick={() => {
                          dispatch(setActiveUser(user));
                          history.push("/users/" + id);
                        }}
                      >
                        <ChevronRight />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TablePagination
                  colSpan={8}
                  rowsPerPageOptions={[50]}
                  rowsPerPage={50}
                  count={total}
                  page={page - 1}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
