import React, { useState } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  Box,
  Grid,
  makeStyles,
  InputAdornment,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";

import { Formik, Field } from "formik";
import { useDispatch } from "react-redux";

import { TextField } from "formik-material-ui";
import {
  getCurrentUser,
  logoutUser,
  setToken,
} from "../../modules/authentication";
import { StyledButtonGradient } from "../components/StyledButtons";

import axios from "axios";
import { cancelSource } from "../../modules/axiosConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "85vh",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
  background: {
    backgroundImage: `url(${process.env.PUBLIC_URL + "/img/Bitmap.png"})`,
    backgroundSize: "cover",
    width: "100vw",
    height: "100vh",
  },
  minWidthField: {
    minWidth: 450,
  },
  fakeLabel: {
    fontWeight: 700,
    fontSize: "1rem",
  },
  errorBackground: {
    backgroundColor: "#FFD8D8",
  },
}));

const LoginForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = async (values, actions) => {
    try {
      console.log("Handling Login");
      let res = await axios.post("/v1/login", values, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      axios.defaults.headers = {
        Authorization: `Bearer ${res.data.accessToken}`,
      };

      dispatch(setToken(res.data.accessToken));
      dispatch(getCurrentUser());
      actions.setSubmitting(false);
      history.push("/dashboard");
    } catch (e) {
      console.log(e.response);
      let message = "Error in authentication";
      if (e.response) {
        message = e.response.data.errors.message;
      }
      actions.setErrors({ email: message });
      actions.setSubmitting(false);
    }
  };
  return (
    <div className={classes.background}>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={handleLogin}
      >
        {({ handleSubmit, errors }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container className={classes.root}>
                <img
                  src={process.env.PUBLIC_URL + "/img/slay@2x.png"}
                  alt="logo"
                />
                <Box
                  flexDirection="column"
                  mt={3}
                  mb={3}
                  display="flex"
                  alignItems="flex-start"
                >
                  <Typography className={classes.fakeLabel}>
                    Email Address
                  </Typography>
                  <Paper
                    className={clsx(errors.email && classes.errorBackground)}
                    elevation={errors.email ? 0 : 1}
                  >
                    <Field
                      name="email"
                      type="email"
                      component={TextField}
                      className={classes.minWidthField}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Paper>
                </Box>
                <Box
                  flexDirection="column"
                  display="flex"
                  alignItems="flex-start"
                >
                  <Typography className={classes.fakeLabel}>
                    Password
                  </Typography>
                  <Paper>
                    <Field
                      name="password"
                      type={showPassword ? "text" : "password"}
                      className={classes.minWidthField}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      component={TextField}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Paper>
                </Box>
                <Box mt={3}>
                  <StyledButtonGradient
                    className={classes.minWidthField}
                    type="submit"
                  >
                    Login
                  </StyledButtonGradient>
                </Box>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default () => {
  return <LoginForm />;
};
