import { put, all, call, delay } from "redux-saga/effects";

export const Tabs = {
  ANALYTICS: "analytics",
  LOGGED: "logged",
};

export const VisibilityFilters = {
  WEEK: "week",
  MONTH: "month",
  YEAR: "year",
};

export const SET_TAB = "DASH/SET_TAB";
export const setActiveTab = (tab) => ({
  type: SET_TAB,
  tab,
});

export const SET_FILTER = "DASH/SET_VISIBILITY_FILTER";
export const setVisibilityFilter = (filter) => ({
  type: SET_FILTER,
  filter,
});

const initState = {
  activeTab: Tabs.LOGGED, // logged, analytics
  activeFilter: VisibilityFilters.YEAR, // year, month, week
};

export const dashboardReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_TAB:
      return {
        ...state,
        activeTab: action.tab,
      };
    case SET_FILTER:
      return { ...state, activeFilter: action.filter };
    default:
      return state;
  }
};

export const selectTab = (state) => state.dashboard.activeTab;
export const selectFilter = (state) => state.dashboard.activeFilter;

function* simple() {
  yield delay(10000);
  console.log("Running example saga");
  // yield put({ type: "TEST"});
}

export default function* root() {
  yield all([call(simple)]);
}
