import React, { useState, useEffect } from "react";
import {
  useHistory,
  useRouteMatch,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  ButtonBase,
} from "@material-ui/core";

import { StyledButton } from "../components/StyledButtons";
import { NavigationLink } from "../components/TabNavigationLink";
import { logoutUser } from "../../modules/authentication";
import { LogoutDialog } from "./dialogs";
import EmailTab from "./email";
import PasswordTab from "./password";
import ImageTab from "./image";

const useStyles = makeStyles((theme) => ({
  titleMargin: {
    marginTop: 20,
  },
  buttonResize: {
    height: 40,
  },
  spacing: { marginLeft: 24 },
  activeColor: {
    color: "#F54E55",
  },
  inactiveColor: {
    color: "#DDDDDD",
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { url, path } = useRouteMatch();
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const handleLogout = () => {
    // dispatch(logoutUser());
    setOpen(true);
  };
  return (
    <Grid container alignItems="flex-start" direction="column" spacing={3}>
      <Grid direction="row" container justify="space-between">
        <Typography variant="h3">{"Settings"}</Typography>
        <StyledButton
          onClick={handleLogout}
          className={classes.buttonResize}
          onClick={handleLogout}
        >
          Logout
        </StyledButton>
      </Grid>
      <Grid
        className={classes.titleMargin}
        direction="row"
        container
        justify="flex-start"
      >
        <NavigationLink to={`${url}/email`} title={"Email Address"} />
        <Box ml={2}>
          <NavigationLink to={`${url}/password`} title={"Password"} />
        </Box>
        <Box ml={2}>
          <NavigationLink to={`${url}/images`} title={"Image Overview"} />
        </Box>
      </Grid>
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}/email`} />
        </Route>
        <Route path={`${path}/email`}>
          <EmailTab />
        </Route>
        <Route path={`${path}/password`}>
          <PasswordTab />
        </Route>
        <Route path={`${path}/images`}>
          <ImageTab />
        </Route>
      </Switch>
      <LogoutDialog open={open} setOpen={setOpen} />
    </Grid>
  );
};
