import { timePickerDefaultProps } from "@material-ui/pickers/constants/prop-types";
import { sortBy } from "lodash";

export const UNIQUE_TYPE = "unique image";
export const TUTORIAL_TYPE = "tutorial image";

/** Automated Push notification actions  */
export const SET_UNIQUE = "SETTINGS/SET_UNIQUE";
export const setUnique = (images) => ({
  type: SET_UNIQUE,
  images,
});
export const UPDATE_UNIQUE = "SETTINGS/UPDATE_UNIQUE";
export const updateUnique = (id, item) => ({
  type: UPDATE_UNIQUE,
  id,
  item,
});
export const DELETE_UNIQUE = "SETTINGS/DELETE_UNIQUE";
export const deleteUnique = (id) => ({
  type: DELETE_UNIQUE,
  id,
});

/** User Push notification actions  */
export const SET_TUTORIAL = "SETTINGS/SET_TUTORIAL";
export const setTutorials = (tutorials) => ({
  type: SET_TUTORIAL,
  tutorials,
});
export const UPDATE_TUTORIAL = "SETTINGS/UPDATE_TUTORIAL";
export const updateTutorials = (id, tutorial) => ({
  type: UPDATE_TUTORIAL,
  id,
  tutorial,
});

export const ADD_TUTORIAL = "SETTINGS/ADD_TUTORIAL";
export const addTutorial = (tutorial) => ({
  type: ADD_TUTORIAL,
  tutorial,
});
export const DELETE_TUTORIAL = "SETTINGS/DELETE_TUTORIAL";
export const deleteTutorial = (id) => ({
  type: DELETE_TUTORIAL,
  id,
});

export const SWAP_TUTORIALS = "SETTINGS/SWAP_TUTORIALS";
export const swapTutorials = (moveData) => ({
  type: SWAP_TUTORIALS,
  moveData,
});

const initState = {
  tutorials: [],
  unique: [],
};

export const settingImagesReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_UNIQUE:
      return { ...state, unique: action.images };
    case UPDATE_UNIQUE:
      const newUnique = state.unqiue.map((p) =>
        p.id === action.id ? action.item : p
      );
      return { ...state, unique: newUnique };
    case DELETE_UNIQUE:
      const filteredUnique = state.unique.filter((p) => p.id !== action.id);
      return { ...state, unique: filteredUnique };
    /* User Created */
    case SET_TUTORIAL:
      return { ...state, tutorials: action.tutorials };
    case UPDATE_TUTORIAL:
      const newTutorials = state.tutorials.map((p) =>
        p.id === action.id ? action.tutorial : p
      );
      return { ...state, tutorials: newTutorials };
    case DELETE_TUTORIAL:
      const filteredTutorials = state.tutorials.filter(
        (p) => p.id !== action.id
      );
      return { ...state, tutorials: filteredTutorials };
    case ADD_TUTORIAL:
      const addedTutorials = [...state.tutorials, action.tutorial];
      return { ...state, tutorials: addedTutorials };
    case SWAP_TUTORIALS:
      const dragItem = action.moveData[0];
      const droppedItem = action.moveData[1];
      const reorderedTutorials = state.tutorials.map((t) => {
        if (t.id === dragItem.id) {
          return { ...t, order: dragItem.order };
        }
        if (t.id === droppedItem.id) {
          return { ...t, order: droppedItem.order };
        }
        return t;
      });
      const resorted = sortBy(reorderedTutorials, "order");
      return { ...state, tutorials: resorted };
    default:
      return state;
  }
};

export const selectUniqueImages = (state) => state.settings.unique;
export const selectTutorialImages = (state) => state.settings.tutorials;
