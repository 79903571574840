import React, { useState, useEffect } from "react";
import { Grid, Box, makeStyles, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";

import { NavigationLink } from "../components/TabNavigationLink";
import { SimpleFileUpload, NoFormFileDisplay } from "../components/FormikFile";
import { StyledButtonGradient } from "../components/StyledButtons";
import { useDispatch, useSelector } from "react-redux";
import { selectOverviewImages } from "../../modules/learning";
import {
  createHandleUpdateOverviewImage,
  useLearnImages,
} from "../../modules/learning/effects";

const useStyles = makeStyles((theme) => ({
  titleMargin: {
    marginTop: 20,
  },
  dropdownMenu: {
    height: 48,
    marginBottom: 40,
    marginTop: 40,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
  select: {
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: "25ch",
    borderBottomWidth: 0,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "flex-start",
  },
  marginTop: {
    marginTop: 40,
  },
  buttonWidth: {
    minWidth: 300,
  },
  section: {
    padding: 16,
    paddingTop: 0,
  },
  sectionBase: {
    backgroundColor: "#F8F8F8",
    borderRadius: 12,
  },
}));

export default ({ parentUrl }) => {
  useLearnImages();
  const classes = useStyles();
  const url = parentUrl;
  const dispatch = useDispatch();
  const images = useSelector(selectOverviewImages);
  const handleSubmit = createHandleUpdateOverviewImage(dispatch);

  return (
    <>
      <Grid
        className={classes.titleMargin}
        direction="row"
        container
        justify="flex-start"
      >
        <NavigationLink to={`${url}/overview`} title={"Learn Overview"} />
        <Box ml={2}>
          <NavigationLink to={`${url}/links`} title={"YouTube Links"} />
        </Box>
        <Box ml={2}>
          <NavigationLink to={`${url}/dictionary`} title={"Dictionary"} />
        </Box>
        <Box ml={2}>
          <NavigationLink to={`${url}/glossary`} title={"Exercise Glossary"} />
        </Box>
      </Grid>
      {/* Automated type select */}

      <Grid container direction="column">
        {images.map(({ image, title, id }) => (
          <Grid key={id + title} container className={classes.marginTop}>
            <Typography variant="body1">{title}</Typography>
            <Grid container direction="column" className={classes.sectionBase}>
              <Formik
                key={id + title + JSON.stringify(image)}
                initialValues={{
                  image,
                  id,
                }}
                onSubmit={handleSubmit}
              >
                {(props) => {
                  const isDisabled = !props.dirty;
                  return (
                    <Form>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        className={classes.section}
                      >
                        <Box display="flex" flex={4}>
                          <Field name="image" component={SimpleFileUpload} />
                        </Box>
                        <Box
                          justifyContent="flex-end"
                          display="flex"
                          flex={1}
                          ml={1}
                        >
                          <StyledButtonGradient
                            disabled={isDisabled}
                            type="submit"
                          >
                            {isDisabled ? "Saved" : "Upload & Save"}
                          </StyledButtonGradient>
                        </Box>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
