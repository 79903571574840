/**
 * @author [Kyle Wilcox]
 * @email [wilcox@mail.com]
 * @create date 2020-04-10 15:00:22
 * @modify date 2020-04-10 15:00:22
 * @desc Contains the form that will be built depending
 * on the current path of the URL
 * TODO: Need to pop up modal to reset day and fix that
 */

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import keys from "lodash/keys";

import { Formik, FieldArray, Field, FastField } from "formik";
import { Select, TextField } from "formik-material-ui";
import axios from "axios";

import {
  Grid,
  Box,
  makeStyles,
  MenuItem,
  Typography,
  FormControl,
} from "@material-ui/core";

import { NavigationLink } from "../components/TabNavigationLink";
import NakedSelect from "../components/NakedSelect";
import DraggableGrid from "../components/DraggableGrid";
import {
  StyledButtonGradient,
  StyledButton,
} from "../components/StyledButtons";
import { DeleteExerciseDialog, ResetDayDialog } from "./dialogs";
import {
  selectExerciseGlossary,
  selectGuidesForWeek,
  swapGuideExercises,
} from "../../modules/guides";
import { useSelector, useDispatch } from "react-redux";
import { getActiveState } from "../helpers";
import {
  createHandleAddExercise,
  createHandleDeleteExercise,
  createHandleUpdateDay,
  createHandleResetDay,
} from "../../modules/guides/effects";

export const KEY_EXERCISES = "exercises";
export const KEY_TYPE = "type";
export const KEY_DAY_FORM = "dayForm";

export const WorkoutTypes = [
  { id: 1, type: "Push" },
  { id: 2, type: "Pull" },
  { id: 3, type: "Leg" },
  { id: 4, type: "Quads" },
  { id: 5, type: "Glute/ Ham" },
  { id: 6, type: "Touch Up" },
  { id: 8, type: "Upper" },
  { id: 9, type: "Lower" },
];
export const createBlankExercise = () => ({
  sets: "",
  reps: "",
  rep_label: "reps",
  name: "",
  exercise_id: 1,
  message: "",
});

const useStyles = makeStyles({
  titleMargin: {
    marginTop: 20,
  },
  marginTop: {
    marginTop: 40,
  },
  buttonWidth: {
    minWidth: 300,
  },
  section: {
    padding: 16,
  },
  sectionBase: {
    backgroundColor: "#F8F8F8",
    borderRadius: 12,
  },
  buttonResize: {
    height: 40,
  },
  dropdownMenu: {
    height: 48,
    marginBottom: 40,
    marginTop: 40,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
  select: {
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: "25ch",
    borderBottomWidth: 0,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "flex-start",
  },
  selectInput: {
    background: 0,
    padding: "6px 0 7px",
    borderRadius: 0,
    margin: 0,
    minHeight: 0,
  },
});

export default ({ parentUrl, formikProps }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const guides = useSelector(selectGuidesForWeek);
  const { days, day, week } = useParams();
  const [open, setOpen] = useState(false);
  const [added, setAdded] = useState(false);
  console.log("Added: ", added);

  const dayToNumber = Number.parseInt(day);
  const dayFormId = `${KEY_DAY_FORM}${dayToNumber - 1}`;

  const activeGuide = guides[dayToNumber - 1];
  const guideId = activeGuide.id;
  const handleChangeDayType = createHandleUpdateDay(
    guideId,
    activeGuide,
    dispatch
  );
  const handleResetDay = createHandleResetDay(activeGuide, dispatch);
  return (
    <Grid
      className="TestForm"
      container
      alignItems="flex-start"
      direction="column"
    >
      {/* Sub Form for the day */}
      <Formik
        // Key required to have multiple forms
        key={`form${days}${day}`}
        initialValues={formikProps.values[dayFormId]}
      >
        {(subFormikProps) => {
          return (
            <FieldArray name={KEY_EXERCISES}>
              {(arrayHelpers) => {
                return (
                  <>
                    {/* Navigation Section */}
                    <Grid
                      className={classes.titleMargin}
                      direction="row"
                      container
                      justify="space-between"
                    >
                      <Box
                        alignItems="center"
                        display="flex"
                        flexDirection={"row"}
                      >
                        {guides.map((guide, index) => {
                          const { day } = guide;
                          if (index === 0) {
                            return (
                              <NavigationLink
                                key={index}
                                replace
                                to={`${parentUrl}/${day}`}
                                title={`Day ${day}`}
                              />
                            );
                          }
                          return (
                            <Box key={index} ml={2}>
                              <NavigationLink
                                replace
                                to={`${parentUrl}/${day}`}
                                title={`Day ${day}`}
                              />
                            </Box>
                          );
                        })}
                      </Box>
                      <Box display="flex" flexDirection={"row"}>
                        <StyledButton
                          onClick={() => {
                            setOpen(true);
                          }}
                        >
                          Reset Day
                        </StyledButton>
                        <Box ml={2}>
                          <StyledButtonGradient
                            disabled={added}
                            onClick={() => {
                              arrayHelpers.push(createBlankExercise());
                              setAdded(true);
                              window.scrollTo(
                                0,
                                document.querySelector(".TestForm").scrollHeight
                              );
                            }}
                          >
                            Add New Exercise
                          </StyledButtonGradient>
                        </Box>
                      </Box>
                    </Grid>

                    {/* Select type of workout */}
                    <Grid
                      className={classes.marginTop}
                      container
                      alignItems="center"
                      direction="row"
                    >
                      <Typography>Today is a: </Typography>
                      <Box ml={2}>
                        <FormControl className={classes.dropdownMenu}>
                          <Field
                            className={classes.select}
                            component={Select}
                            input={<NakedSelect />}
                            inputProps={{
                              id: "trying to get this to work",
                              className: classes.selectInput,
                              onChange: handleChangeDayType,
                            }}
                            name={`type`}
                          >
                            {WorkoutTypes.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.type}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                      </Box>
                    </Grid>
                    {/* Exercises done below */}
                    <Grid container direction="column">
                      {subFormikProps.values.exercises.map(
                        (exercise, index, refArray) => {
                          const active = getActiveState(
                            subFormikProps,
                            "exercises",
                            index,
                            ["sets", "reps", "rep_label", "exercise_id", "message"],
                            []
                          );
                          return (
                            <ExerciseForm
                              buttonDisabled={!active}
                              moveItem={async (dragIndex, hoverIndex) => {
                                const hovered = refArray[hoverIndex];
                                const dragged = refArray[dragIndex];
                                const data = [
                                  {
                                    id: hovered.id,
                                    order: dragged.order,
                                  },
                                  {
                                    id: dragged.id,
                                    order: hovered.order,
                                  },
                                ];
                                try {
                                  arrayHelpers.swap(dragIndex, hoverIndex);
                                  dispatch(swapGuideExercises(guideId, data));
                                  const response = await axios.post(
                                    `/admin/guide/${guideId}/exercise/reorder`,
                                    data,
                                    {
                                      headers: {
                                        Accept: "application/json",
                                      },
                                    }
                                  );
                                  // Need to dispatch update tutorial images
                                } catch (e) {
                                  console.log("Failed to swap");
                                }
                              }}
                              guideId={guideId}
                              key={index}
                              index={index}
                              exercise={exercise}
                              remove={arrayHelpers.remove}
                              setAdded={setAdded}
                            />
                          );
                        }
                      )}
                    </Grid>

                    <ResetDayDialog
                      week={week}
                      day={day}
                      open={open}
                      setOpen={setOpen}
                      resetForm={handleResetDay}
                    />
                  </>
                );
              }}
            </FieldArray>
          );
        }}
      </Formik>
    </Grid>
  );
};

const useExerciseStyles = makeStyles({
  section: {
    padding: 16,
  },
  marginTop: {
    marginTop: 20,
  },
  sectionBase: {
    backgroundColor: "#F8F8F8",
    borderRadius: 12,
  },
  dropdownMenu: {
    height: 48,
    // marginBottom: 40,
    marginTop: 16,
    backgroundColor: "#FFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
  select: {
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: "25ch",
    borderBottomWidth: 0,
    backgroundColor: "#FFF",
    display: "flex",
    justifyContent: "flex-start",
  },
  selectInput: {
    background: 0,
    padding: "6px 0 7px",
    borderRadius: 0,
    margin: 0,
    minHeight: 0,
  },
  repLabel: {
    marginTop: 16,
    paddingLeft: 10,
    paddingRight: 10,
    borderBottomWidth: 0,
    backgroundColor: "#FFF",
    display: "flex",
    justifyContent: "flex-start",
  },
});

const validate = (value) => {
  let errorMessage;

  if (!/^([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])$/.test(value)) {
    errorMessage = "Max Value is 255";
  }
  return errorMessage;
};

const ExerciseForm = ({
  buttonDisabled,
  guideId,
  index,
  exercise,
  remove,
  moveItem,
  setAdded,
}) => {
  const classes = useExerciseStyles();
  const exercises = useSelector(selectExerciseGlossary);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const nameHelper = (name) => {
    return `${KEY_EXERCISES}[${index}].${name}`;
  };

  const { id } = exercise;
  const handleSaveClick = createHandleAddExercise(guideId, exercise, dispatch);

  const handleDeleteClick = createHandleDeleteExercise(
    guideId,
    id,
    index,
    dispatch,
    remove,
    setAdded
  );
  const handleOpenDelete = () => {
    setOpen(true);
    // Do something to save it
  };

  return (
    <DraggableGrid
      id={id}
      index={index}
      moveItem={moveItem}
      container
      direction="column"
      className={classes.marginTop}
      deleteIcon
      handleDeleteClick={handleOpenDelete}
      TitleComponent={<Typography>Exercise {index + 1}</Typography>}
    >
      <>
        <Grid className={classes.sectionBase}>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justify="space-between"
            className={classes.section}
          >
            <Box display="flex" flex={4}>
              <FormControl className={classes.dropdownMenu}>
                {/* <InputLabel>Test</InputLabel> */}
                <Field
                  className={classes.select}
                  component={Select}
                  input={<NakedSelect />}
                  inputProps={{
                    id: "trying to get this to work",
                    className: classes.selectInput,
                  }}
                  name={`${nameHelper("exercise_id")}`}
                  // name={`type`}
                >
                  {exercises.map((e) => (
                    <MenuItem key={e.id} value={e.id}>
                      {e.title}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </Box>
            <Box display="flex" ml={1} flex={2}>
              <FastField
                label="Sets"
                name={`${nameHelper("sets")}`}
                validate={validate}
                component={TextField}
                InputProps={{
                  disableUnderline: true,
                }}
              />
              {/* <ErrorMessage name={`${nameHelper("sets")}`} /> */}
            </Box>
            <Box display="flex" ml={1} flex={2}>
              <FastField
                label="Reps"
                name={`${nameHelper("reps")}`}
                component={TextField}
                validate={validate}
                InputProps={{ disableUnderline: true }}
              />
            </Box>
            <Box display="flex">
            <FastField
                label="Label"
                name={`${nameHelper("rep_label")}`}
                component={TextField}
                InputProps={{ disableUnderline: true }}
              />
            </Box>
            <Box
              alignItems="center"
              justifyContent="flex-end"
              display="flex"
              flex={1}
              ml={1}
            >
              <StyledButtonGradient
                disabled={buttonDisabled}
                onClick={handleSaveClick}
              >
                Save
              </StyledButtonGradient>
            </Box>
          </Grid>
          <Grid container className={classes.section}>
            <FastField
              name={`${nameHelper("message")}`}
              component={TextField}
              InputProps={{ disableUnderline: true }}
              label="Message From Coach"
              fullWidth
              multiline
              rows="3"
            />
          </Grid>
        </Grid>
      </>
      <DeleteExerciseDialog
        open={open}
        setOpen={setOpen}
        handleDeleteClick={handleDeleteClick}
        title={"Exercise"}
      />
    </DraggableGrid>
  );
};
