import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { sortBy, first } from "lodash";
import {
  selectYoutubeLinks,
  setYoutube,
  addYoutube,
  deleteYoutube,
  updateYoutube,
  addTerms,
  setTerms,
  deleteTerms,
  updateTerms,
  setExercises,
  setGlossaryTypes,
  addExercises,
  updateExercises,
  deleteExercises,
  setGlossaryCategory,
  updateOverviewImage,
  setOverviewImages,
} from ".";

export const useYoutubeLinks = () => {
  const dispatch = useDispatch();
  const links = useSelector(selectYoutubeLinks);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const getLinks = async () => {
      try {
        const response = await axios.get("/admin/learn/youtube", {
          params: {
            page,
          },
        });
        const { data } = response;
        const retrievedLinks = data.data;
        const sortedLinks = sortBy(retrievedLinks, ["order"]);
        dispatch(setYoutube(sortedLinks));
      } catch (e) {}

      // setTotal(data.meta.total);
    };

    getLinks();
  }, [page]);

  return {
    links,
    total,
    page,
    setPage,
  };
};

export const createHandleAddYoutube = (id, youtubeLink, dispatch) => {
  return async () => {
    const { title, link } = youtubeLink;
    let values = {
      title,
      link,
    };

    // Create New Link if id is undefined
    if (isNaN(id)) {
      try {
        const response = await axios.post("/admin/learn/youtube", values, {
          headers: {
            Accept: "application/json",
          },
        });

        const createdLink = response.data.data;
        dispatch(addYoutube(createdLink));
      } catch (e) {
        console.log("Failed to create youtube link");
      }
    } else {
      try {
        values = {
          ...youtubeLink,
        };
        const response = await axios.put(`/admin/learn/youtube/${id}`, values, {
          headers: {
            Accept: "application/json",
          },
        });

        const updatedLink = response.data.data;
        dispatch(updateYoutube(id, updatedLink));
      } catch (e) {
        console.log("Failed to update youtube link");
      }
    }
  };
};

export const createHandleDeleteYoutube = (id, index, dispatch, remove) => {
  return async () => {
    // If it is a new field don't send request just remove for list
    if (isNaN(id)) {
      remove(index);
    } else {
      try {
        await axios.delete(`/admin/learn/youtube/${id}`);
        dispatch(deleteYoutube(id));
      } catch (e) {
        console.log("Failed to delete youtube link");
      }
    }
  };
};

export const useDictionary = () => {
  const dispatch = useDispatch();
  // const terms = useSelector(selectTerms);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const getTerms = async () => {
      try {
        const response = await axios.get("/admin/learn/dictionary", {
          params: {
            page,
          },
        });
        const { data } = response;
        const retrievedTerms = data.data;
        dispatch(setTerms(retrievedTerms));
      } catch (e) {}

      // setTotal(data.meta.total);
    };

    getTerms();
  }, [page]);

  return {
    total,
    page,
    setPage,
  };
};

export const createHandleAddTerm = (id, term, dispatch) => {
  return async () => {
    const { title, description } = term;
    let values = {
      title,
      description,
    };

    // Create New Link if id is undefined
    if (isNaN(id)) {
      try {
        const response = await axios.post("/admin/learn/dictionary", values, {
          headers: {
            Accept: "application/json",
          },
        });

        const createdTerm = response.data.data;
        dispatch(addTerms(createdTerm));
      } catch (e) {
        console.log("Failed to create term");
      }
    } else {
      try {
        values = {
          ...term,
        };
        const response = await axios.put(
          `/admin/learn/dictionary/${id}`,
          values,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );

        const updatedTerm = response.data.data;
        dispatch(updateTerms(id, updatedTerm));
      } catch (e) {
        console.log("Failed to update dictionary term");
      }
    }
  };
};

export const createHandleDeleteTerm = (id, index, dispatch, remove) => {
  return async () => {
    // If it is a new field don't send request just remove for list
    if (isNaN(id)) {
      remove(index);
    } else {
      try {
        await axios.delete(`/admin/learn/dictionary/${id}`);
        dispatch(deleteTerms(id));
      } catch (e) {
        console.log("Failed to delete dictionary term");
      }
    }
  };
};

export const useGlossary = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const getGlossary = async () => {
      try {
        const response = await axios.get("/admin/learn/glossary", {
          params: {
            page,
          },
        });
        const { data } = response;
        const retrievedExercises = data.data;
        dispatch(setExercises(retrievedExercises));
      } catch (e) {}

      // setTotal(data.meta.total);
    };

    getGlossary();
  }, [page]);

  useEffect(() => {
    const getTypes = async () => {
      try {
        const response = await axios.get("/admin/learn/glossary/types", {
          params: {
            page,
          },
        });
        const { data } = response;
        const retrievedTypes = data.data;
        const firstCategory = first(retrievedTypes);
        dispatch(setGlossaryTypes(retrievedTypes));
        dispatch(setGlossaryCategory(firstCategory));
      } catch (e) {}

      // setTotal(data.meta.total);
    };

    getTypes();
  }, [page]);

  return {
    filter,
    setFilter,
    total,
    page,
    setPage,
  };
};

export const createHandleAddExercise = (exercise, typeId, dispatch) => {
  const { title, description, id, video, order, thumbnail } = exercise;
  const data = new FormData();
  data.append("title", title);
  data.append("description", description);
  data.append("glossary_type_id", typeId);
  return async () => {
    // Create New Link if id is undefined
    if (isNaN(id)) {
      data.append("thumbnail", thumbnail);
      data.append("file", video);
      try {
        const response = await axios.post("/admin/learn/glossary", data, {
          headers: {
            Accept: "application/json",
          },
        });

        const createdExercise = response.data.data;
        dispatch(addExercises(createdExercise));
      } catch (e) {
        console.log("Failed to create exercise");
      }
    } else {
      try {
        // Add order if updating
        if (typeof thumbnail !== "string") {
          data.append("thumbnail", thumbnail);
        }
        if (typeof video !== "string") {
          data.append("file", video);
        }
        data.append("order", order);
        const response = await axios.post(`/admin/learn/glossary/${id}`, data, {
          headers: {
            Accept: "application/json",
          },
        });

        const updatedExercise = response.data.data;
        dispatch(updateExercises(id, updatedExercise));
      } catch (e) {
        console.log("Failed to update exercise");
      }
    }
  };
};

export const createHandleDeleteExercise = (id, index, dispatch, remove) => {
  return async () => {
    // If it is a new field don't send request just remove for list
    if (isNaN(id)) {
      remove(index);
    } else {
      try {
        await axios.delete(`/admin/learn/glossary/${id}`);
        dispatch(deleteExercises(id));
      } catch (e) {
        console.log("Failed to delete exercise");
      }
    }
  };
};

export const useLearnImages = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const getImages = async () => {
      try {
        const response = await axios.get("/admin/learn/overview");
        const { data } = response;
        const retrievedImages = data.data;
        dispatch(setOverviewImages(retrievedImages));
      } catch (e) {
        console.log(e);
      }
    };

    getImages();
  }, []);
  return {};
};

export const createHandleUpdateOverviewImage = (dispatch) => {
  return async (values, actions) => {
    const data = new FormData();
    const { id, image } = values;
    data.append("file", image);
    try {
      const response = await axios.post(`/admin/learn/overview/${id}`, data, {
        headers: {
          Accept: "application/json",
        },
      });

      const updatedSectionImage = response.data.data;
      dispatch(updateOverviewImage(id, updatedSectionImage));
    } catch (e) {
      console.log("Failed to update image overview");
    }
  };
};
