import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  FormControl,
  Grid,
  Box,
  makeStyles,
  Select,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";

import { NavigationLink } from "../components/TabNavigationLink";
import { NoFormFileDisplay } from "../components/FormikFile";
import { StyledButtonGradient } from "../components/StyledButtons";
import NakedSelect from "../components/NakedSelect";
import keys from "lodash/keys";
import { selectAutomatedNotifications } from "../../modules/notifications";

const useStyles = makeStyles((theme) => ({
  titleMargin: {
    marginTop: 20,
  },
  dropdownMenu: {
    height: 48,
    marginBottom: 40,
    marginTop: 40,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
  select: {
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: "25ch",
    borderBottomWidth: 0,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "flex-start",
  },
  selectInput: {
    background: 0,
    padding: "6px 0 7px",
    borderRadius: 0,
    margin: 0,
    minHeight: 0,
  },
}));

const AutomatedGroups = {
  COLD: "Message for cold users",
  HOT: "Message for hot users",
  ALL: "Message for all users",
};

export default ({ parentUrl }) => {
  const classes = useStyles();
  const url = parentUrl;
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState();
  const notifications = useSelector(selectAutomatedNotifications);

  return (
    <>
      <Grid
        className={classes.titleMargin}
        direction="row"
        container
        justify="flex-start"
      >
        <NavigationLink to={`${url}/automated`} title={"Automated"} />
        <Box ml={2}>
          <NavigationLink to={`${url}/promotions`} title={"Promotions"} />
        </Box>
      </Grid>
      {/* Automated type select */}
      <Grid>
        <FormControl className={classes.dropdownMenu}>
          <Select
            className={classes.select}
            id="select-user-filter"
            value={group || "N/A"}
            onChange={(e) => {
              setGroup(e.target.value);
            }}
            input={<NakedSelect />}
            inputProps={{ className: classes.selectInput }}
          >
            {groups.map((value) => {
              return (
                <MenuItem key={value} value={value}>
                  Message for {value} Users
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid container direction="column">
        {/* Map out each category, currently single */}
        {notifications.map((popup, index) => (
          <PushNotificationAutomated key={index} {...popup} />
        ))}
      </Grid>
    </>
  );
};

const useFormStyles = makeStyles({
  section: {
    padding: 16,
  },
  marginTop: {
    marginTop: 20,
  },
  sectionBase: {
    backgroundColor: "#F8F8F8",
    borderRadius: 12,
  },
  minButtonWidth: {
    minWidth: 130,
  },
});
const PushNotificationAutomated = ({ sectionTitle, image, title, message }) => {
  const classes = useFormStyles();

  return (
    <Grid container direction="column" className={classes.marginTop}>
      <Grid container direction="row" alignItems="center" justify="flex-start">
        <Typography>{sectionTitle}</Typography>
      </Grid>
      <Grid className={classes.sectionBase}>
        <Grid container direction="row" className={classes.section}>
          <Box display="flex" flex={3}>
            <NoFormFileDisplay label="Support Image" name={image} />
          </Box>
          <Box justifyContent="center" display="flex" flex={2} ml={1}>
            <StyledButtonGradient
              className={classes.minButtonWidth}
              disabled
              type="submit"
            >
              Save
            </StyledButtonGradient>
          </Box>
        </Grid>
        <Grid
          alignItems="center"
          direction="row"
          container
          className={classes.section}
        >
          <TextField
            InputProps={{ disableUnderline: true }}
            fullWidth
            label="Push Notification Title"
            value={title}
          />
        </Grid>
        <Grid
          alignItems="center"
          direction="row"
          container
          className={classes.section}
        >
          <TextField
            InputProps={{ disableUnderline: true }}
            fullWidth
            label="Message"
            value={message}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
