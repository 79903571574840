export const Tabs = {
  AUTOMATED: "auto",
  PROMOTIONS: "promo",
};

export const SET_TAB = "POPUP/SET_TAB";
export const setNotificationTab = (tab) => ({
  type: SET_TAB,
  tab,
});

export const SET_FILTER = "POPUP/SET_VISIBILITY_FILTER";
export const setNotificationFilter = (filter) => ({
  type: SET_FILTER,
  filter,
});

/** Automated Push notification actions  */
export const SET_AUTOMATED = "POPUP/SET_AUTOMATED";
export const setAutomated = (popups) => ({
  type: SET_AUTOMATED,
  popups,
});
export const UPDATE_AUTOMATED = "POPUP/UPDATE_AUTOMATED";
export const updateAutomated = (id, popup) => ({
  type: UPDATE_AUTOMATED,
  id,
  popup,
});
export const DELETE_AUTOMATED = "POPUP/DELETE_AUTOMATED";
export const deleteAutomated = (id) => ({
  type: DELETE_AUTOMATED,
  id,
});

/** User Push notification actions  */
export const SET_USER_POPUPS = "POPUP/SET_USER_POPUPS";
export const setUserPopups = (popups) => ({
  type: SET_USER_POPUPS,
  popups,
});
export const UPDATE_USER_POPUPS = "POPUP/UPDATE_USER_POPUPS";
export const updateUserPopups = (id, popup) => ({
  type: UPDATE_USER_POPUPS,
  id,
  popup,
});

export const ADD_USER_POPUPS = "POPUP/ADD_USER_POPUPS";
export const addUserPopups = (popup) => ({
  type: ADD_USER_POPUPS,
  popup,
});
export const DELETE_USER_POPUPS = "POPUP/DELETE_USER_POPUPS";
export const deleteUserPopups = (id) => ({
  type: DELETE_USER_POPUPS,
  id,
});

const initState = {
  tab: Tabs.AUTOMATED,
  usersPopups: [],
  automatedPopups: [],
};

export const popUpReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_TAB:
      return {
        ...state,
        tab: action.tab,
      };
    case SET_FILTER:
      return { ...state, users: action.filter };

    /* AUTOMATED */
    case SET_AUTOMATED:
      return { ...state, automatedPopups: action.popups };
    case UPDATE_AUTOMATED:
      const newAuto = state.automatedPopups.map((p) =>
        p.id === action.id ? action.popup : p
      );
      return { ...state, automatedPopups: newAuto };
    case DELETE_AUTOMATED:
      const filteredAutomated = state.automatedPopups.filter(
        (p) => p.id !== action.id
      );
      return { ...state, automatedPopups: filteredAutomated };
    /* User Created */
    case SET_USER_POPUPS:
      return { ...state, usersPopups: action.popups };
    case UPDATE_USER_POPUPS:
      const newUser = state.usersPopups.map((p) =>
        p.id === action.id ? action.popup : p
      );
      return { ...state, usersPopups: newUser };
    case DELETE_USER_POPUPS:
      const filteredUserPops = state.usersPopups.filter(
        (p) => p.id !== action.id
      );
      return { ...state, usersPopups: filteredUserPops };
    case ADD_USER_POPUPS:
      const addedPopup = [...state.usersPopups, action.popup];
      return { ...state, usersPopups: addedPopup };
    default:
      return state;
  }
};

export const selectTab = (state) => state.popups.tab;
export const selectUserPopups = (state) => state.popups.usersPopups;
export const selectAutomatedPopups = (state) => state.popups.automatedPopups;
