import React, { useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";

import ChevronLeft from "@material-ui/icons/ChevronLeft";
import {
  Grid,
  Box,
  Paper,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import { useHistory, useRouteMatch } from "react-router-dom";
import { TextField, Select } from "formik-material-ui";
import { Field, Form, Formik, getIn } from "formik";

import { StyledButtonGradient } from "../components/StyledButtons";

import { GuideLevelCreateDialog } from "./dialogs";
import { createHandleAddGuideLevel } from "./effects";

const useStyles = makeStyles((theme) => ({
  titleMargin: {
    marginTop: 20,
  },
  dropdownMenu: {
    height: 48,
    marginBottom: 40,
    marginTop: 40,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
  selectInput: {
    background: 0,
    padding: "6px 0 7px",
    alignItems: "center",
    display: "flex",
    paddingLeft: 8,
    paddingRight: 16,
    borderRadius: 0,
    margin: 0,
    minHeight: 32,
    minWidth: 276 + 150,
  },
  minWidthField: {
    minWidth: 450,
  },
  fakeLabel: {
    fontWeight: 700,
    fontSize: "1rem",
  },
  errorBackground: {
    backgroundColor: "#FFD8D8",
  },
}));

export default () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const handleGuideLevelAdd = createHandleAddGuideLevel(setOpen, dispatch);

  return (
    <Grid container alignItems="flex-start" direction="column" spacing={3}>
      <Grid direction="row" container justify="flex-start" alignItems="center">
        <Box>
          <IconButton onClick={() => history.goBack()}>
            <ChevronLeft />
          </IconButton>
        </Box>
        <Box ml={1}>
          <Typography variant="h3">{"Add New Level"}</Typography>
        </Box>
      </Grid>
      <Grid className={classes.titleMargin} container>
        <Formik
          initialValues={{
            level: "",
            message: "",
            image: "",
            type: "gym"
          }}
          validate={(values) => {
            const errors = {};
            if (!values.level) {
              errors.level = "Required";
            } 
            return errors;
          }}
          onSubmit={handleGuideLevelAdd}
        >
          {({ submitForm, isSubmitting, errors, ...formikProps }) => (
            <Form>
              <Grid container direction="column">
                <Box
                  flexDirection="column"
                  mt={3}
                  mb={3}
                  display="flex"
                  alignItems="flex-start"
                >
                  <Typography className={classes.fakeLabel}>
                    Level
                  </Typography>
                  <Paper
                    className={clsx(errors.level && classes.errorBackground)}
                    elevation={errors.level ? 0 : 1}
                  >
                    <Field
                      className={classes.minWidthField}
                      component={TextField}
                      name="level"
                      type="text"
                    />
                  </Paper>
                </Box>

                <Box
                  flexDirection="column"
                  mt={3}
                  mb={3}
                  display="flex"
                  alignItems="flex-start"
                >
                  <Typography className={classes.fakeLabel}>
                    Message
                  </Typography>
                  <Paper>                  
                    <Field
                      className={classes.minWidthField}
                      component={TextField}
                      name="message"
                      type="text"
                    />
                  </Paper>
                </Box>
                              
                <Box display="flex" align="stretch" mt={2}>
                  <StyledButtonGradient
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    Submit
                  </StyledButtonGradient>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
      <GuideLevelCreateDialog open={open} setOpen={setOpen} />
    </Grid>
  );
};
