import React, { useState, useEffect } from "react";
import {
  useHistory,
  useRouteMatch,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  ButtonBase,
} from "@material-ui/core";

import { StyledButton } from "../components/StyledButtons";
import { NavigationLink } from "../components/TabNavigationLink";

import Overview from "./overview";
import LinksPage from "./links";
import DictionaryPage from "./dictionary";
import GlossaryPage from "./glossary";

const useStyles = makeStyles((theme) => ({
  titleMargin: {
    marginTop: 20,
  },
  buttonResize: {
    height: 40,
  },
  spacing: { marginLeft: 24 },
  activeColor: {
    color: "#F54E55",
  },
  inactiveColor: {
    color: "#DDDDDD",
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { url, path } = useRouteMatch();
  const history = useHistory();

  return (
    <Grid container alignItems="flex-start" direction="column" spacing={3}>
      <Grid
        direction="row"
        container
        alignItems="center"
        justify="space-between"
      >
        <Typography variant="h3">{"Learn Content"}</Typography>
      </Grid>
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}/overview`} />
        </Route>
        <Route path={`${path}/overview`}>
          <Overview parentUrl={url} />
        </Route>
        <Route path={`${path}/links`}>
          <LinksPage parentUrl={url} />
        </Route>
        <Route path={`${path}/dictionary`}>
          <DictionaryPage parentUrl={url} />
        </Route>
        <Route path={`${path}/glossary`}>
          <GlossaryPage parentUrl={url} />
        </Route>
      </Switch>
    </Grid>
  );
};
