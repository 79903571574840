import React from "react";
import { useRouteMatch, Link, useParams, useHistory } from "react-router-dom";

import {
  Grid,
  Box,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

import { StyledButtonGradient } from "../components/StyledButtons";
import {
  PLAN_DAYS_THREE,
  PLAN_DAYS_FOUR,
  PLAN_DAYS_FIVE,
  PLAN_DAYS_SIX,
  PLAN_DAYS_SEVEN,
  setLevel,
} from "../../modules/home";

const useStyles = makeStyles({
  marginTop: {
    marginTop: 40,
  },
  buttonWidth: {
    minWidth: 300,
  },
  section: {
    padding: 16,
  },
  sectionBase: {
    backgroundColor: "#F8F8F8",
    borderRadius: 12,
  },
});

const levelToText = (level) => {
  return level.charAt(0).toUpperCase() + level.slice(1);
};

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const { level } = useParams();

  // useEffect()

  // <Link to={`${url}/week1`}>Week</Link>
  return (
    <Grid container alignItems="flex-start" direction="column" spacing={3}>
      <Grid direction="row" container justify="flex-start" alignItems="center">
        <Box>
          <IconButton onClick={() => history.goBack()}>
            <ChevronLeft />
          </IconButton>
        </Box>
        <Box ml={1}>
          <Typography variant="h3">{levelToText(level)}</Typography>
        </Box>
      </Grid>
      <Grid
        className={classes.marginTop}
        container
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Typography variant="body1">Please select a day plan below:</Typography>
        <DaySelection />
      </Grid>
    </Grid>
  );
};

const getDaysForLevel = (level) => {
  return [PLAN_DAYS_THREE, PLAN_DAYS_FOUR, PLAN_DAYS_FIVE, PLAN_DAYS_SIX, PLAN_DAYS_SEVEN];

};

const DaySelection = () => {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const { level } = useParams();
  const levels = getDaysForLevel(level);

  return (
    <>
      {levels.map((v) => (
        <Box key={v} mt={2}>
          <StyledButtonGradient
            className={classes.buttonWidth}
            to={`${url}/${v}`}
            component={Link}
          >
            {v}
          </StyledButtonGradient>
        </Box>
      ))}
    </>
  );
};
