import React from "react";
import clsx from "clsx";
import { makeStyles, Grid } from "@material-ui/core";
import Nav from "./menu";
import Sidebar from "./sidebar";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    paddingTop: 60,
  },
  sidebar: {
    width: 122,
    minWidth: 60,
  },
  paddingSides: {
    marginLeft: 100,
    marginRight: 100,
  },
  addBottom: {
    paddingBottom: 60,
  },
  quickInfo: {
    backgroundColor: "#FFF0F0",
    width: 360,
    maxHeight: 900,
    marginTop: 0,
    [theme.breakpoints.up("md")]: {
      marginTop: -60,
    },
  },
}));

export default ({ children }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.root} container justify="flex-start">
      <Grid item className={classes.sidebar} xs={1}>
        <Nav />
      </Grid>
      <Grid
        container
        className={clsx(classes.paddingSides, classes.addBottom)}
        item
        xs={6}
      >
        {children}
      </Grid>
      <Grid item className={classes.quickInfo} xs={3}>
        <Sidebar />
      </Grid>
    </Grid>
  );
};
