import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  makeStyles,
  ButtonBase,
  Button,
  Paper,
} from "@material-ui/core";
import { ReactComponent as BarChart } from "../../svgs/barChart.svg";

const useStyles = makeStyles((theme) => ({
  rounded: {
    borderRadius: 24,
    maxWidth: 200,
    margin: 20,
    padding: 10,
    marginLeft: 0,
    backgroundColor: "#FAF6F6",
  },
  center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  graphic: {
    // height: 100,
    // width: 100,
  },
}));

export default ({ count, category, onButtonClick }) => {
  const classes = useStyles();
  return (
    <Card
      elevation={0}
      component={ButtonBase}
      onClick={onButtonClick}
      className={classes.rounded}
    >
      <CardContent className={classes.center}>
        <Box className={classes.graphic}>
          <BarChart />
        </Box>
        <Typography variant="h4">{count}</Typography>
        <Typography variant="body2">{category}</Typography>
      </CardContent>
    </Card>
  );
};
