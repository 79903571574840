import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";

import Clear from "@material-ui/icons/Clear";
import {
  Grid,
  MenuItem,
  Button,
  makeStyles,
  Select,
  FormControl,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TablePagination,
  TableBody,
  TableCell,
  Paper,
} from "@material-ui/core";
import { useHistory, useRouteMatch } from "react-router-dom";

import StyledButtonGradient from "../components/StyledButtons";
import NakedSelect from "../components/NakedSelect";
import {
  ResetDialog,
  DeleteUserDialog,
  RoleChangeDialog,
  EmailChangeDialog,
} from "./dialogs";
import { useAdminUsers } from "./effects";
import { setCurrentAdmin, selectRoles } from "../../modules/admin";

const useStyles = makeStyles((theme) => ({
  titleMargin: {
    marginTop: 20,
  },
  dropdownMenu: {
    height: 48,
    marginBottom: 40,
    marginTop: 40,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
  tableRow: {
    borderRadius: 8,
  },
  select: {
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: "25ch",
    borderBottomWidth: 0,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "flex-start",
  },
  table: {
    // minWidth: 730,
    borderSpacing: "3px 1em",
    borderCollapse: "separate",
  },
  tableCell: {
    borderBottom: 0,
    backgroundColor: "white",
  },
  buttonResize: {
    height: 40,
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();

  const { users, setUsers, total, page, setPage, roles } = useAdminUsers();

  const [resetOpen, setResetOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [roleOpen, setRoleOpen] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);

  const handleAddAdmin = () => {
    history.push(`${url}/add`);
  };

  const handleChangePage = (e, nextPage) => {
    setPage(nextPage + 1);
  };

  return (
    <Grid container alignItems="flex-start" direction="column" spacing={3}>
      <Grid direction="row" container justify="space-between">
        <Typography variant="h3">{"Admin"}</Typography>
        <StyledButtonGradient
          className={classes.buttonResize}
          onClick={handleAddAdmin}
        >
          Add Admin User
        </StyledButtonGradient>
      </Grid>
      <Grid className={classes.titleMargin} container>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell colSpan={3} className={clsx(classes.tableCell)}>
                  User Email Address
                </TableCell>
                <TableCell
                  colSpan={2}
                  className={classes.tableCell}
                  align="left"
                >
                  Role
                </TableCell>
                <TableCell
                  colSpan={2}
                  className={classes.tableCell}
                  align="left"
                ></TableCell>
                <TableCell
                  colSpan={1}
                  className={classes.tableCell}
                  align="left"
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => {
                const { id, role_title, email } = user;
                return (
                  <TableRow
                    elevation={3}
                    component={(props) => <Paper component="tr" {...props} />}
                    className={classes.tableRow}
                    key={id}
                  >
                    <TableCell
                      className={classes.tableCell}
                      component="th"
                      colSpan={3}
                      scope="row"
                    >
                      <Button
                        onClick={() => {
                          dispatch(setCurrentAdmin(user));
                          setEmailOpen(true);
                        }}
                      >
                        {email}
                      </Button>
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      className={classes.tableCell}
                      align="left"
                    >
                      <RoleSelect setRoleOpen={setRoleOpen} user={user} />
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      className={classes.tableCell}
                      align="left"
                    >
                      <Button
                        onClick={() => {
                          dispatch(setCurrentAdmin(user));
                          setResetOpen(true);
                        }}
                      >
                        Reset Password
                      </Button>
                    </TableCell>
                    <TableCell
                      colSpan={1}
                      className={classes.tableCell}
                      align="left"
                    >
                      <Button
                        onClick={() => {
                          dispatch(setCurrentAdmin(user));
                          setDeleteOpen(true);
                        }}
                      >
                        <Clear />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TablePagination
                  colSpan={8}
                  rowsPerPageOptions={[50]}
                  rowsPerPage={50}
                  count={total}
                  page={page - 1}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {/* Dialogs */}
      <ResetDialog open={resetOpen} setOpen={setResetOpen} />
      <DeleteUserDialog
        open={deleteOpen}
        setUsers={setUsers}
        users={users}
        setOpen={setDeleteOpen}
      />
      <RoleChangeDialog
        open={roleOpen}
        setUsers={setUsers}
        users={users}
        setOpen={setRoleOpen}
      />
      <EmailChangeDialog
        setUsers={setUsers}
        users={users}
        open={emailOpen}
        setOpen={setEmailOpen}
      />
    </Grid>
  );
};

const useSelectStyle = makeStyles({
  selectInput: {
    background: 0,
    padding: "6px 0 7px",
    borderRadius: 0,
    margin: 0,
    minHeight: 0,
  },
});

const RoleSelect = ({ user, setRoleOpen }) => {
  const classes = useSelectStyle();
  const roles = useSelector(selectRoles);
  const dispatch = useDispatch();
  const [role, setRole] = useState(user.role_id || 1);
  const [open, setOpen] = useState(false);

  const handleChange = async (event) => {
    if (user.role !== event.target.value) {
      setRole(event.target.value);
      dispatch(setCurrentAdmin(user));
      setRoleOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <FormControl>
      {/* <InputLabel id={`${user.email}-role-select-label`}>Role</InputLabel> */}
      <Select
        labelId={`${user.email}-role-select-label`}
        id={`${user.email}-role-select`}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={role}
        onChange={handleChange}
        input={<NakedSelect />}
        inputProps={{ className: classes.selectInput }}
        // This stuff will be disabled depending on admin
      >
        {roles.map((role) => {
          // User role type
          if (role.id === 1) {
            return null;
          }
          return (
            <MenuItem key={role.id} value={role.id}>
              {role.title}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
