import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuIcon from "@material-ui/icons/Menu";
import moment from "moment";
import { FastField, Field, Formik, FieldArray } from "formik";
import { TextField } from "formik-material-ui";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import axios from "axios";

import { NavigationLink } from "../components/TabNavigationLink";
import { SimpleFileUpload } from "../components/FormikFile";
import { StyledButtonGradient } from "../components/StyledButtons";
import { useSelector, useDispatch } from "react-redux";
import { selectUserPopups } from "../../modules/popups";
import {
  useUserPopups,
  createHandleAddPopup,
  createHandleDeletePopup,
} from "../../modules/popups/effects";

const useStyles = makeStyles((theme) => ({
  titleMargin: {
    marginTop: 20,
  },
}));

const createEmptyPopupField = () => {
  return {
    from_date: moment(),
    to_date: moment(),
    background_image: null,
    supporting_image: null,
    title: "",
    button_text: "",
    popup_title: "",
    message: "",
  };
};

export default ({ parentUrl }) => {
  useUserPopups();
  const classes = useStyles();
  const url = parentUrl;
  const popups = useSelector(selectUserPopups);

  return (
    <Formik
      key={JSON.stringify(popups)}
      initialValues={{
        popups: popups,
      }}
    >
      {(formikProps) => {
        return (
          <FieldArray name="popups">
            {(arrayHelpers) => {
              return (
                <>
                  <Grid
                    className={classes.titleMargin}
                    direction="row"
                    container
                    wrap="nowrap"
                    justify="space-between"
                  >
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <NavigationLink
                        to={`${url}/automated`}
                        title={"Automated"}
                      />
                      <Box ml={2}>
                        <NavigationLink
                          to={`${url}/promotions`}
                          title={"Promotions"}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <StyledButtonGradient
                        onClick={() =>
                          arrayHelpers.push(createEmptyPopupField())
                        }
                      >
                        Add Promotion Message
                      </StyledButtonGradient>
                    </Box>
                  </Grid>
                  <Grid container direction="column">
                    {formikProps.values.popups.map((popup, index) => {
                      const active = getActiveState(
                        formikProps,
                        `popups`,
                        index,
                        [
                          "to_date",
                          "from_date",
                          "title",
                          "popup_title",
                          "button_text",
                          "message",
                        ],
                        ["support_image", "background_image"]
                      );
                      return (
                        <PopupForm
                          buttonDisabled={!active}
                          index={index}
                          remove={arrayHelpers.remove}
                          key={index}
                          popup={popup}
                        />
                      );
                    })}
                  </Grid>
                </>
              );
            }}
          </FieldArray>
        );
      }}
    </Formik>
  );
};

const usePopupFormStyles = makeStyles({
  section: {
    padding: 16,
  },
  marginTop: {
    marginTop: 20,
  },
  sectionBase: {
    backgroundColor: "#F8F8F8",
    borderRadius: 12,
  },
  buttonMinWidth: {
    minWidth: 130,
  },
});

const PopupForm = ({ remove, index, buttonDisabled, popup }) => {
  const dispatch = useDispatch();
  const {
    id,
    title,
    background_image,
    supporting_image,
    popup_title,
    button_text,
    message,
    to_date,
    from_date,
  } = popup;
  const classes = usePopupFormStyles();
  const nameHelper = (name) => {
    return `popups[${index}].${name}`;
  };

  const handleDeleteClick = createHandleDeletePopup(
    id,
    index,
    dispatch,
    remove
  );

  const handleSaveClick = createHandleAddPopup(id, popup, dispatch);

  return (
    <Grid container direction="column" className={classes.marginTop}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
      >
        <Box alignItems="center" display="flex" flexDirection="row">
          <IconButton>
            <MenuIcon />
          </IconButton>
          <FastField
            name={`${nameHelper("title")}`}
            component={TextField}
            InputProps={{
              disableUnderline: true,
              placeholder: "Promotion Title",
              // onMouseDown: (e) => e.target.focus(),
            }}
            InputLabelProps={
              {
                // focused: true,
              }
            }
            fullWidth
          />
        </Box>
        <IconButton onClick={handleDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </Grid>
      <Grid className={classes.sectionBase}>
        {/* date row */}
        <Grid
          container
          direction="row"
          justify="space-between"
          className={classes.section}
        >
          <Box flex={2} display="flex" flexDirection="row" alignItems="center">
            <Box>
              <Field
                name={`${nameHelper("from_date")}`}
                label="From"
                disablePast
                component={KeyboardDatePicker}
                labelFunc={(date, invalidString) => {
                  return moment(date).format("L");
                }}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>
            <Box ml={2}>
              <Field
                name={`${nameHelper("to_date")}`}
                label="To"
                disablePast
                component={KeyboardDatePicker}
                labelFunc={(date, invalidString) => {
                  return moment(date).format("L");
                }}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>
          </Box>
          <Box flex={2} justifyContent="flex-end" display="flex">
            <StyledButtonGradient
              disabled={buttonDisabled}
              onClick={handleSaveClick}
              className={classes.buttonMinWidth}
            >
              Save
            </StyledButtonGradient>
          </Box>
        </Grid>
        {/* image row */}
        <Grid
          container
          direction="row"
          justify="space-between"
          className={classes.section}
        >
          <Box display="flex" flex={2}>
            <Field
              label="Background Image"
              name={`${nameHelper("background_image")}`}
              component={SimpleFileUpload}
            />
          </Box>
          <Box display="flex" ml={1} flex={2}>
            <Field
              label="Support Image"
              name={`${nameHelper("supporting_image")}`}
              component={SimpleFileUpload}
            />
          </Box>
        </Grid>
        {/* title, button text row */}
        <Grid container className={classes.section}>
          <Box display="flex" ml={1} flex={2}>
            <FastField
              name={`${nameHelper("popup_title")}`}
              component={TextField}
              InputProps={{ disableUnderline: true }}
              label="Pop Up Title"
              fullWidth
            />
          </Box>
          <Box display="flex" ml={1} flex={2}>
            <FastField
              name={`${nameHelper("button_text")}`}
              component={TextField}
              InputProps={{ disableUnderline: true }}
              label="Button Text"
              fullWidth
            />
          </Box>
        </Grid>
        {/* message row */}
        <Grid container className={classes.section}>
          <FastField
            name={`${nameHelper("message")}`}
            component={TextField}
            InputProps={{ disableUnderline: true }}
            label="Message"
            fullWidth
            multiline
            rows="3"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const getActiveState = (formik, itemName, index, fields, fileFields) => {
  let active = false;
  for (let field of fields) {
    const fieldName = `${itemName}[${index}].${field}`;
    const meta = formik.getFieldMeta(fieldName);
    // active = active || (meta.touched && meta.initialValue !== meta.value);
    active = active || meta.initialValue !== meta.value;
  }
  for (let field of fileFields) {
    const fieldName = `${itemName}[${index}].${field}`;
    const meta = formik.getFieldMeta(fieldName);
    active = active || meta.initialValue !== meta.value;
  }
  return active;
};
