import React from "react";
import clsx from "clsx";
import { Grid, ButtonBase, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: { marginTop: 20 },
  spacing: { marginLeft: 24 },
  activeColor: {
    color: "#F54E55"
  },
  inactiveColor: {
    color: "#DDDDDD"
  }
});

export default ({ tabs, activeTab, onTabClick }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.root} container justify="flex-start">
      {tabs.map(({ tab, title }, index) => {
        const active = tab === activeTab;
        return (
          <ButtonBase
            className={clsx(index !== 0 && classes.spacing)}
            key={index}
            onClick={onTabClick(tab)}
          >
            <Typography
              align="left"
              variant="body1"
              className={active ? classes.activeColor : classes.inactiveColor}
            >
              {title}
            </Typography>
          </ButtonBase>
        );
      })}
    </Grid>
  );
};
