import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  IconButton,
  TextField as MuiTextField,
  makeStyles,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { getIn, Field, Form, Formik, FieldArray } from "formik";
import { TextField } from "formik-material-ui";
import axios from "axios";

import { ImageTypes } from "./constants";
import {
  StyledButtonGradient,
  StyledButtonDisabled,
} from "../components/StyledButtons";
import { displayWithSuffix } from "../components/utils";
import { SimpleFileUpload } from "../components/FormikFile";
import NakedSelect from "../components/NakedSelect";
import DraggableGrid from "../components/DraggableGrid";
import { useSelector, useDispatch } from "react-redux";
import {
  selectTutorialImages,
  swapTutorials,
} from "../../modules/setting-images";
import { getActiveState } from "../helpers";
import {
  createHandleDeleteTutorial,
  createHandleAddTutorial,
} from "../../modules/setting-images/effects";

const useStyles = makeStyles({
  buttonWidth: {
    minWidth: 120,
    height: 40,
  },
  colorText: {
    color: "#03B7B9",
  },
  titleMargin: {
    marginTop: 20,
  },
  dropdownMenu: {
    height: 48,
    marginBottom: 40,
    marginTop: 40,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
  select: {
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: "25ch",
    borderBottomWidth: 0,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "flex-start",
  },
  marginTop: {
    marginTop: 40,
  },
  section: {
    padding: 16,
  },
  sectionBase: {
    backgroundColor: "#F8F8F8",
    borderRadius: 12,
  },
  selectInput: {
    background: 0,
    padding: "6px 0 7px",
    borderRadius: 0,
    margin: 0,
    minHeight: 0,
  },
});

export const TutorialSection = ({ handleSetTab, imageType }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const images = useSelector(selectTutorialImages);

  return (
    <Formik
      key={JSON.stringify(images)}
      initialValues={{
        tutorials: images,
      }}
    >
      {(formikProps) => {
        return (
          <FieldArray name="tutorials">
            {(arrayHelpers) => {
              return (
                <>
                  <Grid container justify="space-between" alignItems="center">
                    <FormControl className={classes.dropdownMenu}>
                      <Select
                        displayEmpty
                        className={classes.select}
                        id="select-settings-image-filter"
                        value={imageType}
                        onChange={handleSetTab}
                        input={<NakedSelect />}
                        inputProps={{ className: classes.selectInput }}
                      >
                        <MenuItem value={ImageTypes.UNIQUE}>
                          {ImageTypes.UNIQUE}
                        </MenuItem>
                        <MenuItem value={ImageTypes.TUTORIAL}>
                          {ImageTypes.TUTORIAL}
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {imageType === ImageTypes.TUTORIAL && (
                      <StyledButtonGradient
                        onClick={() => arrayHelpers.push({})}
                        className={classes.buttonWidth}
                      >
                        Add Tutorial Image
                      </StyledButtonGradient>
                    )}
                  </Grid>
                  <Grid container justify="flex-start">
                    <Grid container direction="column" alignItems="flex-start">
                      {formikProps.values.tutorials.map(
                        (tutorial, index, refArray) => {
                          const active = getActiveState(
                            formikProps,
                            "tutorials",
                            index,
                            ["message"],
                            ["image"]
                          );
                          return (
                            <TutorialForm
                              tutorial={tutorial}
                              moveItem={async (dragIndex, hoverIndex) => {
                                const hoveredTutorial = refArray[hoverIndex];
                                const draggedTutorial = refArray[dragIndex];

                                const data = [
                                  {
                                    id: hoveredTutorial.id,
                                    order: draggedTutorial.order,
                                  },
                                  {
                                    id: draggedTutorial.id,
                                    order: hoveredTutorial.order,
                                  },
                                ];
                                try {
                                  arrayHelpers.swap(dragIndex, hoverIndex);
                                  dispatch(swapTutorials(data));
                                  const response = await axios.post(
                                    `/admin/settings/image-overview/reorder`,
                                    data,
                                    {
                                      headers: {
                                        Accept: "application/json",
                                      },
                                    }
                                  );
                                  // Need to dispatch update tutorial images
                                } catch (e) {
                                  console.log("Failed to swap");
                                }
                              }}
                              buttonDisabled={!active}
                              remove={arrayHelpers.remove}
                              key={index}
                              index={index}
                            />
                          );
                        }
                      )}
                    </Grid>
                  </Grid>
                </>
              );
            }}
          </FieldArray>
        );
      }}
    </Formik>
  );
};

const nonActionMove = () => {
  console.log("Not movable");
};

const TutorialForm = ({
  remove,
  tutorial,
  index,
  moveItem,
  buttonDisabled,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const nameHelper = (name) => {
    return `tutorials[${index}].${name}`;
  };
  const { image, id, message } = tutorial;

  const handleSaveClick = createHandleAddTutorial(tutorial, dispatch);
  const handleDeleteClick = createHandleDeleteTutorial(
    id,
    index,
    dispatch,
    remove
  );

  return (
    <DraggableGrid
      disabled={isNaN(id)}
      id={id}
      index={index}
      moveItem={isNaN(id) ? nonActionMove : moveItem} // If new item can not drag
      container
      direction="column"
      className={classes.marginTop}
      deleteIcon
      handleDeleteClick={handleDeleteClick}
      TitleComponent={
        <Typography>{`${displayWithSuffix(
          index + 1
        )} Tutorial Image`}</Typography>
      }
    >
      <Grid className={classes.sectionBase}>
        <Grid
          container
          direction="row"
          justify="space-between"
          className={classes.section}
        >
          <Box display="flex" flex={4}>
            <Field
              label={"Tutorial Image"}
              name={`${nameHelper("image")}`}
              component={SimpleFileUpload}
            />
          </Box>
          <Box
            alignItems="center"
            justifyContent="center"
            display="flex"
            flex={1}
            ml={1}
          >
            <StyledButtonGradient
              onClick={handleSaveClick}
              disabled={buttonDisabled}
            >
              Save
            </StyledButtonGradient>
          </Box>
        </Grid>
        <Grid container className={classes.section}>
          <Field
            name={`${nameHelper("message")}`}
            component={TextField}
            InputProps={{ disableUnderline: true }}
            label="Tutorial Message"
            fullWidth
            multiline
            rows="3"
          />
        </Grid>
      </Grid>
    </DraggableGrid>
  );
};
