import React, { useState, useEffect } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Typography,
  Grid,
  Box,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuIcon from "@material-ui/icons/Menu";
import { FastField, Field, Formik, FieldArray } from "formik";
import { TextField } from "formik-material-ui";
import axios from "axios";

import { DeleteDialog } from "./dialogs";
import NakedSelect from "../components/NakedSelect";
import { NavigationLink } from "../components/TabNavigationLink";
import { SimpleFileUpload } from "../components/FormikFile";
import { StyledButtonGradient } from "../components/StyledButtons";
import {
  selectGlossaryTypes,
  selectActiveCategory,
  selectFilteredExercises,
  setGlossaryCategoryById,
} from "../../modules/learning";
import { getActiveState } from "../helpers";
import {
  createHandleAddExercise,
  createHandleDeleteExercise,
  useGlossary,
} from "../../modules/learning/effects";
import { useDispatch, useSelector } from "react-redux";
// import { useGlossary } from "../../modules/learning/effects";

const useStyles = makeStyles((theme) => ({
  titleMargin: {
    marginTop: 20,
  },
  dropdownMenu: {
    height: 48,
    marginBottom: 40,
    marginTop: 40,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
  select: {
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: "25ch",
    borderBottomWidth: 0,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "flex-start",
  },
  selectInput: {
    background: 0,
    padding: "6px 0 7px",
    borderRadius: 0,
    margin: 0,
    minHeight: 0,
  },
}));

export const createEmptyExercise = () => {
  return { video: null, title: "", description: "" };
};
export default ({ parentUrl }) => {
  const dispatch = useDispatch();
  useGlossary();
  const classes = useStyles();
  const url = parentUrl;
  const exercises = useSelector(selectFilteredExercises);
  const categories = useSelector(selectGlossaryTypes);
  const category = useSelector(selectActiveCategory);

  return (
    <Formik
      key={JSON.stringify(exercises)}
      initialValues={{
        exercises: exercises,
      }}
    >
      {(formikProps) => {
        return (
          <FieldArray name="exercises">
            {(arrayHelpers) => {
              return (
                <>
                  <Grid
                    className={classes.titleMargin}
                    direction="row"
                    container
                    wrap="nowrap"
                    justify="space-between"
                  >
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <NavigationLink
                        to={`${url}/overview`}
                        title={"Learn Overview"}
                      />
                      <Box ml={2}>
                        <NavigationLink
                          to={`${url}/links`}
                          title={"YouTube Links"}
                        />
                      </Box>
                      <Box ml={2}>
                        <NavigationLink
                          to={`${url}/dictionary`}
                          title={"Dictionary"}
                        />
                      </Box>
                      <Box ml={2}>
                        <NavigationLink
                          to={`${url}/glossary`}
                          title={"Exercise Glossary"}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <StyledButtonGradient
                        onClick={() => arrayHelpers.push(createEmptyExercise())}
                      >
                        Add Exercises
                      </StyledButtonGradient>
                    </Box>
                  </Grid>
                  {/* Automated type select */}
                  <Grid>
                    <FormControl className={classes.dropdownMenu}>
                      <Select
                        className={classes.select}
                        id="select-user-filter"
                        value={category ? category.id : 1}
                        onChange={(e) => {
                          const id = e.target.value;
                          dispatch(setGlossaryCategoryById(id));
                        }}
                        input={<NakedSelect />}
                        inputProps={{ className: classes.selectInput }}
                      >
                        {categories.map((v) => (
                          <MenuItem key={v.id} value={v.id}>
                            {v.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid container direction="column">
                    {formikProps.values.exercises.map((exercise, index) => {
                      const active = getActiveState(
                        formikProps,
                        "exercises",
                        index,
                        ["title", "name", "description"],
                        ["video", "thumbnail"]
                      );
                      return (
                        <ExerciseForm
                          buttonDisabled={!active}
                          index={index}
                          remove={arrayHelpers.remove}
                          key={index}
                          exercise={exercise}
                        />
                      );
                    })}
                  </Grid>
                </>
              );
            }}
          </FieldArray>
        );
      }}
    </Formik>
  );
};

const useFormStyles = makeStyles({
  marginTop: {
    marginTop: 40,
  },
  buttonWidth: {
    minWidth: 130,
  },
  section: {
    padding: 16,
  },
  sectionBase: {
    backgroundColor: "#F8F8F8",
    borderRadius: 12,
  },
  titleFormat: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
  },
  removeVertical: {
    marginTop: 0,
  },
});

const ExerciseForm = ({ remove, index, buttonDisabled, exercise }) => {
  const dispatch = useDispatch();
  const category = useSelector(selectActiveCategory);
  const classes = useFormStyles();
  const [open, setOpen] = useState(false);
  const nameHelper = (name) => {
    return `exercises[${index}].${name}`;
  };

  const { title, id } = exercise;
  const handleDeleteClick = () => {
    setOpen(true);
    // DO async stuff to delete it
  };

  const handleSaveClick = createHandleAddExercise(
    exercise,
    category.id,
    dispatch
  );
  const handleConfirmDelete = createHandleDeleteExercise(
    id,
    index,
    dispatch,
    remove
  );

  return (
    <Grid container direction="column" className={classes.marginTop}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
      >
        <Box alignItems="center" display="flex" flexDirection="row">
          <IconButton>
            <MenuIcon />
          </IconButton>
          <Typography>{title}</Typography>
        </Box>
        <IconButton onClick={handleDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </Grid>
      <Grid className={classes.sectionBase}>
        {/* Link row */}
        <Grid
          container
          direction="row"
          justify="space-between"
          className={classes.section}
        >
          <Box alignItems="center" display="flex" flex={4}>
            <FastField
              name={`${nameHelper("title")}`}
              component={TextField}
              InputProps={{
                disableUnderline: true,
                placeholder: "Exercise Name",
              }}
              label="Exercise Name"
              fullWidth
            />
          </Box>
          <Box justifyContent="flex-end" display="flex" flex={1} ml={1}>
            <StyledButtonGradient
              disabled={buttonDisabled}
              onClick={handleSaveClick}
              className={classes.buttonWidth}
            >
              Save
            </StyledButtonGradient>
          </Box>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          className={classes.section}
        >
          <Box alignItems="center" display="flex" flex={1}>
            <Box alignItems="center" display="flex" flex={1}>
              <Field
                name={`${nameHelper("thumbnail")}`}
                component={SimpleFileUpload}
                label="Video Thumbnail"
                placeholder="Click Here to Upload Image"
              />
            </Box>
            <Box ml={1} alignItems="center" display="flex" flex={1}>
              <Field
                name={`${nameHelper("video")}`}
                component={SimpleFileUpload}
                label="Exercise Video"
                placeholder="Click Here to Upload Video"
              />
            </Box>
          </Box>
        </Grid>
        <Grid direction="column" container className={classes.section}>
          <FastField
            name={`${nameHelper("description")}`}
            component={TextField}
            label="Exercise Video Description"
            fullWidth
            InputProps={{
              disableUnderline: true,
              placeholder: "Description",
            }}
          />
        </Grid>
      </Grid>
      <DeleteDialog
        handleDeleteClick={handleConfirmDelete}
        promptText={`Do you wish to delete the exercise "${title}"?`}
        promptSuccess={`Exercise "${title}" has been deleted`}
        open={open}
        setOpen={setOpen}
      />
    </Grid>
  );
};
