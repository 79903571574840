import { combineReducers } from "redux";

import { usersReducer } from "./users";
import { adminReducer } from "./admin";
import { learnReducer } from "./learning";
import { authReducer } from "./authentication";
import { shopReducer } from "./shopping";
import { notificationReducer } from "./notifications";
import { popUpReducer } from "./popups";
import { settingImagesReducer } from "./setting-images";
import { guideReducer } from "./guides";
import { homeReducer } from "./home";
import { dashboardReducer } from "./dashboard";

const rootreducer = combineReducers({
  dashboard: dashboardReducer,
  notification: notificationReducer,
  popups: popUpReducer,
  users: usersReducer,
  admin: adminReducer,
  learning: learnReducer,
  shop: shopReducer,
  authentication: authReducer,
  settings: settingImagesReducer,
  guides: guideReducer,
  home: homeReducer,
});

export default rootreducer;
