import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUsers,
  setAdminUsers,
  selectRoles,
  setRoles,
  selectAdminUsers,
} from "../../modules/admin";
import axios from "axios";

export const createHandleAddAdmin = (setOpen) => {
  return async (values, { setSubmitting, setErrors }) => {
    try {
      await axios.post("/admin/user", values, {
        headers: {
          Accept: "application/json",
        },
      });
      setOpen(true);
    } catch (e) {
      const email = e.response.data.errors.email;
      const password = e.response.data.errors.password;
      setSubmitting(false);
      setErrors({ email, password });
    }
    setSubmitting(false);
  };
};

export const useAdminUsers = () => {
  const dispatch = useDispatch();
  const users = useSelector(selectAdminUsers);
  const roles = useSelector(selectRoles);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await axios.get("/admin/admin-users", {
          params: {
            page,
          },
        });
        const { data } = response;
        dispatch(setAdminUsers(data.data));
        setTotal(data.meta.total);
      } catch (e) {
        console.log(e);
      }
      // setUsers(data.data);
    };

    getUsers();
  }, [page]);

  useEffect(() => {
    const getRoles = async () => {
      try {
        const response = await axios.get("/admin/roles");
        const { data } = response;
        dispatch(setRoles(data.data));
      } catch (e) {
        console.log(e);
      }
    };

    getRoles();
  }, []);

  return { roles, users, total, page, setPage };
};

export const createAdminPasswordReset = (setSuccess, id) => {
  return async (e) => {
    try {
      await axios.post(`/admin/user/${id}/reset-password`, null, {
        headers: {
          Accept: "application/json",
        },
      });
      setSuccess(true);
    } catch (e) {
      alert("Failed to send password reset");
    }
  };
};

export const createDeleteUserClick = (
  currentUser,
  setSuccess,
  dispatch,
  admins
) => {
  return async (e) => {
    try {
      const { id } = currentUser;
      const res = await axios.delete(`/admin/user/${id}`, {
        headers: {
          Accept: "application/json",
        },
      });
      // If admin in current list of admins update user data
      const newUsers = admins.filter((admin) => {
        return admin.id !== id;
      });
      dispatch(setAdminUsers(newUsers));
    } catch (e) {
      console.log(e);
    }
    setSuccess(true);
  };
};

const getOppositeRole = (id) => {
  if (id === 2) {
    // Set moderator to admin
    return 3;
  } else {
    return 2; // Opposite of above
  }
};

export const createAdminRoleUpdate = (
  currentUser,
  setSuccess,
  dispatch,
  admins
) => {
  return async (e) => {
    try {
      const { id, role_id, first_name, last_name, email } = currentUser;
      const newRole = getOppositeRole(role_id);
      const data = {
        first_name,
        last_name,
        email,
        role_id: newRole,
      };
      const res = await axios.put(`/admin/user/${id}`, data, {
        headers: {
          Accept: "application/json",
        },
      });
      const updatedUser = res.data.data;
      // If admin in current list of admins update user data
      const newUsers = admins.map((admin) => {
        return admin.id === id ? updatedUser : admin;
      });
      dispatch(setAdminUsers(newUsers));
    } catch (e) {
      console.log(e);
    }
    setSuccess(true);
  };
};

export const createAdminEmailUpdate = (
  email,
  currentUser,
  setSuccess,
  dispatch,
  admins
) => {
  return async (e) => {
    try {
      const { id, role_id, first_name, last_name } = currentUser;
      const newRole = getOppositeRole(role_id);
      const data = {
        first_name,
        last_name,
        email,
        role_id,
      };
      const res = await axios.put(`/admin/user/${id}`, data, {
        headers: {
          Accept: "application/json",
        },
      });
      const updatedUser = res.data.data;
      // If admin in current list of admins update user data
      const newUsers = admins.map((admin) => {
        return admin.id === id ? updatedUser : admin;
      });
      dispatch(setAdminUsers(newUsers));
    } catch (e) {
      console.log(e);
    }
    setSuccess(true);
  };
};
