import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import axios from "axios";

import Layout from "./ui/layout";
import Login from "./ui/login";
import Dashboard from "./ui/dashboard";
import NotificationDashboard from "./ui/notifications";
import AllUsers from "./ui/users/AllUsers";
import UserOverview from "./ui/users/User";
import Admin from "./ui/admin";
import Settings from "./ui/settings";
import Guides from "./ui/guides";
import HomeGuides from "./ui/home";
import Shopping from "./ui/shopping";
import Popup from "./ui/popup";
import Learning from "./ui/learning";
import { getModeratorStatus, logoutUser } from "./modules/authentication";
import { cancelSource, requestInterceptor } from "./modules/axiosConfig";

export default () => {
  const isModerator = useSelector(getModeratorStatus);
  const history = useHistory();
  const dispatch = useDispatch();

  axios.interceptors.request.use(requestInterceptor);
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      console.log(error);
      if (
        error &&
        error.response.status === 401 &&
        error.response.config.url !== "/v1/login"
      ) {
        // cancelSource.cancel();
        alert("Your session has expired please log back in.");
        dispatch(logoutUser());
        history.push("/login");
      }

      return Promise.reject(error);
    }
  );

  return (
    <Switch>
      <Route exact path="/">
        {false ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}
      </Route>
      {/* Login ups*/}
      <Route path="/login">
        <Login />
      </Route>
      {/* Dashboard ups*/}
      <Route path="/dashboard">
        <Layout>
          <Dashboard />
        </Layout>
      </Route>
      {/* Notification ups*/}
      <Route path="/notifications">
        <Layout>
          <NotificationDashboard />
        </Layout>
      </Route>

      {/* Pop ups*/}
      <Route path="/popups">
        <Layout>
          <Popup />
        </Layout>
      </Route>

      {/* Learning Section */}
      <Route path="/learning">
        <Layout>
          <Learning />
        </Layout>
      </Route>

      {/* Shop Section */}
      <Route path="/shop">
        <Layout>
          <Shopping />
        </Layout>
      </Route>

      {/* Gym Guides Section */}
      <Route path="/guides">
        <Layout>
          <Guides />
        </Layout>
      </Route>

      {/* Gym Guides Section */}
      <Route path="/home-guides">
        <Layout>
          <HomeGuides />
        </Layout>
      </Route>
      {/* Users Section */}
      <Route path="/users/:id">
        <Layout>
          <UserOverview />
        </Layout>
      </Route>
      <Route path="/users">
        <Layout>
          <AllUsers />
        </Layout>
      </Route>

      {/* Admin Section */}
      <Route path="/admin">
        <Layout>
          {isModerator ? <Redirect to="/dashboard" /> : <Admin />}
        </Layout>
      </Route>

      {/* Settings Section */}
      <Route path="/settings">
        <Layout>
          <Settings />
        </Layout>
      </Route>
    </Switch>
  );
};
