import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch, Link } from "react-router-dom";
import axios from "axios";

import { Grid, Box, makeStyles, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";

import TitleBar from "../components/TitleBar";
import { StyledButtonGradient } from "../components/StyledButtons";
import { SimpleFileUpload } from "../components/FormikFile";
import {
  selectLevels,
  selectOverviewImages,
} from "../../modules/home";
import { useSelector, useDispatch } from "react-redux";
import { createHandleUpdateOverviewImage } from "../../modules/home/effects";
import moment from "moment";

const useStyles = makeStyles({
  marginTop: {
    marginTop: 40,
  },
  buttonWidth: {
    minWidth: 300,
  },
  sectionNoTop: {
    padding: 16,
    paddingTop: 0,
  },
  section: {
    padding: 16,
  },
  sectionBase: {
    backgroundColor: "#F8F8F8",
    borderRadius: 12,
  },
});

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { url, _path } = useRouteMatch();
  const banners = useSelector(selectOverviewImages);
  const levels = useSelector(selectLevels);

  const handleImageUpdate = createHandleUpdateOverviewImage(dispatch);

  const handleAddHomeGuideLevel = () => {
    history.push(`${url}/add`);
  };

  return (
    <Grid container alignItems="flex-start" direction="column" spacing={3}>
      <TitleBar date={moment().format("L")} title={"Home Guides"}></TitleBar>
      <Grid
        alignItems="right"
        direction="row"
        container
      >
        <StyledButtonGradient
          className={classes.buttonResize}
          onClick={handleAddHomeGuideLevel}
        >
          Add New Level
        </StyledButtonGradient>
      </Grid>
      <Grid
        className={classes.marginTop}
        container
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Typography variant="body1">
          To edit Home Guides, please select a level below
        </Typography>
        {levels.map((level) => (
          <Box key={level} mt={2}>
            <StyledButtonGradient
              className={classes.buttonWidth}
              to={`${url}/${level}`}
              component={Link}
            >
              {level.charAt(0).toUpperCase() + level.slice(1)}
            </StyledButtonGradient>
          </Box>
        ))}
        <Box mt={2}>
          <Typography variant="body1">
            To edit Home Guides Overview Images, please select a level below
          </Typography>
        </Box>
      </Grid>
      {/* Image Sections Banner */}
      {banners.map(({ id, message, image, title }) => (
        <Grid key={id} container className={classes.marginTop}>
          <Typography variant="body1">{title}</Typography>
          <Grid container direction="column" className={classes.sectionBase}>
            <Formik
              key={id + message + JSON.stringify(image)}
              initialValues={{
                id,
                image: image,
                message: message || "",
              }}
              onSubmit={handleImageUpdate}
            >
              {(props) => {
                const isDisabled = !props.dirty;
                return (
                  <Form>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      className={classes.section}
                    >
                      <Box display="flex" flex={4}>
                        <Field
                          label="Image"
                          name="image"
                          component={SimpleFileUpload}
                        />
                      </Box>
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flex={1}
                        ml={1}
                      >
                        <StyledButtonGradient
                          disabled={isDisabled}
                          type="submit"
                        >
                          Save
                        </StyledButtonGradient>
                      </Box>
                    </Grid>
                    <Grid container className={classes.section}>
                      <Field
                        name="message"
                        placeholder="Message to users."
                        component={TextField}
                        InputProps={{ disableUnderline: true }}
                        label="Message"
                        fullWidth
                        multiline
                        rows="3"
                      />
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      ))}
      {/* End Image Section */}
    </Grid>
  );
};
