import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { sortBy, first } from "lodash";
import {
  addGuide,
  PLAN_DAYS_THREE,
  PLAN_DAYS_FOUR,
  PLAN_DAYS_FIVE,
  PLAN_DAYS_SIX,
  PLAN_DAYS_SEVEN,
  updateExerciseToGuide,
  addExerciseToGuide,
  deleteExerciseToGuide,
  updateOverviewImage,
  deleteWeek,
  updateGuideById,
  resetDay,
  addLevel,
} from ".";

const getGuideCountFromPlan = (plan) => {
  switch (plan) {
    case PLAN_DAYS_THREE:
      return 3;
    case PLAN_DAYS_FOUR:
      return 4;
    case PLAN_DAYS_FIVE:
      return 5;
    case PLAN_DAYS_SIX:
      return 6;
    case PLAN_DAYS_SEVEN:
      return 7;
    default:
      return 4;
  }
};

export const createHandleAddWeek = (week, plan, level, dispatch) => {
  return async () => {
    let values = {
      plan,
      level,
      week,
      type: "home",
      exercise_type_id: 1,
    };
    // Create New Link if id is undefined
    try {
      for (let i = 0; i < getGuideCountFromPlan(plan); i++) {
        values = { ...values, day: i + 1 };
        const response = await axios.post("/admin/guide", values, {
          headers: {
            Accept: "application/json",
          },
        });
        // Add guide with blank exercises
        const createdGuide = response.data.data;
        dispatch(addGuide({ ...createdGuide, exercises: [] }));
      }
    } catch (e) {
      console.log("Failed to create new guidees");
    }
  };
};

export const createHandleDeleteWeek = (week, plan, level, dispatch) => {
  return async () => {
    let data = {
      plan,
      level,
      week,
      type: "home",
    };
    // Create New Link if id is undefined
    try {
      const response = await axios.delete("/admin/guide/week", {
        headers: {
          Accept: "application/json",
        },
        data,
      });
      console.log("Deleting: ", level, plan, week);
      dispatch(deleteWeek(level, plan, week));
    } catch (e) {
      console.log("Failed to delete week new guidees");
    }
  };
};

export const createHandleAddExercise = (guideId, exercise, dispatch) => {
  return async () => {
    const { id, exercise_id, sets, reps, rep_label, message } = exercise;
    const data = {
      sets,
      reps,
      rep_label,
      message,
      exercise_id,
    };
    // Create New Link if id is undefined
    if (isNaN(id)) {
      try {
        const response = await axios.post(
          `/admin/guide/${guideId}/exercise`,
          data,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );

        const item = response.data.data;
        dispatch(addExerciseToGuide(guideId, item));
      } catch (e) {
        console.log("Failed to create exercise");
      }
    } else {
      try {
        const response = await axios.put(
          `/admin/guide/${guideId}/exercise/${id}`,
          data,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );

        const updatedItem = response.data.data;
        dispatch(updateExerciseToGuide(guideId, id, updatedItem));
      } catch (e) {
        console.log("Failed to update exercise");
      }
    }
  };
};

export const createHandleDeleteExercise = (
  guideId,
  id,
  index,
  dispatch,
  remove,
  setAdded
) => {
  return async () => {
    // If it is a new field don't send request just remove for list
    if (isNaN(id)) {
      remove(index);
      setAdded(false);
    } else {
      try {
        await axios.delete(`/admin/guide/${guideId}/exercise/${id}`);
        dispatch(deleteExerciseToGuide(guideId, id));
      } catch (e) {
        console.log("Failed to delete exercise");
      }
    }
  };
};

export const createHandleUpdateOverviewImage = (dispatch) => {
  return async (values, actions) => {
    const data = new FormData();
    const { id, image, message } = values;
    if (typeof image !== "string") {
      data.append("file", image);
    }
    data.append("message", message);
    try {
      const response = await axios.post(`/admin/guide/settings/${id}`, data, {
        headers: {
          Accept: "application/json",
        },
      });

      const updatedSectionImage = response.data.data;
      dispatch(updateOverviewImage(id, updatedSectionImage));
    } catch (e) {
      console.log("Failed to update image overview");
    }
  };
};

export const createHandleUpdateDay = (guideId, guide, dispatch) => {
  return async (e) => {
    const type = e.target.value;
    const data = {
      exercise_type_id: type,
    };
    try {
      const response = await axios.put(`/admin/guide/${guideId}`, data, {
        headers: {
          Accept: "application/json",
        },
      });
      const updatedItem = response.data.data;
      const updatedGuide = { ...guide, exercise_type_id: type };
      dispatch(updateGuideById(guideId, updatedGuide));
    } catch (e) {
      console.log("Failed to update exercise");
    }
  };
};

export const createHandleResetDay = (guide, dispatch) => {
  const { exercises } = guide;
  return async () => {
    try {
      for (let exercise of exercises) {
        const { id } = exercise;
        await axios.delete(`/admin/guide/${guide.id}/exercise/${id}`, {
          headers: {
            Accept: "application/json",
          },
        });
      }
      dispatch(resetDay(guide.id));
    } catch (e) {
      console.log("Failed to reset day");
    }
  };
};

export const createHandleAddLevel = (title, image, message, level, dispatch) => {
  return async () => {
    let values = {
      title,
      level,
      image,
      message,
      type: "home",
    };
    // Create New Link if id is undefined
    try {
      const response = await axios.post("/admin/guide/settings", values, {
        headers: {
          Accept: "application/json",
        },
      });
      // Add level to state
      const createdLevel = response.data.data;
      dispatch(addLevel(createdLevel.level));
    } catch (e) {
      console.log("Failed to create new level");
    }
  };
};
