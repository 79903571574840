import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, ListItem, SvgIcon, Box, Tooltip } from "@material-ui/core";
import NavigationLogo from "../../svgs/Navigation-Shop.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRightWidth: 1,
    borderRightColor: ({ active }) => (active ? "#F54E55" : "#DDDDDD"),
    borderRightStyle: "solid",
    justifyContent: "center",
    display: "flex",
    padding: 15,
  },
  icon: {
    color: ({ active }) => (active ? "red" : "gray"),
    fontSize: 24,
  },
}));

const NavItem = ({ link, Icon, active, title }) => {
  const classes = useStyles({ active });
  return (
    <Box className={classes.root} component={Link} to={link} key={link}>
      <Tooltip placement="right" title={title}>
        <Box>
          <Icon className={classes.icon} />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default NavItem;
