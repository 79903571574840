import axios from "axios";

// Axios handled logout on request getting 401 unautrhorized

export const cancelSource = axios.CancelToken.source();
// Request interceptor

export const requestInterceptor = (config) => {
  //   console.log(config);
  //   config.cancelToken = cancelSource.token;
  return config;
};
