import React from "react";

import { Typography,Grid, makeStyles } from "@material-ui/core";

export default ({ title, date }) => {
  return (
    <Grid direction="row" container justify="space-between">
      <Typography variant="h3">{title}</Typography>
      {date && <Typography variant="h5">{date}</Typography>}
    </Grid>
  );
};
