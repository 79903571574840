import React, { useState } from "react";
import clsx from "clsx";
import { ReactComponent as CheckedAcitve } from "../../svgs/icon-Checkbox Checked.svg";
import { ReactComponent as CheckedDisabled } from "../../svgs/icon-Checkbox Uncheck.svg";

import {
  Dialog,
  DialogTitle,
  Typography,
  makeStyles,
  Grid,
  List,
  DialogContent,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  withStyles,
} from "@material-ui/core";
import {
  StyledButton,
  StyledButtonGradient,
  StyledButtonDisabled,
} from "../components/StyledButtons";

const useStyles = makeStyles({
  resetModal: {
    height: 150,
    width: 300,
  },
  subscriptionModal: {
    height: 425,
    width: 400,
  },
  buttonWidth: {
    minWidth: 120,
  },
  round: {
    borderRadius: 16,
  },
  paper: {
    margin: 32,
    position: "relative",
    overflowY: "auto",
    borderRadius: 16,
  },
  centerList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

export const ResetDialog = ({ open, setOpen }) => {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const handleClick = async (e) => {
    let v = await new Promise((resolve) => {
      setTimeout(() => {
        console.log("Hiya from await");
        resolve(true);
      }, 2000);
      setSuccess(true);
    });
  };

  const handleClose = () => setOpen(false);
  const handleSuccessClose = () => {
    setOpen(false);
    setSuccess(false);
  };

  return (
    <Dialog
      transitionDuration={{ exit: 0 }}
      classes={{ paper: classes.paper }}
      className={classes.round}
      open={open}
      onClose={handleClose}
    >
      <DialogContent className={classes.round}>
        <Grid
          className={classes.resetModal}
          container
          alignItems="center"
          direction="column"
          justify="space-between"
        >
          {!success ? (
            <>
              <Grid>
                <Typography align="center" variant="body1">
                  Do you wish to send a reset password email to this user?
                </Typography>
              </Grid>
              <Grid container justify="space-between">
                <StyledButtonGradient
                  className={classes.buttonWidth}
                  onClick={handleClick}
                >
                  Yes
                </StyledButtonGradient>
                <StyledButton
                  className={classes.buttonWidth}
                  onClick={handleClose}
                >
                  No
                </StyledButton>
              </Grid>
            </>
          ) : (
            <>
              <Grid>
                <Typography align="center" variant="body1">
                  Reset Password email has been sent to this user’s email
                  address.
                </Typography>
              </Grid>
              <Grid container justify="center">
                <StyledButtonGradient
                  className={classes.buttonWidth}
                  onClick={handleSuccessClose}
                >
                  Okay
                </StyledButtonGradient>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export const EmailUpdateDialog = ({ open, setOpen }) => {
  const classes = useStyles();
  const handleClose = () => setOpen(false);
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      className={classes.round}
      open={open}
      onClose={handleClose}
    >
      <DialogContent className={classes.round}>
        <Grid
          className={classes.resetModal}
          container
          alignItems="center"
          direction="column"
          justify="space-between"
        >
          <Grid>
            <Typography align="center" variant="body1">
              New User Email Address has been updated!
            </Typography>
          </Grid>
          <Grid container justify="center">
            <StyledButtonGradient
              className={classes.buttonWidth}
              onClick={handleClose}
            >
              Okay
            </StyledButtonGradient>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const StyledListItem = withStyles({
  root: {
    background: "linear-gradient(#e93e66 0%, #f54e55 100%)",
    borderRadius: 8,
    border: 0,
    minHeight: 40,
    minHeight: 72,
    minWidth: 375,
    color: "white",
    marginTop: 16,
  },
  label: {
    color: "white",
    fontWeight: "bold",
  },
})(ListItem);

const StyledListItemDisabled = withStyles({
  root: {
    background: "#D6D6D6",
    borderRadius: 8,
    border: 0,
    minHeight: 72,
    minWidth: 375,
    marginTop: 16,
  },
  label: {
    color: "#A1A1A1",
    fontWeight: "bold",
  },
})(ListItem);

export const SubscriptionDialog = ({ open, setOpen }) => {
  const classes = useStyles();
  const [cancelSelected, setCancelSelect] = useState(false);
  const [resize, setResize] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setCancelSelect(false);
  };

  return (
    <Dialog
      transitionDuration={{ exit: 0 }}
      classes={{ paper: classes.paper }}
      className={classes.round}
      open={open}
      onClose={handleClose}
    >
      <DialogContent className={classes.round}>
        <Grid
          className={clsx(
            (resize || cancelSelected) && classes.resetModal,
            !cancelSelected && !resize && classes.subscriptionModal
          )}
          container
          alignItems="center"
          direction="column"
          justify="space-between"
        >
          {cancelSelected ? (
            <CancelContent setOpen={setOpen} setCancel={setCancelSelect} />
          ) : (
            <UpdateSubscriptionContent
              setOpen={setOpen}
              setResize={setResize}
              setCancel={setCancelSelect}
            />
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const SubscriptionListItem = ({ active, onClick, text, secondaryText }) => {
  return active ? (
    <StyledListItem>
      <ListItemText primary={text} secondary={secondaryText} />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="delete">
          <CheckedAcitve />
        </IconButton>
      </ListItemSecondaryAction>
    </StyledListItem>
  ) : (
    <StyledListItemDisabled onClick={onClick}>
      <ListItemText primary={text} secondary={secondaryText} />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="delete">
          <CheckedDisabled />
        </IconButton>
      </ListItemSecondaryAction>
    </StyledListItemDisabled>
  );
};

const UpdateSubscriptionContent = ({ setOpen, setCancel, setResize }) => {
  const classes = useStyles();
  const [success, setSuccess] = useState();
  // Connect to get current sub info
  const subscriptionInfo = {
    type: "monthly", // yearly, bi-yearly
  };
  const [original, setOriginal] = useState(subscriptionInfo.type);
  const [type, setType] = useState(subscriptionInfo.type);

  const handleClick = async (e) => {
    let v = await new Promise((resolve) => {
      setTimeout(() => {
        console.log("Hiya from subscription");
        resolve(true);
      }, 2000);
      setSuccess(true);
      setResize(true);
      setOriginal(type);
      // Set new subscription + refetch user info w/ dispatch
    });
  };
  const handleClose = () => setOpen(false);
  const handleSuccessClose = () => {
    setOpen(false);
    setResize(false);
    setSuccess(false);
  };

  return !success ? (
    <>
      <Grid>
        <Typography align="center" variant="body1">
          Change Subscription Plans
        </Typography>
      </Grid>
      <Grid>
        <List className={classes.centerList}>
          {/* monthly */}
          <SubscriptionListItem
            onClick={() => setType("monthly")}
            active={type === "monthly"}
            text={"Monthly"}
            secondaryText={"$14.99 a month"}
          />
          {/* bi-annual */}
          <SubscriptionListItem
            onClick={() => setType("bi-yearly")}
            active={type === "bi-yearly"}
            text={"6 Months"}
            secondaryText={"$12.99 a month"}
          />
          {/* annual */}
          <SubscriptionListItem
            onClick={() => setType("yearly")}
            active={type === "yearly"}
            text={"12 Months"}
            secondaryText={"$9.99 a month"}
          />
          {/* cancel */}
          <ListItem onClick={() => setCancel(true)}>
            <ListItemText align="center" primary="Cancel Subscription" />
          </ListItem>
        </List>
      </Grid>
      <Grid container justify="space-between">
        {type !== original ? (
          <StyledButtonGradient
            className={classes.buttonWidth}
            onClick={handleClick}
          >
            Update
          </StyledButtonGradient>
        ) : (
          <StyledButtonDisabled className={classes.buttonWidth} disabled>
            Update
          </StyledButtonDisabled>
        )}
        <StyledButton className={classes.buttonWidth} onClick={handleClose}>
          Not Now
        </StyledButton>
      </Grid>
    </>
  ) : (
    <>
      <Grid>
        <Typography align="center" variant="body1">
          {`You have updated user’s Subscription plan to ${type} plan. An
                  email will send to user and they will confirm to upgrade the
                  subscription.`}
        </Typography>
      </Grid>
      <Grid container justify="center">
        <StyledButtonGradient
          className={classes.buttonWidth}
          onClick={handleSuccessClose}
        >
          Okay
        </StyledButtonGradient>
      </Grid>
    </>
  );
};

const CancelContent = ({ setOpen, setCancel }) => {
  const [success, setSuccess] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    setSuccess(false);
    setCancel(false);
  };

  const handleClick = async () => {
    let v = await new Promise((resolve) => {
      setTimeout(() => {
        console.log("Hiya from cancel");
        resolve(true);
      }, 2000);
      setSuccess(true);
      // Set new subscription + refetch user info w/ dispatch
    });
  };

  return !success ? (
    <>
      <Grid>
        <Typography align="center" variant="body1">
          Do you wish to cancel user’s subscription plan?
        </Typography>
      </Grid>
      <Grid container justify="space-between">
        <StyledButtonGradient
          className={classes.buttonWidth}
          onClick={handleClick}
        >
          Yes
        </StyledButtonGradient>
        <StyledButton className={classes.buttonWidth} onClick={handleClose}>
          No
        </StyledButton>
      </Grid>
    </>
  ) : (
    <>
      <Grid>
        <Typography align="center" variant="body1">
          User’s subscription has been removed. They will receive an email to
          reselect another plan.
        </Typography>
      </Grid>
      <Grid container justify="center">
        <StyledButtonGradient
          className={classes.buttonWidth}
          onClick={handleClose}
        >
          Okay
        </StyledButtonGradient>
      </Grid>
    </>
  );
};
