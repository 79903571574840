import React, { useRef } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuIcon from "@material-ui/icons/Menu";
import { useDrag, useDrop } from "react-dnd";
import { IconButton, Box, Grid, Button, Tooltip } from "@material-ui/core";

export const MOVE_TYPE = "Grid";

const MoveableGrid = ({
  id,
  children,
  index,
  moveItem,
  deleteIcon,
  handleDeleteClick,
  TitleComponent,
  disabled,
  ...restProps
}) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: MOVE_TYPE,
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: MOVE_TYPE, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  if (disabled) {
    return (
      <Grid style={{ opacity }} {...restProps}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          <Box
            style={{ width: "90%" }}
            alignItems="center"
            display="flex"
            flexDirection="row"
          >
            <IconButton ref={ref}>
              <MenuIcon />
            </IconButton>
            {TitleComponent && TitleComponent}
          </Box>
          {deleteIcon && handleDeleteClick && (
            <IconButton onClick={handleDeleteClick}>
              <DeleteIcon />
            </IconButton>
          )}
        </Grid>
        {/* top row of the moveable component */}

        {children}
      </Grid>
    );
  }
  return (
    <Grid ref={preview} style={{ opacity }} {...restProps}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
      >
        <Box
          style={{ width: "90%" }}
          alignItems="center"
          display="flex"
          flexDirection="row"
        >
          <IconButton ref={ref}>
            <MenuIcon />
          </IconButton>
          {TitleComponent && TitleComponent}
        </Box>
        {deleteIcon && handleDeleteClick && (
          <IconButton onClick={handleDeleteClick}>
            <DeleteIcon />
          </IconButton>
        )}
      </Grid>
      {/* top row of the moveable component */}

      {children}
    </Grid>
  );
};
export default MoveableGrid;
