import { put, all, call, delay, takeLatest } from "redux-saga/effects";
import moment from "moment";
import axios from "axios";
import { UPDATE_EXCERCISES } from "../learning";
import accessories from "../../ui/shopping/accessories";
import { createSelector } from "reselect";
import { first } from "lodash";
import { SUPPLEMENT_TYPE, ACCESSORY_TYPE } from "./effects";
/**
 * Actions
 */

export const SET_SUPPLEMENTS = "SHOP/SUPPLEMENTS";
export const setSupplements = (supplements) => ({
  type: SET_SUPPLEMENTS,
  supplements,
});

export const SET_ACCESSORIES = "SHOP/ACCESSORY_SET";
export const setAccessory = (items) => ({
  type: SET_ACCESSORIES,
  items,
});

export const UPDATE_SUPPLEMENTS = "SHOP/SUPPLEMENTS_UPDATE";
export const updateSupplement = (id, supplement) => ({
  type: UPDATE_SUPPLEMENTS,
  id,
  supplement,
});

export const UPDATE_ACCESSORY = "SHOP/ACCESSORY_UPDATE";
export const updateAccessory = (id, item) => ({
  type: UPDATE_ACCESSORY,
  id,
  item,
});

export const DELETE_SUPPLEMENT = "SHOP/SUPPLEMENT_DELETE";
export const deleteSupplement = (id) => ({
  type: DELETE_SUPPLEMENT,
  id,
});

export const DELETE_ACCESSORY = "SHOP/ACCESSORY_DELETE";
export const deleteAccessory = (id) => ({
  type: DELETE_ACCESSORY,
  id,
});

export const DELETE_EXCERCISES = "LEARNING/EXERCISES_DELETE";
export const deleteExercises = (id) => ({
  type: DELETE_EXCERCISES,
  id,
});

export const ADD_SUPPLEMENT = "SHOP/SUPPLEMENT_ADD";
export const addSupplement = (supplement) => ({
  type: ADD_SUPPLEMENT,
  supplement,
});

export const ADD_ACCESSORY = "SHOP/ACCESSORY_ADD";
export const addAccessory = (item) => ({
  type: ADD_ACCESSORY,
  item,
});

export const SET_OVERVIEW_IMAGES = "SHOP/SET_OVERVIEW_IMAGES";
export const setOverviewImages = (images) => ({
  type: SET_OVERVIEW_IMAGES,
  images,
});

export const UPDATE_OVERVIEW_IMAGE = "SHOP/UPDATE_OVERVIEW_IMAGE";
export const updateOverviewImage = (id, image) => ({
  type: UPDATE_OVERVIEW_IMAGE,
  id,
  image,
});

export const SET_ACCESSORY_BANNER = "SHOP/SET_ACCESSORY_BANNER";
export const setAccessoryBanner = (item) => ({
  type: SET_ACCESSORY_BANNER,
  item,
});
export const SET_SUPPLEMENT_BANNER = "SHOP/SET_SUPPLEMENT_BANNER";
export const setSupplementBanner = (item) => ({
  type: SET_SUPPLEMENT_BANNER,
  item,
});

/**
 * Reducer
 */
const initState = {
  accessories: [],
  supplements: [],
  overviewImages: [],
};

export const shopReducer = (state = initState, action) => {
  switch (action.type) {
    // ------- Overview --------- //
    case SET_OVERVIEW_IMAGES:
      return { ...state, overviewImages: action.images };
    case UPDATE_OVERVIEW_IMAGE:
      const newOverviewImages = state.overviewImages.map((image) =>
        image.id === action.id ? action.image : image
      );
      return { ...state, overviewImages: newOverviewImages };
    // ------- Supplements --------- //
    case SET_SUPPLEMENT_BANNER:
      return { ...state, supplementBanner: action.item };
    case SET_SUPPLEMENTS:
      return { ...state, supplements: action.supplements };
    case UPDATE_SUPPLEMENTS:
      const newSupplements = state.supplements.map((s) =>
        s.id === action.id ? action.supplement : s
      );
      return { ...state, supplements: newSupplements };
    case ADD_SUPPLEMENT:
      const addedSupplements = [...state.supplements, action.supplement];
      return { ...state, supplements: addedSupplements };
    case DELETE_SUPPLEMENT:
      const filteredSupplements = state.supplements.filter(
        (s) => s.id !== action.id
      );
      return { ...state, supplements: filteredSupplements };
    // ------- Accessories --------- //
    case SET_ACCESSORY_BANNER:
      return { ...state, accessoryBanner: action.item };
    case SET_ACCESSORIES:
      return { ...state, accessories: action.items };
    case UPDATE_ACCESSORY:
      const newList = state.accessories.map((item) =>
        item.id === action.id ? action.item : item
      );
      return { ...state, accessories: newList };
    case ADD_ACCESSORY:
      const addedAccessories = [...state.accessories, action.item];
      return { ...state, accessories: addedAccessories };
    case DELETE_ACCESSORY:
      const filteredAccessories = state.accessories.filter(
        (l) => l.id !== action.id
      );
      return { ...state, accessories: filteredAccessories };
    default:
      return state;
  }
};

/**
 * Sagas
 */
function* handleSetActiveUser() {
  yield delay(100);
  const userData = {
    subscriptionInfo: {
      frequency: "monthly",
      cost: 10.0,
      freeTrial: true,
      freeTrialEnd: "9/30",
    },
    joinInfo: {
      since: "06/19/2020",
    },
    paymentInfo: [
      {
        date: moment().calendar(),
        amount: 100.0,
        status: "Complete",
      },
      {
        date: moment().calendar(),
        amount: 100.0,
        status: "Failed",
      },
    ],
  };
  // yield put(setUserDetails(userData));
}

function* watchSelectUser() {
  yield takeLatest([], handleSetActiveUser);
}

function* handleGetUsers() {
  try {
    let params = {};
    const res = yield call(axios.get, "/admin/site-users", {
      params,
    });
  } catch (e) {
    console.log(e);
  }
}

function* watchGetUsers() {
  yield takeLatest([], handleGetUsers);
}

export function* rootSaga() {
  yield all([call(watchSelectUser), call(watchGetUsers)]);
}

/**
 *  Selectors
 */

export const selectSupplements = (state) => state.shop.supplements;
export const selectAccessories = (state) => state.shop.accessories;
export const selectOverviewImages = (state) => state.shop.overviewImages || [];

const PROMO_TITLE = "Promotion Banner";
export const selectSupplementBanner = createSelector(
  selectOverviewImages,
  (images) =>
    first(
      images.filter(
        (i) => i.title === PROMO_TITLE && i.type === SUPPLEMENT_TYPE
      )
    )
);
export const selectAccessoryBanner = createSelector(
  selectOverviewImages,
  (images) =>
    first(
      images.filter((i) => i.title === PROMO_TITLE && i.type === ACCESSORY_TYPE)
    )
);
