export const Tabs = {
  AUTOMATED: "auto",
  PROMOTIONS: "promo",
};

export const SET_TAB = "PUSH/SET_TAB";
export const setNotificationTab = (tab) => ({
  type: SET_TAB,
  tab,
});

export const SET_FILTER = "PUSH/SET_VISIBILITY_FILTER";
export const setNotificationFilter = (filter) => ({
  type: SET_FILTER,
  filter,
});

/** Automated Push notification actions  */
export const SET_AUTOMATED = "PUSH/SET_AUTOMATED";
export const setAutomated = (notifications) => ({
  type: SET_AUTOMATED,
  notifications,
});
export const UPDATE_AUTOMATED = "PUSH/UPDATE_AUTOMATED";
export const updateAutomated = (id, notification) => ({
  type: UPDATE_AUTOMATED,
  id,
  notification,
});
export const DELETE_AUTOMATED = "PUSH/DELETE_AUTOMATED";
export const deleteAutomated = (id) => ({
  type: DELETE_AUTOMATED,
  id,
});

/** User Push notification actions  */
export const SET_USER_PUSH = "PUSH/SET_USER_PUSH";
export const setUserPush = (notifications) => ({
  type: SET_USER_PUSH,
  notifications,
});
export const UPDATE_USER_PUSH = "PUSH/UPDATE_USER_PUSH";
export const updateUserPush = (id, notification) => ({
  type: UPDATE_USER_PUSH,
  id,
  notification,
});

export const ADD_USER_PUSH = "PUSH/ADD_USER_PUSH";
export const addUserPush = (notification) => ({
  type: ADD_USER_PUSH,
  notification,
});
export const DELETE_USER_PUSH = "PUSH/DELETE_USER_PUSH";
export const deleteUserPush = (id) => ({
  type: DELETE_USER_PUSH,
  id,
});

const initState = {
  tab: Tabs.AUTOMATED,
  usersNotifications: [],
  automatedNotifications: [],
};

export const notificationReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_TAB:
      return {
        ...state,
        tab: action.tab,
      };
    case SET_FILTER:
      return { ...state, users: action.filter };

    /* AUTOMATED */
    case SET_AUTOMATED:
      return { ...state, automatedNotifications: action.notifications };
    case UPDATE_AUTOMATED:
      const newAuto = state.automatedNotifications.map((p) =>
        p.id === action.id ? action.notification : p
      );
      return { ...state, automatedNotifications: newAuto };
    case DELETE_AUTOMATED:
      const filteredAutomated = state.automatedNotifications.filter(
        (p) => p.id !== action.id
      );
      return { ...state, automatedNotifications: filteredAutomated };
    /* User Created */
    case SET_USER_PUSH:
      return { ...state, usersNotifications: action.notifications };
    case UPDATE_USER_PUSH:
      const newUser = state.usersNotifications.map((p) =>
        p.id === action.id ? action.notification : p
      );
      return { ...state, usersNotifications: newUser };
    case DELETE_USER_PUSH:
      const filteredUserPops = state.usersNotifications.filter(
        (p) => p.id !== action.id
      );
      return { ...state, usersNotifications: filteredUserPops };
    case ADD_USER_PUSH:
      const addedPopup = [...state.usersNotifications, action.notification];
      return { ...state, usersNotifications: addedPopup };
    default:
      return state;
  }
};

export const selectTab = (state) => state.notification.tab;
export const selectUserNotifications = (state) =>
  state.notification.usersNotifications || [];
export const selectAutomatedNotifications = (state) =>
  state.notification.automatedNotifications || [];
