import { put, all, call, delay, takeLatest } from "redux-saga/effects";
import moment from "moment";

/**
 * Actions
 * */
export const SET_ADMIN_USERS = "ADMIN/SET_USERS";
export const setAdminUsers = (users) => ({
  type: SET_ADMIN_USERS,
  users,
});

export const ADD_ADMIN_USER = "ADMIN/ADD_USER";
export const addAdminUser = (user) => ({
  type: ADD_ADMIN_USER,
  user,
});

export const SET_CURRENT_ADMIN = "ADMIN/SET_CURRENT_ADMIN";
export const setCurrentAdmin = (user) => ({
  type: SET_CURRENT_ADMIN,
  user,
});

export const SET_ROLES = "ADMIN/SET_ROLES";
export const setRoles = (roles) => ({
  type: SET_ROLES,
  roles,
});
/**
 * Reducer
 * */
const initState = {
  users: [],
  roles: [],
  currentAdmin: null,
};

export const adminReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_ADMIN_USERS:
      return { ...state, users: action.users };
    case ADD_ADMIN_USER:
      return { ...state, users: [...state.users, action.user] };
    case SET_CURRENT_ADMIN:
      return { ...state, currentAdmin: action.user };
    case SET_ROLES:
      return { ...state, roles: action.roles };
    default:
      return state;
  }
};

/**
 * Sagas
 * */
function* handleSetActiveUser() {
  yield delay(100);
  const userData = {
    subscriptionInfo: {
      frequency: "monthly",
      cost: 10.0,
      freeTrial: true,
      freeTrialEnd: "9/30",
    },
    joinInfo: {
      since: "06/19/2020",
    },
    paymentInfo: [
      {
        date: moment().calendar(),
        amount: 100.0,
        status: "Complete",
      },
      {
        date: moment().calendar(),
        amount: 100.0,
        status: "Failed",
      },
    ],
  };
  //   yield put(setUserDetails(userData));
}

function* watchSelectUser() {
  yield takeLatest([], handleSetActiveUser);
}

export default function* root() {
  yield all([call(watchSelectUser)]);
}

/**
 * Selectors
 * */

export const selectAdminUsers = (state) => state.admin.users || [];
export const selectCurrentAdmin = (state) => state.admin.currentAdmin;
export const selectRoles = (state) => state.admin.roles || [];
