import React from "react";
import moment from "moment";
import { Grid, makeStyles, Typography, Box } from "@material-ui/core";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { NavigationLink } from "../components/TabNavigationLink";
import LoggedWorkouts from "./LoggedWorkouts";
import Analytics from "./Analytics";
import DashboardNavigation from "./DashboardNavigation";

const useStyles = makeStyles((theme) => ({
  titleMargin: {
    marginTop: 20,
  },
  // dropdownMenu: {
  //   height: 48,
  //   marginBottom: 40,
  //   marginTop: 40,
  //   backgroundColor: "#F2F0F0",
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   borderRadius: 8,
  // },
  // select: {
  //   paddingLeft: 10,
  //   paddingRight: 10,
  //   minWidth: "25ch",
  //   borderBottomWidth: 0,
  //   backgroundColor: "#F2F0F0",
  //   display: "flex",
  //   justifyContent: "flex-start",
  // },
}));

export default () => {
  const classes = useStyles();
  const { url, path } = useRouteMatch();

  return (
    <Grid container alignItems="flex-start" direction="column" spacing={3}>
      <Grid direction="row" container justify="space-between">
        <Typography variant="h3">{"Dashboard"}</Typography>
        <Typography variant="h5">{moment().format("L")}</Typography>
      </Grid>
      <Grid
        className={classes.titleMargin}
        direction="row"
        container
        justify="flex-start"
      >
        <NavigationLink to={`${url}/logged`} title={"Logged Workouts"} />
        <Box ml={2}>
          <NavigationLink to={`${url}/analytics`} title={"Workout Analytics"} />
        </Box>
      </Grid>
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}/logged`} />
        </Route>
        <Route path={`${path}/analytics`}>
          <Analytics parentUrl={url} />
        </Route>
        <Route path={`${path}/logged`}>
          <LoggedWorkouts parentUrl={url} />
        </Route>
      </Switch>
    </Grid>
  );
};
