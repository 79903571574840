import React from "react";
import { useRouteMatch, Link, useHistory } from "react-router-dom";
import { makeStyles, Typography, ButtonBase } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  activeColor: {
    color: "#F54E55",
  },
  inactiveColor: {
    color: "#DDDDDD",
  },
}));

export const NavigationLink = ({ to, title, replace = false }) => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch({
    path: to,
  });

  if (replace) {
    return (
      <ButtonBase onClick={() => history.replace(to)}>
        <Typography
          align="left"
          variant="body1"
          className={match ? classes.activeColor : classes.inactiveColor}
        >
          {title}
        </Typography>
      </ButtonBase>
    );
  }

  return (
    <ButtonBase component={Link} to={to}>
      <Typography
        align="left"
        variant="body1"
        className={match ? classes.activeColor : classes.inactiveColor}
      >
        {title}
      </Typography>
    </ButtonBase>
  );
};
