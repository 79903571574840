import React, { useState } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  Typography,
  makeStyles,
  Grid,
  List,
  DialogContent,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  withStyles,
} from "@material-ui/core";
import {
  StyledButton,
  StyledButtonGradient,
  StyledButtonDisabled,
} from "../components/StyledButtons";

const useStyles = makeStyles({
  resetModal: {
    height: 150,
    width: 300,
  },
  subscriptionModal: {
    height: 425,
    width: 400,
  },
  buttonWidth: {
    minWidth: 120,
  },
  round: {
    borderRadius: 16,
  },
  paper: {
    margin: 32,
    position: "relative",
    overflowY: "auto",
    borderRadius: 16,
  },
  centerList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

export const LogoutDialog = ({ open, setOpen }) => {
  const classes = useStyles();
  const history = useHistory();
  const handleClick = async (e) => {
    let v = await new Promise((resolve) => {
      history.push("/login");
      setTimeout(() => {
        console.log("Logging Out");
        resolve(true);
      }, 2000);
      setOpen(false);
    });
  };

  const handleClose = () => setOpen(false);
  const handleSuccessClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      transitionDuration={{ exit: 0 }}
      classes={{ paper: classes.paper }}
      className={classes.round}
      open={open}
      onClose={handleClose}
    >
      <DialogContent className={classes.round}>
        <Grid
          className={classes.resetModal}
          container
          alignItems="center"
          direction="column"
          justify="space-between"
        >
          <>
            <Grid>
              <Typography align="center" variant="body1">
                Do you wish to logout Slay? You will need to relogin again to
                use the portal.
              </Typography>
            </Grid>
            <Grid container justify="space-between">
              <StyledButtonGradient
                className={classes.buttonWidth}
                onClick={handleClick}
              >
                Yes
              </StyledButtonGradient>
              <StyledButton
                className={classes.buttonWidth}
                onClick={handleClose}
              >
                No
              </StyledButton>
            </Grid>
          </>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
