export const getActiveState = (formik, itemName, index, fields, fileFields) => {
  let active = false;
  for (let field of fields) {
    const fieldName = `${itemName}[${index}].${field}`;
    const meta = formik.getFieldMeta(fieldName);
    active = active || meta.initialValue !== meta.value;
    // active = active || meta.initialValue !== meta.value;
  }
  for (let field of fileFields) {
    const fieldName = `${itemName}[${index}].${field}`;
    const meta = formik.getFieldMeta(fieldName);
    active = active || meta.initialValue !== meta.value;
  }
  return active;
};
