import React, { useEffect } from "react";
import { Formik } from "formik";

import { Switch, Route, Redirect } from "react-router-dom";
import { Select, TextField } from "formik-material-ui";

import {
  Grid,
  Box,
  makeStyles,
  MenuItem,
  Typography,
  IconButton,
  FormControl,
} from "@material-ui/core";

import DayForm, {
  KEY_DAY_FORM,
  KEY_EXERCISES,
  KEY_TYPE,
  WorkoutTypes,
} from "./DayBuilder";
import { useSelector } from "react-redux";
import { selectActiveGuides, selectGuidesForWeek } from "../../modules/guides";

export default ({ path, url }) => {
  const initialForms = {};
  // const guides = useSelector(selectActiveGuides);
  const guides = useSelector(selectGuidesForWeek);

  guides.forEach((guide, index) => {
    const dayFormId = `${KEY_DAY_FORM}${index}`;
    const dayForm = {
      [KEY_TYPE]: guide.exercise_type_id || 1,
      [KEY_EXERCISES]: guide.exercises || [],
    };
    initialForms[dayFormId] = dayForm;
  });

  return (
    <Formik key={JSON.stringify(initialForms)} initialValues={initialForms}>
      {(formikProps) => {
        return (
          <Switch>
            <Route exact path={path}>
              <Redirect to={`${url}/1`} />
            </Route>
            <Switch>
              <Route path={`${path}/:day`}>
                <DayForm formikProps={formikProps} parentUrl={url} />
              </Route>
            </Switch>
          </Switch>
        );
      }}
    </Formik>
  );
};
