import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        textTransform: "none",
      },
    },
    MuiInputBase: {
      input: {
        backgroundColor: "white",
        paddingLeft: 8,
        paddingTop: 8,
        minHeight: 32,
        borderRadius: 8,
        marginRight: 8,
        "&:invalid": {
          boxShadow: "none",
          backgroundColor: "#FFD8D8",
          color: "#E50909",
        },
        "& .Mui-error input": {
          boxShadow: "none",
          backgroundColor: "#FFD8D8",
          color: "#E50909",
        },
      },

      inputMultiline: {
        paddingLeft: 8,
        paddingTop: 8,
        height: "auto",
      },
    },
    MuiFormControl: {
      root: {
        "& .Mui-error input": {
          boxShadow: "none",
          backgroundColor: "#FFD8D8",
          color: "#E50909",
        },
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: "translate(0, -1px) scale(0.75)",
      },
    },
    MuiFormHelperText: {
      root: {
        "&$error": {
          // position: "absolute",
          right: 0,
          top: -20,
          color: "#E50909",
        },
      },
    },
  },
  props: {
    // Name of the component ⚛️
    MuiInput: {
      // The default props to change
      //   disableRipple: true, // No more ripple, on the whole application 💣!
      disableUnderline: true,
    },
    MuiInputLabel: {
      // The default props to change
      shrink: true,
      focused: false,
    },
  },
});

export default theme;
