import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 24,
  },
  content: {
    marginLeft: 24,
    display: "flex",
    flexDirection: "column",
  },
}));
export default ({ count, info, Icon }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <div>
        <Icon />
      </div>
      <div className={classes.content}>
        <Typography color="textSecondary" variant="body1">
          {info}
        </Typography>
        <Typography align="left" color="textPrimary" variant="body1">
          {count || 0}
        </Typography>
      </div>
    </Box>
  );
};
