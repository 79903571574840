import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { sortBy } from "lodash";
import {
  setAutomated,
  setUserPush,
  addUserPush,
  updateUserPush,
  deleteUserPush,
} from ".";
import moment from "moment";

export const HOME_TYPE = "home guides";
export const GYM_TYPE = "gym guides";

export const useAutomated = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const getAutomated = async () => {
      // const response = await axios.get("/admin/automated-pop-ups", {
      //   params: {
      //     page,
      //   },
      // });
      // const { data } = response;
      // const retrievedItems = data.data;
      // const homeTypes = retrievedItems.filter(
      //   (item) => item.type === SUPPLEMENT_TYPE
      // );
      // const items = retrievedItems.filter(
      //   (item) => item.type === ACCESSORY_TYPE
      // );
      // dispatch(setAutomated(retrievedItems));
      const tester = [];
      dispatch(setAutomated(tester));
      // dispatch(setAccessory(items));
      // setTotal(data.meta.total);
    };

    getAutomated();
  }, [page]);

  return {
    total,
    page,
    setPage,
  };
};

export const useUserNotificatons = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const getPopups = async () => {
      try {
        const response = await axios.get("/admin/push-notifications", {
          params: {
            page,
          },
        });
        const { data } = response;
        const retrievedItems = data.data;
        // const homeTypes = retrievedItems.filter(
        //   (item) => item.type === SUPPLEMENT_TYPE
        // );
        // const items = retrievedItems.filter(
        //   (item) => item.type === ACCESSORY_TYPE
        // );
        const fixedDates = retrievedItems.map((item) => ({
          ...item,
          send_date: moment(item.send_date),
        }));
        dispatch(setUserPush(fixedDates));
        // dispatch(setAccessory(items));
        // setTotal(data.meta.total);
      } catch (e) {}
    };

    getPopups();
  }, [page]);

  return {
    total,
    page,
    setPage,
  };
};

export const createHandleAddPush = (id, push, dispatch) => {
  return async () => {
    const { id, title, image, message, send_date } = push;
    const data = new FormData();

    data.append("message", message);
    data.append("title", title);
    data.append("send_date", send_date.format("YYYY-MM-DD HH:mm:ss"));

    // Create New Link if id is undefined
    if (isNaN(id)) {
      data.append("image", image);
      try {
        const response = await axios.post("/admin/push-notifications", data, {
          headers: {
            Accept: "application/json",
          },
        });

        const item = response.data.data;
        const fixedItem = {
          ...item,
          send_date: moment(item.send_date),
        };
        dispatch(addUserPush(fixedItem));
      } catch (e) {
        console.log("Failed to create notification");
      }
    } else {
      if (typeof image !== "string") {
        data.append("image", image);
      }
      try {
        const response = await axios.post(
          `/admin/push-notifications/${id}`,
          data,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );

        const updatedItem = response.data.data;
        const fixedItem = {
          ...updatedItem,
          send_date: moment(updatedItem.send_date),
        };
        dispatch(updateUserPush(id, fixedItem));
      } catch (e) {
        console.log("Failed to update notification");
      }
    }
  };
};

export const createHandleDeletePush = (id, index, dispatch, remove) => {
  return async () => {
    // If it is a new field don't send request just remove for list
    if (isNaN(id)) {
      remove(index);
    } else {
      try {
        await axios.delete(`/admin/push-notifications/${id}`);
        dispatch(deleteUserPush(id));
      } catch (e) {
        console.log("Failed to delete notification");
      }
    }
  };
};
