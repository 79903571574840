import React from "react";
import clsx from "clsx";
import { Box, Typography, Card, CardContent, makeStyles } from "@material-ui/core";
import { ReactComponent as ColdIcon } from "../../svgs/icon-Cold.svg";
import { ReactComponent as WarmIcon } from "../../svgs/icon-Warm.svg";
import { ReactComponent as HotIcon } from "../../svgs/icon-Hot.svg";

const useStyles = makeStyles((theme) => ({
  rounded: {
    borderRadius: 24,
    maxWidth: 200,
    minWidth: 177,
    maxHeight: 200,
    minHeight: 152,
    margin: 10,
    marginLeft: 0,
    backgroundColor: "#FAF6F6",
  },
  center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  addColor: {
    color: "#F54E55",
  },
}));

const getIcon = (type) => {
  switch (type) {
    case "hot":
      return HotIcon;
    case "cold":
      return ColdIcon;
    case "warm":
      return WarmIcon;
    default:
      return HotIcon;
  }
};

export default ({ count, type, typeTitle }) => {
  const classes = useStyles();
  const Icon = getIcon(type);
  return (
    <Card elevation={0} className={classes.rounded}>
      <CardContent className={classes.center}>
        <Box>
          <Icon />
        </Box>
        <Box mt={2}>
          <Typography className={clsx(classes.addColor)} variant="h4">
            {count}
          </Typography>
          <Typography variant="subtitle1">{typeTitle}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
