import React from "react";
import { withStyles, InputBase, Select } from "@material-ui/core";
import { fieldToSelect } from "formik-material-ui";

const NakedSelect = withStyles((theme) => ({
  input: {
    display: "flex",
    justifyContent: "space-between",
  },
}))(InputBase);

export const FormikNakedSelect = (props) => (
  <Select input={NakedSelect} {...fieldToSelect(props)} />
);

export default NakedSelect;
