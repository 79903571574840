import { all, call } from "redux-saga/effects";

import { rootSaga as userSaga } from "./users";
import { rootSaga as authSaga } from "./authentication";
import { rootSaga as guideSaga } from "./guides";
import { rootSaga as homeSaga } from "./home";

export default function* rootSaga() {
  yield all([call(userSaga), call(authSaga), call(guideSaga), call(homeSaga)]);
}
