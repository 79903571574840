import React from "react";
import { getIn } from "formik";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import Clear from "@material-ui/icons/Clear";
import { uuidv4 } from "./utils";

const useStyles = makeStyles({
  base: {
    backgroundColor: "white",
    minHeight: 48,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 8,
    marginTop: 16,
  },
  padLeft: { marginLeft: 8 },
  fakeLabel: {
    top: 0,
    left: 0,
    position: "absolute",
    color: "rgba(0, 0, 0, 0.54)",
    padding: 0,
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "0.00938em",
    transform: "translate(0, -1px) scale(0.75)",
    transformOrigin: "top left",
  },
});
export const SimpleFileUpload = ({
  field,
  form: { isSubmitting, touched, errors, setFieldValue },
  label,
  labelId,
  disabled = false,
  InputProps,
  InputLabelProps,
  placeholder,
}) => {
  const classes = useStyles();
  const error = getIn(touched, field.name) && getIn(errors, field.name);
  const generatedId = uuidv4();

  let fileName = "";

  if (field.value) {
    fileName = field.value.name || field.value;
    const lastPathIndex = fileName.lastIndexOf("/");

    if (lastPathIndex > -1) {
      fileName = fileName.substring(lastPathIndex + 1);
    }
  }

  return (
    <>
      <FormControl fullWidth>
        {label && (
          <Typography className={classes.fakeLabel}>
            {label || "label"}
          </Typography>
        )}
        <ButtonBase component="label" id={labelId || generatedId}>
          <Input
            error={!!error}
            inputProps={{
              type: "file",
              labelId: labelId || generatedId,
              style: { display: "none" },
              disabled: disabled || isSubmitting,
              name: field.name,
              onChange: (event) => {
                const file = event.currentTarget.files[0];
                setFieldValue(field.name, file);
              },
            }}
            {...InputProps}
          ></Input>
          {field.value ? (
            <Box className={classes.base}>
              <Box ml={1}>
                <Typography>{fileName}</Typography>
              </Box>
              <IconButton
                onClick={() => {
                  setFieldValue(field.name, null);
                }}
              >
                <Clear />
              </IconButton>
            </Box>
          ) : (
            <Box className={classes.base}>
              <Typography className={classes.padLeft} color="textSecondary">
                {placeholder || "Click to Upload Image"}
              </Typography>
            </Box>
          )}
        </ButtonBase>
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    </>
  );
};

export const NoFormFileDisplay = ({ label, name }) => {
  const classes = useStyles();
  let fileName = "";

  if (name) {
    fileName = name;
    const lastPathIndex = fileName.lastIndexOf("/");
    if (lastPathIndex > -1) {
      fileName = fileName.substring(lastPathIndex + 1);
    }
  }
  return (
    <FormControl fullWidth>
      {label && (
        <Typography className={classes.fakeLabel}>
          {label || "Label"}
        </Typography>
      )}
      <Box className={classes.base}>
        <Box ml={1}>
          <Typography>{fileName}</Typography>
        </Box>
        <IconButton>
          <Clear />
        </IconButton>
      </Box>
    </FormControl>
  );
};
