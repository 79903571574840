import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { sortBy, isEmpty } from "lodash";
import {
  selectSupplements,
  selectAccessories,
  setSupplements,
  setAccessory,
  updateSupplement,
  updateAccessory,
  deleteAccessory,
  deleteSupplement,
  addAccessory,
  addSupplement,
  setOverviewImages,
  updateOverviewImage,
} from ".";

export const ACCESSORY_TYPE = "slay accessory";
export const SUPPLEMENT_TYPE = "product line";
export const OVERVIEW_TYPE = "overview";

export const useShop = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const getLinks = async () => {
      try {
        const response = await axios.get("/admin/shop", {
          params: {
            page,
          },
        });
        const { data } = response;
        const retrievedItems = data.data;
        const supplements = retrievedItems.filter(
          (item) => item.type === SUPPLEMENT_TYPE
        );
        const items = retrievedItems.filter(
          (item) => item.type === ACCESSORY_TYPE
        );
        const overviews = retrievedItems.filter(
          (item) => item.type === OVERVIEW_TYPE
        );
        dispatch(setSupplements(supplements));
        dispatch(setAccessory(items));
        dispatch(setOverviewImages(overviews));
      } catch (e) {
        console.log(e);
      }

      // setTotal(data.meta.total);
    };

    getLinks();
  }, [page]);

  return {
    total,
    page,
    setPage,
  };
};

export const createHandleAddShop = (id, supplement, dispatch, type) => {
  return async () => {
    const { title, link, image, order, message } = supplement;
    const data = new FormData();
    data.append("title", title);
    data.append("type", type);
    if (typeof image !== "string") {
      data.append("image", image);
    }
    if (!isEmpty(message)) {
      data.append("message", message);
    }
    data.append("link", link);

    // Create New Link if id is undefined
    if (isNaN(id)) {
      try {
        const response = await axios.post("/admin/shop", data, {
          headers: {
            Accept: "application/json",
          },
        });

        const createdItem = response.data.data;
        if (type === ACCESSORY_TYPE) {
          dispatch(addAccessory(createdItem));
        } else if (type === SUPPLEMENT_TYPE) {
          dispatch(addSupplement(createdItem));
        }
      } catch (e) {
        console.log("Failed to create shop item");
      }
    } else {
      data.append("order", order);
      try {
        const response = await axios.post(`/admin/shop/${id}`, data, {
          headers: {
            Accept: "application/json",
          },
        });

        const updatedItem = response.data.data;
        if (type === ACCESSORY_TYPE) {
          dispatch(updateAccessory(id, updatedItem));
        } else if (type === SUPPLEMENT_TYPE) {
          dispatch(updateSupplement(id, updatedItem));
        }
      } catch (e) {
        console.log("Failed to update youtube link");
      }
    }
  };
};

export const createHandleDeleteShop = (id, index, dispatch, remove) => {
  return async () => {
    // If it is a new field don't send request just remove for list
    if (isNaN(id)) {
      remove(index);
    } else {
      try {
        await axios.delete(`/admin/shop/${id}`);
        dispatch(deleteSupplement(id));
        dispatch(deleteAccessory(id));
      } catch (e) {
        console.log("Failed to delete youtube link");
      }
    }
  };
};

export const createHandleUpdateOverviewImage = (dispatch) => {
  return async (values, actions) => {
    console.log("Calling update overview image in shop");
    const data = new FormData();
    const { id, image, type, title, order } = values;
    data.append("image", image);
    data.append("title", title);
    data.append("type", type);
    data.append("link", "");
    data.append("order", order);
    try {
      const response = await axios.post(`/admin/shop/${id}`, data, {
        headers: {
          Accept: "application/json",
        },
      });

      const updatedSectionImage = response.data.data;
      dispatch(updateOverviewImage(id, updatedSectionImage));
    } catch (e) {
      console.log("Failed to update image overview");
    }
  };
};
