import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { DndProvider } from "react-dnd";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Backend from "react-dnd-html5-backend";
import { PersistGate } from "redux-persist/integration/react";
import axios from "axios";

import configureStore from "./modules/store";
import Routes from "./routes";
import theme from "./theme";

import "./App.css";
import { makeStyles } from "@material-ui/core";

// Axios config
if (process.env.NODE_ENV === "development") {
  // const corsHelper = "http://localhost:8080/";
  const corsHelper = "";
  // axios.defaults.baseURL = corsHelper + "http://localhost:8000/api";
  axios.defaults.baseURL = "https://api.slayapp.com/api";
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.post["Accept"] = "application/json";
  axios.defaults.maxRedirects = 0;
} else {
  // const marifitnessApi = "https://api.marifitness.com/";
  // axios.defaults.baseURL = "https://marifitness-api.herokuapp.com";
  axios.defaults.baseURL = "https://api.slayapp.com/api";
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.maxRedirects = 0;
}

const { store, persistor } = configureStore();

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
  },
});

function App() {
  const classes = useStyles();
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <DndProvider backend={Backend}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            {/*
              * The empty `onBeforeLift` is necessary magic, or else the PersistGate will not prevent loading of child components
              * before the redux-persist rehydrates from local storage.
              * jasonmoore 2021-12-10
              * hot tip from: https://github.com/rt2zz/redux-persist/issues/1003
            */}
            <PersistGate loading={null} persistor={persistor} onBeforeLift={() => ({})}>
              <div className={`${classes.root} App`}>
                <Router>
                  <Routes />
                </Router>
              </div>
            </PersistGate>
          </MuiPickersUtilsProvider>
        </DndProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
