import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

import {
  Dialog,
  DialogTitle,
  Typography,
  makeStyles,
  Grid,
  DialogContent,
  Box,
  FormControl,
  Input,
  InputLabel,
} from "@material-ui/core";
import {
  StyledButton,
  StyledButtonGradient,
  StyledButtonDisabled,
} from "../components/StyledButtons";

const useStyles = makeStyles({
  resetModal: {
    height: 150,
    width: 300,
  },
  buttonWidth: {
    minWidth: 120,
  },
  round: {
    borderRadius: 16,
  },
  paper: {
    margin: 32,
    position: "relative",
    overflowY: "auto",
    borderRadius: 16,
  },
});

export const DeleteWeekDialog = ({ open, setOpen, removeWeek, week }) => {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);

  const handleClick = async (e) => {
    try {
      await removeWeek();
      setSuccess(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = () => setOpen(false);
  const handleSuccessClose = () => {
    setOpen(false);
    setSuccess(false);
  };

  return (
    <Dialog
      transitionDuration={{ exit: 0 }}
      classes={{ paper: classes.paper }}
      className={classes.round}
      open={open}
      onClose={handleClose}
    >
      <DialogContent className={classes.round}>
        <Grid
          className={classes.resetModal}
          container
          alignItems="center"
          direction="column"
          justify="space-between"
        >
          {!success ? (
            <>
              <Grid>
                <Typography align="center" variant="body1">
                  Do you wish to delete the entire Week {week}?
                </Typography>
              </Grid>
              <Grid container justify="space-between">
                <StyledButtonGradient
                  className={classes.buttonWidth}
                  onClick={handleClick}
                >
                  Yes
                </StyledButtonGradient>
                <StyledButton
                  className={classes.buttonWidth}
                  onClick={handleClose}
                >
                  No
                </StyledButton>
              </Grid>
            </>
          ) : (
            <>
              <Grid>
                <Typography align="center" variant="body1">
                  Week {week} has been removed!
                </Typography>
              </Grid>
              <Grid container justify="center">
                <StyledButtonGradient
                  className={classes.buttonWidth}
                  onClick={handleSuccessClose}
                >
                  Okay
                </StyledButtonGradient>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export const ResetDayDialog = ({ open, setOpen, week, day, resetForm }) => {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const handleClick = async (e) => {
    await resetForm();
    setSuccess(true);
  };

  const handleClose = () => setOpen(false);
  const handleSuccessClose = () => {
    setOpen(false);
    setSuccess(false);
  };

  return (
    <Dialog
      transitionDuration={{ exit: 0 }}
      classes={{ paper: classes.paper }}
      className={classes.round}
      open={open}
      onClose={handleClose}
    >
      <DialogContent className={classes.round}>
        <Grid
          className={classes.resetModal}
          container
          alignItems="center"
          direction="column"
          justify="space-between"
        >
          {!success ? (
            <>
              <Grid>
                <Typography align="center" variant="body1">
                  Do you wish to reset date of Week {week}, Day {day}? All
                  contents will be removed
                </Typography>
              </Grid>
              <Grid container justify="space-between">
                <StyledButtonGradient
                  className={classes.buttonWidth}
                  onClick={handleClick}
                >
                  Yes
                </StyledButtonGradient>
                <StyledButton
                  className={classes.buttonWidth}
                  onClick={handleClose}
                >
                  No
                </StyledButton>
              </Grid>
            </>
          ) : (
            <>
              <Grid>
                <Typography align="center" variant="body1">
                  Gym Guide contents within Week {week}, Day {day} has been all
                  removed.
                </Typography>
              </Grid>
              <Grid container justify="center">
                <StyledButtonGradient
                  className={classes.buttonWidth}
                  onClick={handleSuccessClose}
                >
                  Okay
                </StyledButtonGradient>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export const DeleteExerciseDialog = ({
  open,
  setOpen,
  title,
  handleDeleteClick,
}) => {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const handleClick = async (e) => {
    let v = await new Promise((resolve) => {
      setTimeout(() => {
        handleDeleteClick();
        resolve(true);
      }, 2000);
      setSuccess(true);
    });
  };

  const handleClose = () => setOpen(false);
  const handleSuccessClose = () => {
    setOpen(false);
    setSuccess(false);
  };

  return (
    <Dialog
      transitionDuration={{ exit: 0 }}
      classes={{ paper: classes.paper }}
      className={classes.round}
      open={open}
      onClose={handleClose}
    >
      <DialogContent className={classes.round}>
        <Grid
          className={classes.resetModal}
          container
          alignItems="center"
          direction="column"
          justify="space-between"
        >
          {!success ? (
            <>
              <Grid>
                <Typography align="center" variant="body1">
                  Do you wish to delete exercise?
                </Typography>
              </Grid>
              <Grid container justify="space-between">
                <StyledButtonGradient
                  className={classes.buttonWidth}
                  onClick={handleClick}
                >
                  Yes
                </StyledButtonGradient>
                <StyledButton
                  className={classes.buttonWidth}
                  onClick={handleClose}
                >
                  No
                </StyledButton>
              </Grid>
            </>
          ) : (
            <>
              <Grid>
                <Typography align="center" variant="body1">
                  Exercise has been deleted from week.
                </Typography>
              </Grid>
              <Grid container justify="center">
                <StyledButtonGradient
                  className={classes.buttonWidth}
                  onClick={handleSuccessClose}
                >
                  Okay
                </StyledButtonGradient>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export const HomeGuideLevelCreateDialog = ({ open, setOpen }) => {
  const classes = useStyles();
  const history = useHistory();
  const handleSuccessClose = () => {
    history.goBack();
    setOpen(false);
  };

  return (
    <Dialog
      transitionDuration={{ exit: 0 }}
      classes={{ paper: classes.paper }}
      className={classes.round}
      open={open}
      onClose={handleSuccessClose}
    >
      <DialogContent className={classes.round}>
        <Grid
          className={classes.resetModal}
          container
          alignItems="center"
          direction="column"
          justify="space-between"
        >
          <Grid>
            <Typography align="center" variant="body1">
              New Guide Level has been created!
            </Typography>
          </Grid>
          <Grid container justify="center">
            <StyledButtonGradient
              className={classes.buttonWidth}
              onClick={handleSuccessClose}
            >
              Okay
            </StyledButtonGradient>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
