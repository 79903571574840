import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUsers, Filters, setUsers, selectPlans, setPlans } from "./index";
import { saveAs } from "file-saver";
import axios from "axios";
import { isEmpty } from "lodash";

export const useSiteUsers = () => {
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const [counts, setCounts] = useState([0, 0, 0]); // Hot, Warm, Cold
  const [filter, setFilter] = useState(Filters.NONE);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const getUsers = async () => {
      let params = {};

      if (!isEmpty(search)) {
        params = {
          search,
        };
      }

      if (!isEmpty(filter)) {
        params = {
          ...params,
          temperature: filter,
        };
      }

      try {
        const response = await axios.get("/admin/site-users", {
          params: {
            ...params,
            page,
          },
        });

        const userStats = await axios.get("/admin/stats/users");
        const { data } = response;
        const retrievedUsers = data.data;
        dispatch(setUsers(retrievedUsers));

        setTotal(data.meta.total);

        // Setup counts
        const totals = [
          userStats.data.hot,
          userStats.data.warm,
          userStats.data.cold,
        ];
        setCounts(totals);
      } catch (e) {
        console.log(e);
      }
    };

    getUsers();
  }, [page, filter, search]);

  return {
    counts,
    filter,
    setFilter,
    search,
    setSearch,
    users,
    total,
    page,
    setPage,
  };
};

export const usePlanInfo = () => {
  const dispatch = useDispatch();
  const plans = useSelector(selectPlans);
  useEffect(() => {
    const getPlans = async () => {
      try {
        const response = await axios.get("/admin/plans");
        const plans = response.data.data;
        dispatch(setPlans(plans));
      } catch (e) {
        console.log(e);
      }
    };

    getPlans();
  }, []);

  return {
    plans,
  };
};

export const useUserInfo = (user) => {
  //   const dispatch = useDispatch();
  const [details, setDetails] = useState({});
  useEffect(() => {
    const { id } = user;
    const getDetails = async () => {
      try {
        const response = await axios.get(`/admin/user/${id}`);
        const info = response.data.data;
        setDetails(info);
      } catch (e) {}
    };
    getDetails();
  }, [user]);

  return {
    details,
  };
};

// Function to download data to a file
function download(data, filename, type) {
  var file = new Blob([JSON.stringify(data, null, 2)], { type: type });
  if (window.navigator.msSaveOrOpenBlob)
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename);
  else {
    // Others
    var a = document.createElement("a"),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}

export const handleExportSubmit = async (values, _formik) => {
  const { startDate, endDate } = values;
  const start = startDate.format("YYYY-MM-DD");
  const end = endDate.format("YYYY-MM-DD");
  const { data } = await axios.get(`/admin/users/export`, {
    responseType: "blob",
    headers: {
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      "Access-Control-Allow-Origin": "*",
    },
    params: {
      start,
      end,
    },
  });
  console.log("Responsde user data");
  console.log(data);
  // const file = new Blob(data, { type: "text/csv" });
  await saveAs(data, "users.csv");
  // download(data, "users.csv", "text/csv");
};

// export const createAdminPasswordReset = (setSuccess, id) => {
//   return async (e) => {
//     try {
//       await axios.post(`/admin/user/${id}/reset-password`, null, {
//         headers: {
//           Accept: "application/json",
//         },
//       });
//       setSuccess(true);
//     } catch (e) {
//       alert("Failed to send password reset");
//     }
//   };
// };

// export const createDeleteUserClick = (
//   currentUser,
//   setSuccess,
//   dispatch,
//   admins
// ) => {
//   return async (e) => {
//     try {
//       const { id } = currentUser;
//       const res = await axios.delete(`/admin/user/${id}`, {
//         headers: {
//           Accept: "application/json",
//         },
//       });
//       // If admin in current list of admins update user data
//       const newUsers = admins.filter((admin) => {
//         return admin.id !== id;
//       });
//       dispatch(setAdminUsers(newUsers));
//     } catch (e) {
//       console.log(e);
//     }
//     setSuccess(true);
//   };
// };

// const getOppositeRole = (id) => {
//   if (id === 2) {
//     // Set moderator to admin
//     return 3;
//   } else {
//     return 2; // Opposite of above
//   }
// };

// export const createAdminRoleUpdate = (
//   currentUser,
//   setSuccess,
//   dispatch,
//   admins
// ) => {
//   return async (e) => {
//     try {
//       const { id, role_id, first_name, last_name, email } = currentUser;
//       const newRole = getOppositeRole(role_id);
//       const data = {
//         first_name,
//         last_name,
//         email,
//         role_id: newRole,
//       };
//       const res = await axios.put(`/admin/user/${id}`, data, {
//         headers: {
//           Accept: "application/json",
//         },
//       });
//       const updatedUser = res.data.data;
//       // If admin in current list of admins update user data
//       const newUsers = admins.map((admin) => {
//         return admin.id === id ? updatedUser : admin;
//       });
//       dispatch(setAdminUsers(newUsers));
//     } catch (e) {
//       console.log(e);
//     }
//     setSuccess(true);
//   };
// };

// export const createAdminEmailUpdate = (
//   email,
//   currentUser,
//   setSuccess,
//   dispatch,
//   admins
// ) => {
//   return async (e) => {
//     try {
//       const { id, role_id, first_name, last_name } = currentUser;
//       const newRole = getOppositeRole(role_id);
//       const data = {
//         first_name,
//         last_name,
//         email,
//         role_id,
//       };
//       const res = await axios.put(`/admin/user/${id}`, data, {
//         headers: {
//           Accept: "application/json",
//         },
//       });
//       const updatedUser = res.data.data;
//       // If admin in current list of admins update user data
//       const newUsers = admins.map((admin) => {
//         return admin.id === id ? updatedUser : admin;
//       });
//       dispatch(setAdminUsers(newUsers));
//     } catch (e) {
//       console.log(e);
//     }
//     setSuccess(true);
//   };
// };
