import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuIcon from "@material-ui/icons/Menu";
import axios from "axios";
import { FastField, Field, Formik, FieldArray } from "formik";
import { TextField, InputBase } from "formik-material-ui";
import { KeyboardDatePicker } from "formik-material-ui-pickers";

import { NavigationLink } from "../components/TabNavigationLink";
import DraggableGrid from "../components/DraggableGrid";
import { SimpleFileUpload } from "../components/FormikFile";
import { StyledButtonGradient } from "../components/StyledButtons";
import { DeleteDialog } from "./dialogs";
import {
  useYoutubeLinks,
  createHandleDeleteYoutube,
} from "../../modules/learning/effects";
import { useSelector, useDispatch } from "react-redux";
import { selectYoutubeLinks } from "../../modules/learning";
import { createHandleAddYoutube } from "../../modules/learning/effects";

const useStyles = makeStyles((theme) => ({
  titleMargin: {
    marginTop: 20,
  },
}));

export const createEmptyLink = () => {
  return { link: "", title: "" };
};
export default ({ parentUrl }) => {
  const classes = useStyles();
  const url = parentUrl;
  const links = useSelector(selectYoutubeLinks);
  const { total, page, setPage } = useYoutubeLinks();

  // TODO: Get links already existing
  return (
    <Formik
      key={JSON.stringify(links)}
      initialValues={{
        links,
      }}
    >
      {(formikProps) => {
        return (
          <FieldArray name="links">
            {(arrayHelpers) => {
              return (
                <>
                  <Grid
                    className={classes.titleMargin}
                    direction="row"
                    container
                    wrap="nowrap"
                    justify="space-between"
                  >
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <NavigationLink
                        to={`${url}/overview`}
                        title={"Learn Overview"}
                      />
                      <Box ml={2}>
                        <NavigationLink
                          to={`${url}/links`}
                          title={"YouTube Links"}
                        />
                      </Box>
                      <Box ml={2}>
                        <NavigationLink
                          to={`${url}/dictionary`}
                          title={"Dictionary"}
                        />
                      </Box>
                      <Box ml={2}>
                        <NavigationLink
                          to={`${url}/glossary`}
                          title={"Exercise Glossary"}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <StyledButtonGradient
                        onClick={() => arrayHelpers.push(createEmptyLink())}
                      >
                        Add Videos
                      </StyledButtonGradient>
                    </Box>
                  </Grid>
                  <Grid container direction="column">
                    {formikProps.values.links.map((youtubeLink, index) => {
                      const linkField = `links[${index}].link`;
                      const titleField = `links[${index}].title`;
                      const linkMeta = formikProps.getFieldMeta(linkField);
                      const titleMeta = formikProps.getFieldMeta(titleField);
                      const active =
                        linkMeta.initialValue !== linkMeta.value ||
                        titleMeta.initialValue !== titleMeta.value;
                      return (
                        <LinkForm
                          youtubeLink={youtubeLink}
                          index={index} // Need for drag
                          moveItem={async (dragIndex, hoverIndex) => ({})}
                          remove={arrayHelpers.remove}
                          buttonDisabled={!active}
                          key={index}
                        />
                      );
                    })}
                  </Grid>
                </>
              );
            }}
          </FieldArray>
        );
      }}
    </Formik>
  );
};

const useLinkFormStyles = makeStyles({
  marginTop: {
    marginTop: 40,
  },
  buttonWidth: {
    minWidth: 130,
  },
  section: {
    padding: 16,
  },
  sectionBase: {
    backgroundColor: "#F8F8F8",
    borderRadius: 12,
  },
  titleFormat: {
    fontSize: "1.2rem",
    fontWeight: 800,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
  },
  removeVertical: {
    marginTop: 0,
  },
  large: {
    flex: 1,
    display: "flex",
  },
});

/**
 * A thing to note here is that the `id` prop for the draggable
 * grid is also primary key for the object returned for the server
 * If the `id` prop is `undefined` then it is a new link being made
 * @param {*} param0
 */
const LinkForm = ({ youtubeLink, remove, index, moveItem, buttonDisabled }) => {
  const classes = useLinkFormStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const nameHelper = (name) => {
    return `links[${index}].${name}`;
  };

  const { id, link, title } = youtubeLink;

  const handleDeleteClick = () => {
    setOpen(true); // Open delete dialog
  };

  const handleDeleteConfirm = createHandleDeleteYoutube(
    id,
    index,
    dispatch,
    remove
  );
  const handleSaveClick = createHandleAddYoutube(id, youtubeLink, dispatch);

  return (
    <DraggableGrid
      id={id || JSON.stringify(Math.random())}
      index={index}
      moveItem={moveItem}
      container
      direction="column"
      className={classes.marginTop}
      TitleComponent={
        <FastField
          className={classes.large}
          component={InputBase}
          inputProps={{
            // disableUnderline: true,
            className: classes.titleFormat,
            placeholder: "Untitled Video",
          }}
          // inputLabelProps={{ className: classes.removeVertical }}
          name={`${nameHelper("title")}`}
        />
      }
      deleteIcon
      handleDeleteClick={handleDeleteClick}
    >
      <Grid className={classes.sectionBase}>
        {/* Link row */}
        <Grid
          container
          direction="row"
          justify="space-between"
          className={classes.section}
        >
          <Box display="flex" flex={4}>
            <FastField
              name={`${nameHelper("link")}`}
              component={TextField}
              InputProps={{
                disableUnderline: true,
                placeholder: "Enter YouTube Link",
                // onMouseDown: (e) => e.target.focus(),
              }}
              InputLabelProps={
                {
                  // focused: true,
                }
              }
              label="Link"
              fullWidth
            />
          </Box>
          <Box justifyContent="flex-end" display="flex" flex={1} ml={1}>
            <StyledButtonGradient
              disabled={buttonDisabled}
              onClick={handleSaveClick}
              className={classes.buttonWidth}
            >
              Save
            </StyledButtonGradient>
          </Box>
        </Grid>
      </Grid>
      <DeleteDialog
        handleDeleteClick={handleDeleteConfirm}
        promptText={`Do you wish to delete the video "${title}"?`}
        promptSuccess={`Video "${title}" has been deleted`}
        open={open}
        setOpen={setOpen}
      />
    </DraggableGrid>
  );
};
