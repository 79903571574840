import React, { useState } from "react";
import {
  MenuItem,
  Grid,
  makeStyles,
  Typography,
  FormControl,
  Select,
} from "@material-ui/core";
import { FilterTypes } from "./constants";
import NakedSelect from "../components/NakedSelect";
import CardLogged from "./CardLogged";
import { useTracks } from "../../modules/dashboard/effects";

const cardData = {
  count: 1,
  exercise: "Squat",
  sets: 3,
  reps: 5,
  updatedAt: new Date(),
};

const useStyles = makeStyles((theme) => ({
  dropdownMenu: {
    height: 48,
    marginBottom: 40,
    marginTop: 40,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
  select: {
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: "25ch",
    borderBottomWidth: 0,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "flex-start",
  },
  selectInput: {
    background: 0,
    padding: "6px 0 7px",
    borderRadius: 0,
    margin: 0,
    minHeight: 0,
  },
}));
export default () => {
  const classes = useStyles();
  const [filter, setFilter] = useState(FilterTypes.YEAR);
  const { records } = useTracks(filter);

  return (
    <>
      <Grid>
        <FormControl className={classes.dropdownMenu}>
          <Select
            className={classes.select}
            id="select-user-filter"
            value={filter}
            onChange={(e) => {
              setFilter(e.target.value);
            }}
            input={<NakedSelect />}
            inputProps={{ className: classes.selectInput }}
          >
            <MenuItem value={FilterTypes.YEAR}>
              This {FilterTypes.YEAR}
            </MenuItem>
            <MenuItem value={FilterTypes.MONTH}>
              This {FilterTypes.MONTH}
            </MenuItem>
            <MenuItem value={FilterTypes.WEEK}>
              This {FilterTypes.WEEK}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid container alignItems="flex-start" direction="column">
        <Typography>Top 4 Most Logged Workouts</Typography>
        <Grid container direction="row">
          {records.slice(0, 4).map((record, i) => (
            <CardLogged key={i} record={record} topExercise />
          ))}
        </Grid>
        <Typography>Other Logged Workouts</Typography>
        <Grid container direction="row">
          {records.slice(4).map((record, i) => (
            <CardLogged key={i + 20} record={record} />
          ))}
        </Grid>
      </Grid>
    </>
  );
};
