import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Typography,
  Grid,
  Box,
  FormControl,
  Paper,
  InputLabel,
  Input,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";

import {
  StyledButton,
  StyledButtonDisabled,
  StyledButtonGradient,
} from "../components/StyledButtons";
import { selectUser, setUser } from "../../modules/authentication";
import { useDispatch, useSelector } from "react-redux";
import { updateEmail } from "../../modules/authentication";

const useStyles = makeStyles({
  buttonWidth: {
    minWidth: 120,
  },
  colorText: {
    color: "#03B7B9",
  },
  minWidthField: {
    minWidth: 300,
  },
  fakeLabel: {
    fontWeight: 700,
    fontSize: "1rem",
  },
  errorBackground: {
    backgroundColor: "#FFD8D8",
  },
});

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [email, setEmail] = useState(user.email);
  const [success, setSuccess] = useState(false);

  const handleUpdateEmail = async () => {
    const data = {
      ...user,
      email,
    };
    try {
      let res = await axios.put(`/admin/user/${user.id}`, data);
      // Display Success for 2 seconds
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
      console.log(res.data.data);
      dispatch(setUser(res.data.data));
    } catch (e) {
      // e = error 400, admin already has email
    }
  };

  return (
    <>
      <Grid>
        <Box
          flexDirection="column"
          mt={3}
          mb={3}
          display="flex"
          alignItems="flex-start"
        >
          <Typography className={classes.fakeLabel}>Email Address</Typography>
          <Paper
          // className={clsx(errors.email && classes.errorBackground)}
          // elevation={errors.email ? 0 : 1}
          >
            <Input
              id="admin-email-input"
              labelId="admin-email-label"
              className={classes.minWidthField}
              value={email}
              onChange={(e) => {
                const v = e.target.value;
                setEmail(v);
                setSuccess(false);
              }}
            ></Input>
          </Paper>
        </Box>
      </Grid>
      <Grid container justify="flex-start">
        <StyledButtonGradient
          disabled={user.email === email}
          className={classes.buttonWidth}
          onClick={handleUpdateEmail}
        >
          Update
        </StyledButtonGradient>
      </Grid>
      <Grid>
        {success && (
          <Box mt={2}>
            <Typography className={classes.colorText} variant="body1">
              New Email Address Updated Successfully
            </Typography>
          </Box>
        )}
      </Grid>
    </>
  );
};
