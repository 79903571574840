import React, { useState, useEffect } from "react";
import moment from "moment";
import { Bar } from "@vx/shape";
import { Group } from "@vx/group";
import { genDateValue } from "@vx/mock-data";
import { scaleBand, scaleLinear } from "@vx/scale";
import { FilterTypes } from "./constants";
import { withTooltip, TooltipWithBounds } from "@vx/tooltip";
import { localPoint } from "@vx/event";
import { AxisBottom } from "@vx/axis";
import { keys, values } from "lodash";

// responsive utils for axis ticks
function numTicksForType(type) {
  if (type === FilterTypes.WEEK) {
    return 2;
  }
  if (type === FilterTypes.MONTH) {
    return 3;
  }
  if (type === FilterTypes.YEAR) {
    return 5;
  }
}

function getDataLength(type) {
  if (type === FilterTypes.WEEK) {
    return 7;
  }
  if (type === FilterTypes.MONTH) {
    return 30;
  }
  if (type === FilterTypes.YEAR) {
    return 56;
  }
  return 56;
}

function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

// accessors
const x = (d, i) => {
  // console.log(d);
  // console.log(i);
  return i;
};
const y = (d) => d.value;

const BarGraph = ({
  width,
  height,
  margin,
  type,
  title,
  data,
  showTooltip,
  hideTooltip,
  tooltipLeft,
  tooltipTop,
  tooltipData,
  tooltipOpen,
}) => {
  const length = getDataLength(type);
  // const [data, setData] = useState(genDateValue(length));
  // bounds
  // const xMax = width;
  // const yMax = height - 120;
  // bounds
  useEffect(() => {
    const length = getDataLength(type);
    // setData(genDateValue(length));
  }, [type]);

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  let tooltipTimeout;

  // scales
  const xScale = scaleBand({
    rangeRound: [0, xMax],
    domain: keys(data).reverse(),
    // nice: true,
  });

  const yScale = scaleLinear({
    rangeRound: [yMax, 0],
    domain: [0, Math.max(...values(data))],
  });

  const [active, setActive] = useState(-1);

  const handleMouseOver = (event, datum, index) => {
    setActive(index);
    const coords = localPoint(event.target.ownerSVGElement, event);
    showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: datum,
    });
  };

  const handleMouseOut = () => {
    setActive(-1);
    hideTooltip();
  };

  return (
    <>
      <svg width={width} height={height}>
        <rect width={width} height={height} fill={"#2D2D2D"} rx={14} />
        <Group top={margin.top} left={margin.left}>
          {keys(data).map((key, i) => {
            const d = data[key];
            const barWidth = xMax / keys(data).length - 2;
            const barHeight = yMax - yScale(d);
            const barX = xScale(key);
            const barY = yMax - barHeight;
            let offset = 4;
            return (
              <Bar
                key={`bar-${i}`}
                x={barX}
                y={barY}
                rx={4}
                width={barWidth}
                height={barHeight}
                fill={active === i ? "#F54E55" : "#979797"}
                onClick={(event) => {
                  setActive(i);
                }}
                onMouseOver={(e) =>
                  handleMouseOver(e, { value: d, date: key }, i)
                }
                onMouseOut={handleMouseOut}
              />
            );
          })}
        </Group>
        <AxisBottom
          top={yMax + margin.bottom}
          left={margin.left}
          tickStroke="#f0f0f0"
          hideTicks={true}
          tickLabelProps={(val, index) => ({
            textAnchor: "middle",
            fontSize: 12,
            fill: "#f0f0f0",
          })}
          hideAxisLine={true}
          label={title}
          labelProps={{
            textAnchor: "middle",
            fontSize: 14,
            fill: "#f0f0f0",
          }}
          scale={xScale}
        />
      </svg>
      {tooltipOpen && (
        <TooltipWithBounds
          // set this to random so it correctly updates with parent bounds
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
        >
          <div>
            <p>Date: {tooltipData.date}</p>
            <p>Value: {tooltipData.value}</p>
          </div>
        </TooltipWithBounds>
      )}
    </>
  );
};

export default withTooltip(BarGraph);
