import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import axios from "axios";
import { Paper, Grid, Box, Typography, makeStyles } from "@material-ui/core";
import { Form, Field, Formik } from "formik";
import { TextField } from "formik-material-ui";

import {
  StyledButton,
  StyledButtonDisabled,
  StyledButtonGradient,
} from "../components/StyledButtons";
import { selectUser, setUser } from "../../modules/authentication";

const useStyles = makeStyles({
  buttonWidth: {
    minWidth: 120,
  },
  colorText: {
    color: "#03B7B9",
  },
  minWidthField: {
    minWidth: 300,
  },
  fakeLabel: {
    fontWeight: 700,
    fontSize: "1rem",
  },
  errorBackground: {
    backgroundColor: "#FFD8D8",
  },
});

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [success, setSuccess] = useState();

  const handleUpdatePassword = async (values, { setSubmitting }) => {
    const data = {
      ...user,
      password: values.newPassword,
      password_confirmation: values.confirmPassword,
    };
    try {
      let res = await axios.put(`/admin/user/${user.id}`, data);
      // Display Success for 2 seconds
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setSubmitting(false);
      }, 2000);
      dispatch(setUser(res.data.data));
    } catch (e) {
      // e = error 400, admin already has email
    }
  };

  return (
    <Grid
      direction="column"
      alignItems="flex-start"
      className={classes.titleMargin}
      container
    >
      <Formik
        initialValues={{
          newPassword: "",
          confirmPassword: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.newPassword) {
            errors.newPassword = "Required";
          }
          if (!values.confirmPassword) {
            errors.confirmPassword = "Required";
          }
          return errors;
        }}
        onSubmit={handleUpdatePassword}
      >
        {({ submitForm, isSubmitting, errors, touched }) => (
          <Form>
            <Grid container direction="column">
              <Box
                flexDirection="column"
                mt={3}
                mb={3}
                display="flex"
                alignItems="flex-start"
              >
                <Typography className={classes.fakeLabel}>
                  New Password
                </Typography>
                <Paper
                  className={clsx(
                    errors.newPassword &&
                      touched.newPassword &&
                      classes.errorBackground
                  )}
                  elevation={errors.newPassword && touched.newPassword ? 0 : 1}
                >
                  <Field
                    className={classes.minWidthField}
                    component={TextField}
                    name="newPassword"
                    type="password"
                  />
                </Paper>
              </Box>
              <Box
                flexDirection="column"
                mt={3}
                mb={3}
                display="flex"
                alignItems="flex-start"
              >
                <Typography className={classes.fakeLabel}>
                  Confirm Password
                </Typography>
                <Paper
                  className={clsx(
                    errors.confirmPassword &&
                      touched.confirmPassword &&
                      classes.errorBackground
                  )}
                  elevation={
                    errors.confirmPassword && touched.confirmPassword ? 0 : 1
                  }
                >
                  <Field
                    className={classes.minWidthField}
                    component={TextField}
                    name="confirmPassword"
                    type="password"
                  />
                </Paper>
              </Box>
              <Box display="flex" align="stretch" mt={2}>
                <StyledButtonGradient
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Submit
                </StyledButtonGradient>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>
      <Grid>
        {success && (
          <Box mt={2}>
            <Typography className={classes.colorText} variant="body1">
              New Password Updated Successfully
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
