import { useState, useEffect } from "react";
import axios from "axios";
import {addLevel, addOverviewImage} from "../../modules/guides/index"

export const createHandleAddGuideLevel = (setOpen, dispatch) => {
  return async (values, { setSubmitting, setErrors }) => {
    try {
      const response = await axios.post("/admin/guide/settings", values, {
        headers: {
          Accept: "application/json",
        },
      });

      const createdLevel = response.data.data;
      dispatch(addLevel(createdLevel.level));
      dispatch(addOverviewImage(createdLevel));
      setOpen(true);
    } catch (e) {
      const level = e.response.data.errors.level;
      const message = e.response.data.errors.message;
      setSubmitting(false);
      setErrors({ level, message });
    }
    setSubmitting(false);
  };
};
