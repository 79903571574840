import { put, all, call, delay, takeLatest } from "redux-saga/effects";
import moment from "moment";
import { createSelector } from "reselect";
import axios from "axios";
/**
 * Actions
 */
export const Filters = {
  USERS_HOT: "Hot",
  USERS_COLD: "Cold",
  USERS_WARM: "Warm",
  NONE: "",
};

export const GET_USERS = "USERS/GET";
export const getUsers = () => ({
  type: GET_USERS,
});

export const SET_FILTER = "USERS/SET_FILTER";
export const setFilter = (filter) => ({
  type: SET_FILTER,
  filter,
});

export const SET_ACTIVE_USER = "USERS/SET_ACTIVE_USER";
export const setActiveUser = (user) => ({
  type: SET_ACTIVE_USER,
  user,
});

export const SET_USER_DETAILS = "USERS/SET_DETAILS_USER";
export const setUserDetails = (details) => ({
  type: SET_USER_DETAILS,
  details,
});

export const SET_SEARCH_INPUT = "USERS/SET_SEARCH_INPUT";
export const setSearchInput = (input) => ({
  type: SET_SEARCH_INPUT,
  input,
});

export const SET_USERS = "USERS/SET_USERS";
export const setUsers = (users) => ({
  type: SET_USERS,
  users,
});

export const SET_PLANS = "USERS/SET_PLANS";
export const setPlans = (plans) => ({
  type: SET_PLANS,
  plans,
});

/**
 * Reducer
 */
const initState = {
  filter: Filters.NONE,
  selectedUser: {},
  selectedUserDetails: {},
  users: [],
  plans: [],
  searchInput: "",
};

export const usersReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_FILTER:
      return { ...state, filter: action.filter };
    case SET_ACTIVE_USER: {
      return { ...state, selectedUser: action.user };
    }
    case SET_USER_DETAILS: {
      return { ...state, selectedUserDetails: action.details };
    }
    case SET_SEARCH_INPUT: {
      return { ...state, searchInput: action.input };
    }
    case SET_USERS: {
      return { ...state, users: action.users };
    }
    case SET_PLANS: {
      return { ...state, plans: action.plans };
    }
    default:
      return state;
  }
};

/**
 * Sagas
 */
function* handleSetActiveUser() {
  yield delay(100);
  const userData = {
    subscriptionInfo: {
      frequency: "monthly",
      cost: 10.0,
      freeTrial: true,
      freeTrialEnd: "9/30",
    },
    joinInfo: {
      since: "06/19/2020",
    },
    paymentInfo: [
      {
        date: moment().calendar(),
        amount: 100.0,
        status: "Complete",
      },
      {
        date: moment().calendar(),
        amount: 100.0,
        status: "Failed",
      },
    ],
  };
  yield put(setUserDetails(userData));
}

function* watchSelectUser() {
  yield takeLatest([SET_ACTIVE_USER], handleSetActiveUser);
}

function formatUserData(users) {
  return users.map((u) => ({
    plan: u.plan,
    type: u.user_type,
    email: u.email,
    name: u.username,
    lastLogin: u.last_login_datetime
      ? moment(u.last_login_datetime.date).format("L")
      : "N/A",
    joinDate: u.join_date ? moment(u.join_date).format("L") : "N/A",
    id: u.id || 1,
  }));
}

function* handleGetUsers() {
  try {
    let params = {};
    const res = yield call(axios.get, "/admin/site-users", {
      params,
    });
    // const data = formatUserData(res.data);
    // yield put(setUsers(data));
  } catch (e) {
    console.log(e);
  }
}

function* watchGetUsers() {
  yield takeLatest([GET_USERS], handleGetUsers);
}

export function* rootSaga() {
  yield all([call(watchSelectUser), call(watchGetUsers)]);
}

/**
 *  Selectors
 */

export const selectUsers = (state) => state.users.users || [];
export const selectPlans = (state) => state.users.plans || [];
export const selectActiveUser = (state) => state.users.selectedUser;
