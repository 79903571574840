import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuIcon from "@material-ui/icons/Menu";
import moment from "moment";
import { FastField, Field, Formik, FieldArray } from "formik";
import { TextField } from "formik-material-ui";
import { KeyboardDateTimePicker } from "formik-material-ui-pickers";
import axios from "axios";

import { getActiveState } from "../helpers";
import { NavigationLink } from "../components/TabNavigationLink";
import { SimpleFileUpload } from "../components/FormikFile";
import { StyledButtonGradient } from "../components/StyledButtons";
import { useSelector, useDispatch } from "react-redux";
import { selectUserNotifications } from "../../modules/notifications";
import {
  createHandleAddPush,
  createHandleDeletePush,
} from "../../modules/notifications/effects";

const useStyles = makeStyles((theme) => ({
  titleMargin: {
    marginTop: 20,
  },
}));

const createEmptyPushField = () => {
  return {
    send_date: moment(),
    image: null,
    title: "",
    message: "",
  };
};

export default ({ parentUrl }) => {
  const classes = useStyles();
  const url = parentUrl;
  const notifications = useSelector(selectUserNotifications);

  return (
    <Formik
      key={JSON.stringify(notifications)}
      initialValues={{
        notifications,
      }}
    >
      {(formikProps) => {
        return (
          <FieldArray name="notifications">
            {(arrayHelpers) => {
              return (
                <>
                  <Grid
                    className={classes.titleMargin}
                    direction="row"
                    container
                    wrap="nowrap"
                    justify="space-between"
                  >
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <NavigationLink
                        to={`${url}/automated`}
                        title={"Automated"}
                      />
                      <Box ml={2}>
                        <NavigationLink
                          to={`${url}/promotions`}
                          title={"Promotions"}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <StyledButtonGradient
                        onClick={() =>
                          arrayHelpers.push(createEmptyPushField())
                        }
                      >
                        Add Promotion Message
                      </StyledButtonGradient>
                    </Box>
                  </Grid>
                  <Grid container direction="column">
                    {formikProps.values.notifications.map((n, index) => {
                      const active = getActiveState(
                        formikProps,
                        `notifications`,
                        index,
                        ["send_date", "title", "message"],
                        ["image"]
                      );
                      return (
                        <PushNotificationForm
                          buttonDisabled={!active}
                          index={index}
                          remove={arrayHelpers.remove}
                          key={index}
                          notification={n}
                        />
                      );
                    })}
                  </Grid>
                </>
              );
            }}
          </FieldArray>
        );
      }}
    </Formik>
  );
};

const usePopupFormStyles = makeStyles({
  section: {
    padding: 16,
  },
  marginTop: {
    marginTop: 20,
  },
  sectionBase: {
    backgroundColor: "#F8F8F8",
    borderRadius: 12,
  },
  buttonMinWidth: {
    minWidth: 130,
  },
});

const PushNotificationForm = ({
  notification,
  remove,
  index,
  buttonDisabled,
}) => {
  const dispatch = useDispatch();
  const classes = usePopupFormStyles();
  const nameHelper = (name) => {
    return `notifications[${index}].${name}`;
  };

  const { id } = notification;
  const handleDeleteClick = createHandleDeletePush(id, index, dispatch, remove);

  const handleSaveClick = createHandleAddPush(id, notification, dispatch);

  return (
    <Grid container direction="column" className={classes.marginTop}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
      >
        <Box alignItems="center" display="flex" flexDirection="row">
          <IconButton>
            <MenuIcon />
          </IconButton>
          <FastField
            name={`${nameHelper("title")}`}
            component={TextField}
            InputProps={{
              disableUnderline: true,
              placeholder: "Promotion Title",
              // onMouseDown: (e) => e.target.focus(),
            }}
            InputLabelProps={
              {
                // focused: true,
              }
            }
            fullWidth
          />
        </Box>
        <IconButton onClick={handleDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </Grid>
      <Grid className={classes.sectionBase}>
        {/* date row */}
        <Grid
          container
          direction="row"
          justify="space-between"
          className={classes.section}
        >
          <Box flex={2} display="flex" flexDirection="row" alignItems="center">
            <Box>
              <Field
                name={`${nameHelper("send_date")}`}
                label="Date"
                disablePast
                component={KeyboardDateTimePicker}
                labelFunc={(date, invalidString) => {
                  return moment(date).format("YYYY-MM-DD HH:mm:ss");
                }}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>
          </Box>
          <Box flex={2} justifyContent="flex-end" display="flex">
            <StyledButtonGradient
              onClick={handleSaveClick}
              disabled={buttonDisabled}
              className={classes.buttonMinWidth}
            >
              Save
            </StyledButtonGradient>
          </Box>
        </Grid>
        {/* image row */}
        <Grid
          container
          direction="row"
          justify="space-between"
          className={classes.section}
        >
          <Box display="flex" ml={1} flex={2}>
            <Field
              label="Image"
              name={`${nameHelper("image")}`}
              component={SimpleFileUpload}
            />
          </Box>
        </Grid>
        {/* message row */}
        <Grid container className={classes.section}>
          <FastField
            name={`${nameHelper("message")}`}
            component={TextField}
            InputProps={{ disableUnderline: true }}
            label="Message"
            fullWidth
            multiline
            rows="3"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
