import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  makeStyles,
  FormControl,
  MenuItem,
  Select,
} from "@material-ui/core";
import axios from "axios";

import { StyledButtonDisabled } from "../components/StyledButtons";
import { NoFormFileDisplay } from "../components/FormikFile";
import NakedSelect from "../components/NakedSelect";
import { selectUser } from "../../modules/authentication";
import { ImageTypes } from "./constants";
import { useSelector } from "react-redux";
import { selectUniqueImages } from "../../modules/setting-images";

const useStyles = makeStyles({
  buttonWidth: {
    minWidth: 120,
    height: 40,
  },
  colorText: {
    color: "#03B7B9",
  },
  titleMargin: {
    marginTop: 20,
  },
  dropdownMenu: {
    height: 48,
    marginBottom: 40,
    marginTop: 40,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
  select: {
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: "25ch",
    borderBottomWidth: 0,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "flex-start",
  },
  marginTop: {
    marginTop: 40,
  },
  section: {
    padding: 16,
    paddingTop: 0,
  },
  sectionBase: {
    backgroundColor: "#F8F8F8",
    borderRadius: 12,
  },
  selectInput: {
    background: 0,
    padding: "6px 0 7px",
    borderRadius: 0,
    margin: 0,
    minHeight: 0,
  },
});

export const UniqueSection = ({ handleSetTab, imageType }) => {
  const classes = useStyles();
  const images = useSelector(selectUniqueImages);

  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        <FormControl className={classes.dropdownMenu}>
          <Select
            displayEmpty
            className={classes.select}
            id="select-settings-image-filter"
            value={imageType}
            onChange={handleSetTab}
            input={<NakedSelect />}
            inputProps={{ className: classes.selectInput }}
          >
            <MenuItem value={ImageTypes.UNIQUE}>{ImageTypes.UNIQUE}</MenuItem>
            <MenuItem value={ImageTypes.TUTORIAL}>
              {ImageTypes.TUTORIAL}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid container direction="column" alignItems="flex-start">
        {/* Section */}
        {images.map((item) => (
          <Grid key={item.id} container style={{ marginTop: 10 }}>
            <Typography variant="body1">{item.title}</Typography>
            <Grid container direction="column" className={classes.sectionBase}>
              <Grid container direction="row" className={classes.section}>
                <Box display="flex" flex={4}>
                  <NoFormFileDisplay name={item.image} />
                </Box>
                <Box justifyContent="flex-end" display="flex" flex={1} ml={1}>
                  <StyledButtonDisabled>Save</StyledButtonDisabled>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
