import isEmpty from "lodash/isEmpty";
import { put, all, call, takeLatest } from "redux-saga/effects";
import axios from "axios";

export const LOGOUT_USER = "USER/LOGOUT";
export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const SET_USER = "AUTH/SET_USER";
export const setUser = (user) => ({
  type: SET_USER,
  user,
});

export const SET_TOKEN = "AUTH/SET_TOKEN";
export const setToken = (token) => ({
  type: SET_TOKEN,
  token,
});

export const UPDATE_USER_EMAIL = "AUTH/UPDATE_EMAIL";
export const updateEmail = (email) => ({
  type: UPDATE_USER_EMAIL,
  email,
});

export const GET_CURRENT_USER = "AUTH/CURRENT_USER";
export const getCurrentUser = () => ({
  type: GET_CURRENT_USER,
});

const initState = {
  currentUser: {},
  isAdmin: false,
  isModerator: false,
  token: null,
};

const ROLE_ID_ADMIN = 3;
const ROLE_ID_MODERATOR = 2;

export const authReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_USER:
      const isAdmin =
        action.user.role_id === ROLE_ID_ADMIN ||
        action.user.role_id === ROLE_ID_MODERATOR;
      const isModerator = action.user.role_id === ROLE_ID_MODERATOR;
      return { ...state, currentUser: action.user, isAdmin, isModerator };
    case UPDATE_USER_EMAIL:
      return { ...state, currentUser: { ...state.user, email: action.email } };
    case SET_TOKEN: {
      return { ...state, token: action.token };
    }
    case LOGOUT_USER:
      return initState;
    default:
      return state;
  }
};

function* handleGetUser() {
  try {
    const res = yield call(axios.get, "/admin/user");
    const userData = res.data.data;
    yield put(setUser(userData));
  } catch (e) {
    console.log(e);
  }
}

function* watchGetUser() {
  yield takeLatest([GET_CURRENT_USER], handleGetUser);
}

export function* rootSaga() {
  yield all([call(watchGetUser)]);
}

export const selectUser = (state) => state.authentication.currentUser;
export const getToken = (state) => state.authentication.token;
export const getLoggedInStatus = (state) =>
  !isEmpty(state.authentication.currentUser);
export const getAdminStatus = (state) => state.authentication.isAdmin;
export const getModeratorStatus = (state) => state.authentication.isModerator;
