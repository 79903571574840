import React from "react";
import clsx from "clsx";
import { Typography, Card, CardContent, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  rounded: {
    borderRadius: 24,
    maxWidth: 200,
    minWidth: 177,
    maxHeight: 200,
    minHeight: 152,
    margin: 10,
    marginLeft: 0,
    backgroundColor: "#FAF6F6",
  },
  center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  topExercise: {
    color: "#F54E55",
  },
}));

export default ({ record, topExercise }) => {
  const { workout_count, title, set_count, reps_count } = record;
  const classes = useStyles();
  return (
    <Card elevation={0} className={classes.rounded}>
      <CardContent className={classes.center}>
        <Typography
          className={clsx(topExercise && classes.topExercise)}
          variant="h4"
        >
          {workout_count}
        </Typography>
        <Typography variant="subtitle1">{title}</Typography>
        <Typography color="textSecondary" variant="body2">
          {set_count} Sets, {reps_count} Reps
        </Typography>
      </CardContent>
    </Card>
  );
};
