import React, { useState } from "react";
import {  useRouteMatch, Route, Switch } from "react-router-dom";

import AdminPanel from "./adminPanel";
import AdminAdd from "./adminAdd";

export default () => {
  const { url, path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
          <AdminPanel />
      </Route>
      <Route path={`${path}/add`}>
        <AdminAdd />
      </Route>
    </Switch>
  );
};
