import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuIcon from "@material-ui/icons/Menu";
import { FastField, Formik, FieldArray } from "formik";
import { TextField } from "formik-material-ui";
import axios from "axios";

import { NavigationLink } from "../components/TabNavigationLink";
import { SimpleFileUpload } from "../components/FormikFile";
import { StyledButtonGradient } from "../components/StyledButtons";
import { DeleteDialog } from "./dialogs";
import {
  useDictionary,
  createHandleAddTerm,
  createHandleDeleteTerm,
} from "../../modules/learning/effects";
import { useSelector, useDispatch } from "react-redux";
import { selectTerms } from "../../modules/learning";

const useStyles = makeStyles((theme) => ({
  titleMargin: {
    marginTop: 20,
  },
}));

export const createDictionaryItem = () => {
  return { title: "", description: "" };
};
export default ({ parentUrl }) => {
  const classes = useStyles();
  const url = parentUrl;
  const terms = useSelector(selectTerms);
  useDictionary();

  return (
    <Formik
      key={JSON.stringify(terms)}
      initialValues={{
        items: terms,
      }}
    >
      {(formikProps) => {
        return (
          <FieldArray name="items">
            {(arrayHelpers) => {
              return (
                <>
                  <Grid
                    className={classes.titleMargin}
                    direction="row"
                    container
                    wrap="nowrap"
                    justify="space-between"
                  >
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <NavigationLink
                        to={`${url}/overview`}
                        title={"Learn Overview"}
                      />
                      <Box ml={2}>
                        <NavigationLink
                          to={`${url}/links`}
                          title={"YouTube Links"}
                        />
                      </Box>
                      <Box ml={2}>
                        <NavigationLink
                          to={`${url}/dictionary`}
                          title={"Dictionary"}
                        />
                      </Box>
                      <Box ml={2}>
                        <NavigationLink
                          to={`${url}/glossary`}
                          title={"Exercise Glossary"}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <StyledButtonGradient
                        onClick={() =>
                          arrayHelpers.push(createDictionaryItem())
                        }
                      >
                        Add Terms
                      </StyledButtonGradient>
                    </Box>
                  </Grid>
                  <Grid container direction="column">
                    {formikProps.values.items.map((item, index) => {
                      const term = `items[${index}].title`;
                      const description = `items[${index}].description`;
                      const metaTerm = formikProps.getFieldMeta(term);
                      const metaDescription = formikProps.getFieldMeta(
                        description
                      );
                      const active =
                        metaTerm.initialValue !== metaTerm.value ||
                        metaDescription.initialValue !== metaDescription.value;
                      return (
                        <TermForm
                          buttonDisabled={!active}
                          term={item}
                          index={index}
                          remove={arrayHelpers.remove}
                          key={index}
                        />
                      );
                    })}
                  </Grid>
                </>
              );
            }}
          </FieldArray>
        );
      }}
    </Formik>
  );
};

const useLinkFormStyles = makeStyles({
  marginTop: {
    marginTop: 40,
  },
  buttonWidth: {
    minWidth: 130,
  },
  section: {
    padding: 16,
  },
  sectionBase: {
    backgroundColor: "#F8F8F8",
    borderRadius: 12,
  },
  titleFormat: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
  },
  removeVertical: {
    marginTop: 0,
  },
});

const TermForm = ({ term, remove, index, buttonDisabled }) => {
  const classes = useLinkFormStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { id, title } = term;

  const nameHelper = (name) => {
    return `items[${index}].${name}`;
  };

  const handleDeleteClick = () => {
    setOpen(true);
  };

  const handleSaveClick = createHandleAddTerm(id, term, dispatch);
  const handleConfirmDelete = createHandleDeleteTerm(
    id,
    index,
    dispatch,
    remove
  );

  return (
    <Grid container direction="column" className={classes.marginTop}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
      >
        <Box alignItems="center" display="flex" flexDirection="row">
          <IconButton>
            <MenuIcon />
          </IconButton>
          <Typography>{title}</Typography>
        </Box>
        <IconButton onClick={handleDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </Grid>
      <Grid className={classes.sectionBase}>
        <Grid
          container
          direction="row"
          justify="space-between"
          className={classes.section}
        >
          <Box display="flex" flex={4}>
            <FastField
              name={`${nameHelper("title")}`}
              component={TextField}
              InputProps={{
                disableUnderline: true,
                placeholder: "Enter Term Name",
              }}
              label="Term Name"
              fullWidth
            />
          </Box>
          <Box justifyContent="flex-end" display="flex" flex={1} ml={1}>
            <StyledButtonGradient
              onClick={handleSaveClick}
              disabled={buttonDisabled}
              className={classes.buttonWidth}
            >
              Save
            </StyledButtonGradient>
          </Box>
        </Grid>
        <Grid container className={classes.section}>
          <FastField
            name={`${nameHelper("description")}`}
            component={TextField}
            InputProps={{
              disableUnderline: true,
              placeholder: "Enter Description",
            }}
            label="Description"
            fullWidth
            multiline
            row="2"
          />
        </Grid>
      </Grid>
      <DeleteDialog
        handleDeleteClick={handleConfirmDelete}
        promptText={`Do you wish to delete the term "${title}"?`}
        promptSuccess={`Term "${title}" has been deleted`}
        open={open}
        setOpen={setOpen}
      />
    </Grid>
  );
};
