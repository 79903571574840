import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { sortBy } from "lodash";
import {
  UNIQUE_TYPE,
  TUTORIAL_TYPE,
  setTutorials,
  setUnique,
  deleteTutorial,
  updateTutorials,
  addTutorial,
} from ".";

export const useSettingsImages = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const getImages = async () => {
      try {
        const response = await axios.get("/admin/settings/image-overview");
        const { data } = response;
        const retrievedItems = data.data;
        const uniqueItems = retrievedItems.filter(
          (item) => item.type === UNIQUE_TYPE
        );
        const tutorials = retrievedItems.filter(
          (item) => item.type === TUTORIAL_TYPE
        );
        const sorted = sortBy(tutorials, "order");
        dispatch(setTutorials(sorted));
        dispatch(setUnique(uniqueItems));
      } catch (e) {}
    };

    getImages();
  }, [page]);

  return {
    total,
    page,
    setPage,
  };
};

export const createHandleAddTutorial = (tutorial, dispatch) => {
  return async () => {
    const { id, image, message } = tutorial;
    const data = new FormData();

    data.append("title", "Tutorial");
    data.append("type", TUTORIAL_TYPE);
    data.append("message", message);

    // Create New Link if id is undefined
    if (isNaN(id)) {
      data.append("file", image);
      try {
        const response = await axios.post(
          "/admin/settings/image-overview",
          data,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );

        const item = response.data.data;
        dispatch(addTutorial(item));
      } catch (e) {
        console.log("Failed to create tutorial");
      }
    } else {
      try {
        if (typeof image !== "string") {
          data.append("file", image);
        }
        const response = await axios.post(
          `/admin/settings/image-overview/${id}`,
          data,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );

        const updatedItem = response.data.data;
        dispatch(updateTutorials(id, updatedItem));
      } catch (e) {
        console.log("Failed to update tutorial");
      }
    }
  };
};

export const createHandleDeleteTutorial = (id, index, dispatch, remove) => {
  return async () => {
    // If it is a new field don't send request just remove for list
    if (isNaN(id)) {
      remove(index);
    } else {
      try {
        await axios.delete(`/admin/settings/image-overview/${id}`);
        dispatch(deleteTutorial(id));
      } catch (e) {
        console.log("Failed to delete tutorial");
      }
    }
  };
};
