import React, { useState, useEffect } from "react";
import {
  Redirect,
  useRouteMatch,
  useHistory,
  Route,
  Switch,
  Link,
  useParams,
} from "react-router-dom";
import {
  Grid,
  Typography,
  Box,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import capitalize from "lodash/capitalize";

import GuideSelect from "./GuideSelect";
import NumberOfDaysSelect from "./NumberOfDaysSelect";
import WeekCreator from "./WeekCreator";
import WorkoutCreator from "./WorkoutCreator";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllGuides,
  getGuideTypes,
  getExerciseGlossary,
  setWeek,
  setLevel,
  setPlan,
  setLoading,
  isLoading,
  selectRetrieveCount,
  selectAllGuidesLength,
} from "../../modules/guides";
import GuideLevelAdd from "./guideLevelAdd";


export default () => {
  const dispatch = useDispatch();
  const { url, path } = useRouteMatch();
  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getAllGuides());
    dispatch(getGuideTypes());
    dispatch(getExerciseGlossary());
  }, []);

  const isGuideLoading = useSelector(isLoading);
  const guideCount = useSelector(selectRetrieveCount);
  const totalGuides = useSelector(selectAllGuidesLength);
  return (
    <Switch>
      <Route exact path={path}>
        {isGuideLoading ? (
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
            <p>{`Loading ${guideCount} of ${totalGuides} Gym Guides...`}</p>
          </Box>
        ) : (
          <GuideSelect />
        )}
      </Route>
      <Route path={`${path}/add`}>
        <GuideLevelAdd />
      </Route>
      <Route path={`${path}/:level`}>
        <LevelRoutes />
      </Route>
    </Switch>
  );
};

const LevelRoutes = () => {
  const { url, path } = useRouteMatch();
  const dispatch = useDispatch();
  const { level } = useParams();

  useEffect(() => {
    dispatch(setLevel(level));
  });
  return (
    <Switch>
      <Route exact path={path}>
        <NumberOfDaysSelect />
      </Route>
      <Route path={`${path}/:days`}>
        <WeekRoutes />
      </Route>
    </Switch>
  );
};

const WeekRoutes = () => {
  const { url, path } = useRouteMatch();
  const dispatch = useDispatch();
  const { days } = useParams();
  useEffect(() => {
    dispatch(setPlan(days));
  });
  return (
    <Switch>
      <Route exact path={path}>
        <WeekCreator />
      </Route>
      <Switch>
        <Route path={`${path}/:week`}>
          <DaySelectRoutes />
        </Route>
      </Switch>
    </Switch>
  );
};

const DaySelectRoutes = () => {
  const dispatch = useDispatch();
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const { week, days, level } = useParams();
  useEffect(() => {
    const weekConverted = Number.parseInt(week);
    dispatch(setWeek(weekConverted));
  }, [week]);
  return (
    <Grid container alignItems="flex-start" direction="column" spacing={3}>
      <Grid alignItems="center" direction="row" container>
        <Box alignItems="center" display="flex" flexDirection="row">
          <IconButton onClick={() => history.goBack()}>
            <ChevronLeft />
          </IconButton>
        </Box>
        <Box ml={1}>
          <Typography variant="h5">{`${capitalize(
            level
          )} / ${days} / Week ${week}`}</Typography>
        </Box>
      </Grid>
      <WorkoutCreator path={path} url={url} />
    </Grid>
  );
};
