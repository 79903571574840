import React, { useState, useEffect } from "react";
import {
  useHistory,
  useRouteMatch,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  ButtonBase,
} from "@material-ui/core";
import moment from "moment";

import { StyledButton } from "../components/StyledButtons";
import { NavigationLink } from "../components/TabNavigationLink";

import Promotions from "./promotions";
import Automated from "./automated";
import {
  useUserNotificatons,
  useAutomated,
} from "../../modules/notifications/effects";

const useStyles = makeStyles((theme) => ({
  titleMargin: {
    marginTop: 20,
  },
  buttonResize: {
    height: 40,
  },
  spacing: { marginLeft: 24 },
  activeColor: {
    color: "#F54E55",
  },
  inactiveColor: {
    color: "#DDDDDD",
  },
}));

export default () => {
  useAutomated();
  useUserNotificatons();
  const classes = useStyles();
  const { url, path } = useRouteMatch();
  const history = useHistory();

  return (
    <Grid container alignItems="flex-start" direction="column" spacing={3}>
      <Grid
        direction="row"
        container
        alignItems="center"
        justify="space-between"
      >
        <Typography variant="h3">{"Push Notificatons"}</Typography>
        <Typography variant="h5">{moment().format("L")}</Typography>
      </Grid>
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}/automated`} />
        </Route>
        <Route path={`${path}/automated`}>
          <Automated parentUrl={url} />
        </Route>
        <Route path={`${path}/promotions`}>
          <Promotions parentUrl={url} />
        </Route>
      </Switch>
    </Grid>
  );
};
