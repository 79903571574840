import moment from "moment";
import axios from "axios";
import { useState, useEffect } from "react";

const getDateRange = (range) => {
  const currentDate = moment();
  switch (range) {
    case "year":
      return {
        start: currentDate.subtract(1, "years").format("YYYY-MM-DD"),
        end: moment().add(1, "days").format("YYYY-MM-DD"),
      };
    case "month": {
      return {
        start: currentDate.subtract(1, "months").format("YYYY-MM-DD"),
        end: moment().add(1, "days").format("YYYY-MM-DD"),
      };
    }
    case "week": {
      return {
        start: currentDate.subtract(7, "days").format("YYYY-MM-DD"),
        end: moment().add(1, "days").format("YYYY-MM-DD"),
      };
    }
    default:
      return {
        start: currentDate.subtract(1, "years").format("YYYY-MM-DD"),
        end: moment().add(1, "days").format("YYYY-MM-DD"),
      };
  }
};

export const useStat = (event, range) => {
  const [count, setCount] = useState(0);
  const [data, setData] = useState({});
  const params = getDateRange(range);
  useEffect(() => {
    const getCount = async () => {
      try {
        const response = await axios.get(`/admin/events/${event}`, {
          params,
        });
        const info = response.data;
        let currentTotal = 0;
        for (let date in info) {
          currentTotal = currentTotal + info[date];
        }
        setCount(currentTotal);
        setData(info);
      } catch (e) {
        console.log(e);
      }
    };
    getCount();
  }, [event, range]);

  return { count, data };
};

export const useTracks = (range) => {
  const [data, setData] = useState([]);
  const params = getDateRange(range);
  useEffect(() => {
    const getCount = async () => {
      try {
        const response = await axios.get(`/admin/track/summary`, {
          params,
        });
        const records = response.data;
        setData(records);
      } catch (e) {
        console.log(e);
      }
    };
    getCount();
  }, [range]);
  return { records: data };
};
