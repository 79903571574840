import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import axios from "axios";

import {
  Dialog,
  DialogTitle,
  Typography,
  makeStyles,
  Grid,
  DialogContent,
  Box,
  FormControl,
  Input,
  InputLabel,
} from "@material-ui/core";
import {
  StyledButton,
  StyledButtonGradient,
  StyledButtonDisabled,
} from "../components/StyledButtons";
import {
  createAdminPasswordReset,
  createAdminRoleUpdate,
  createAdminEmailUpdate,
  createDeleteUserClick,
} from "./effects";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentAdmin, selectAdminUsers } from "../../modules/admin";

const useStyles = makeStyles({
  resetModal: {
    height: 200,
    width: 300,
  },
  subscriptionModal: {
    height: 450,
    width: 400,
  },
  buttonWidth: {
    minWidth: 120,
  },
  round: {
    borderRadius: 16,
  },
  paper: {
    margin: 32,
    position: "relative",
    overflowY: "auto",
    borderRadius: 16,
  },
});

export const ResetDialog = ({ open, setOpen }) => {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const selectedAdmin = useSelector(selectCurrentAdmin);

  if (isEmpty(selectedAdmin)) {
    return null;
  }

  const { id } = selectedAdmin;
  const handleClick = createAdminPasswordReset(setSuccess, id);

  const handleClose = () => setOpen(false);
  const handleSuccessClose = () => {
    setOpen(false);
    setSuccess(false);
  };

  return (
    <Dialog
      transitionDuration={{ exit: 0 }}
      classes={{ paper: classes.paper }}
      className={classes.round}
      open={open}
      onClose={handleClose}
    >
      <DialogContent className={classes.round}>
        <Grid
          className={classes.resetModal}
          container
          alignItems="center"
          direction="column"
          justify="space-between"
        >
          {!success ? (
            <>
              <Grid>
                <Typography align="center" variant="body1">
                  Do you wish to send a reset password email to this user?
                </Typography>
              </Grid>
              <Grid container justify="space-between">
                <StyledButtonGradient
                  className={classes.buttonWidth}
                  onClick={handleClick}
                >
                  Yes
                </StyledButtonGradient>
                <StyledButton
                  className={classes.buttonWidth}
                  onClick={handleClose}
                >
                  No
                </StyledButton>
              </Grid>
            </>
          ) : (
            <>
              <Grid>
                <Typography align="center" variant="body1">
                  Reset Password email has been sent to this user’s email
                  address.
                </Typography>
              </Grid>
              <Grid container justify="center">
                <StyledButtonGradient
                  className={classes.buttonWidth}
                  onClick={handleSuccessClose}
                >
                  Okay
                </StyledButtonGradient>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export const DeleteUserDialog = ({ open, setOpen, users, setUsers }) => {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const selectedAdmin = useSelector(selectCurrentAdmin);
  const admins = useSelector(selectAdminUsers);
  const dispatch = useDispatch();

  if (isEmpty(selectedAdmin)) {
    return null;
  }
  const handleClick = createDeleteUserClick(
    selectedAdmin,
    setSuccess,
    dispatch,
    admins
  );

  const handleClose = () => setOpen(false);
  const handleSuccessClose = () => {
    setOpen(false);
    setSuccess(false);
  };

  return (
    <Dialog
      transitionDuration={{ exit: 0 }}
      classes={{ paper: classes.paper }}
      className={classes.round}
      open={open}
      onClose={handleClose}
    >
      <DialogContent className={classes.round}>
        <Grid
          className={classes.resetModal}
          container
          alignItems="center"
          direction="column"
          justify="space-between"
        >
          {!success ? (
            <>
              <Grid>
                <Typography align="center" variant="body1">
                  Do you wish to delete this user ? Actions are not reversable
                </Typography>
              </Grid>
              <Grid container justify="space-between">
                <StyledButtonGradient
                  className={classes.buttonWidth}
                  onClick={handleClick}
                >
                  Yes
                </StyledButtonGradient>
                <StyledButton
                  className={classes.buttonWidth}
                  onClick={handleClose}
                >
                  No
                </StyledButton>
              </Grid>
            </>
          ) : (
            <>
              <Grid>
                <Typography align="center" variant="body1">
                  User has been deleted from the list
                </Typography>
              </Grid>
              <Grid container justify="center">
                <StyledButtonGradient
                  className={classes.buttonWidth}
                  onClick={handleSuccessClose}
                >
                  Okay
                </StyledButtonGradient>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export const RoleChangeDialog = ({ open, setOpen, setUsers, users }) => {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const [user, setUser] = useState({ role_title: "Moderator" });
  const selectedAdmin = useSelector(selectCurrentAdmin);
  const admins = useSelector(selectAdminUsers);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedAdmin === undefined || selectedAdmin === null) {
      setUser({ role: "Moderator" });
    } else {
      setUser(selectedAdmin);
    }
  }, [selectedAdmin]);

  if (isEmpty(selectedAdmin)) {
    return null;
  }

  const handleClick = createAdminRoleUpdate(
    selectedAdmin,
    setSuccess,
    dispatch,
    admins
  );

  const handleClose = () => setOpen(false);
  const handleSuccessClose = () => {
    setOpen(false);
    setSuccess(false);
  };

  const getOppositeRole = (role) => {
    if (role === "Adminstrator") {
      return "Moderator";
    } else {
      return "Adminstrator";
    }
  };

  return (
    <Dialog
      transitionDuration={{ exit: 0 }}
      classes={{ paper: classes.paper }}
      className={classes.round}
      open={open}
      onClose={handleClose}
    >
      <DialogContent className={classes.round}>
        <Grid
          className={classes.resetModal}
          container
          alignItems="center"
          direction="column"
          justify="space-between"
        >
          {!success ? (
            <>
              <Grid>
                <Typography align="center" variant="body1">
                  {`Do you wish update user’s role from ${
                    user.role_title
                  } to ${getOppositeRole(user.role_title)}?`}
                </Typography>
              </Grid>
              <Grid container justify="space-between">
                <StyledButtonGradient
                  className={classes.buttonWidth}
                  onClick={handleClick}
                >
                  Yes
                </StyledButtonGradient>
                <StyledButton
                  className={classes.buttonWidth}
                  onClick={handleClose}
                >
                  No
                </StyledButton>
              </Grid>
            </>
          ) : (
            <>
              <Grid>
                <Typography align="center" variant="body1">
                  {`User’s role has been updated from ${getOppositeRole(
                    user.role_title
                  )} to ${selectedAdmin.role_title}`}
                </Typography>
              </Grid>
              <Grid container justify="center">
                <StyledButtonGradient
                  className={classes.buttonWidth}
                  onClick={handleSuccessClose}
                >
                  Okay
                </StyledButtonGradient>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export const AdminCreateDialog = ({ open, setOpen }) => {
  const classes = useStyles();
  const history = useHistory();
  const handleSuccessClose = () => {
    history.goBack();
    setOpen(false);
  };

  return (
    <Dialog
      transitionDuration={{ exit: 0 }}
      classes={{ paper: classes.paper }}
      className={classes.round}
      open={open}
      onClose={handleSuccessClose}
    >
      <DialogContent className={classes.round}>
        <Grid
          className={classes.resetModal}
          container
          alignItems="center"
          direction="column"
          justify="space-between"
        >
          <Grid>
            <Typography align="center" variant="body1">
              New Admin has been added! They will receive an email and notice
              with the login credentials.
            </Typography>
          </Grid>
          <Grid container justify="center">
            <StyledButtonGradient
              className={classes.buttonWidth}
              onClick={handleSuccessClose}
            >
              Okay
            </StyledButtonGradient>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export const EmailChangeDialog = ({ open, setOpen, users, setUsers }) => {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");

  const selectedAdmin = useSelector(selectCurrentAdmin);
  const admins = useSelector(selectAdminUsers);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(selectedAdmin)) {
      setEmail(selectedAdmin.email);
    }
  }, [selectedAdmin]);

  const handleClick = createAdminEmailUpdate(
    email,
    selectedAdmin,
    setSuccess,
    dispatch,
    admins
  );

  const handleClose = () => setOpen(false);
  const handleSuccessClose = () => {
    setOpen(false);
    setSuccess(false);
  };

  if (selectedAdmin === null) {
    return null;
  }

  return (
    <Dialog
      transitionDuration={{ exit: 0 }}
      classes={{ paper: classes.paper }}
      className={classes.round}
      open={open}
      onClose={handleClose}
    >
      <DialogContent className={classes.round}>
        <Grid
          className={classes.resetModal}
          container
          alignItems="center"
          direction="column"
          justify="space-between"
        >
          {!success ? (
            <>
              <Grid>
                <Typography align="center" variant="body1">
                  Update Email Address
                </Typography>
                <Box mt={2} mb={2}>
                  <FormControl>
                    <InputLabel id="admin-email-label">
                      Email Address
                    </InputLabel>
                    <Input
                      type="email"
                      id="admin-email-input"
                      labelId="admin-email-label"
                      value={email}
                      onChange={(e) => {
                        const v = e.target.value;
                        setEmail(v);
                      }}
                    ></Input>
                  </FormControl>
                </Box>
              </Grid>
              <Grid container justify="space-between">
                {selectedAdmin.email !== email ? (
                  <StyledButtonGradient
                    className={classes.buttonWidth}
                    onClick={handleClick}
                  >
                    Update
                  </StyledButtonGradient>
                ) : (
                  <StyledButtonDisabled disabled>Update</StyledButtonDisabled>
                )}
                <StyledButton
                  className={classes.buttonWidth}
                  onClick={handleClose}
                >
                  Not Now
                </StyledButton>
              </Grid>
            </>
          ) : (
            <>
              <Grid>
                <Typography align="center" variant="body1">
                  User’s email address has been updated. They will receive an
                  email from the new address to verify account.
                </Typography>
              </Grid>
              <Grid container justify="center">
                <StyledButtonGradient
                  className={classes.buttonWidth}
                  onClick={handleSuccessClose}
                >
                  Okay
                </StyledButtonGradient>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
