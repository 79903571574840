import React, { useEffect, useState } from "react";
import { useRouteMatch, Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import capitalize from "lodash/capitalize";

import {
  Grid,
  Box,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Delete from "@material-ui/icons/Delete";

import { StyledButtonGradient } from "../components/StyledButtons";
import { DeleteWeekDialog } from "./dialogs";
import {
  setPlan,
  selectNumberOfWeeks,
  selectPlan,
  selectLevel,
} from "../../modules/guides";
import {
  createHandleAddWeek,
  createHandleDeleteWeek,
} from "../../modules/guides/effects";

const useStyles = makeStyles({
  marginTop: {
    marginTop: 40,
  },
  buttonWidth: {
    minWidth: 300,
  },
  section: {
    padding: 16,
  },
  sectionBase: {
    backgroundColor: "#F8F8F8",
    borderRadius: 12,
  },
  buttonResize: {
    height: 40,
  },
});

export default () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const { days } = useParams();

  const weeks = useSelector(selectNumberOfWeeks);
  const plan = useSelector(selectPlan);
  const level = useSelector(selectLevel);
  const [deleteWeekIndex, setDeleteWeekIndex] = useState(1);
  const [open, setOpen] = useState(false);

  // If new week occurs increase count
  const addWeek = createHandleAddWeek(weeks.length + 1, plan, level, dispatch);
  const removeWeek = createHandleDeleteWeek(
    deleteWeekIndex,
    plan,
    level,
    dispatch
  );
  const handleDeleteClick = (index) => {
    return () => {
      setOpen(true);
      setDeleteWeekIndex(index);
    };
  };

  return (
    <Grid container alignItems="flex-start" direction="column" spacing={3}>
      <Grid
        alignItems="center"
        direction="row"
        container
        justify="space-between"
      >
        <Box alignItems="center" display="flex" flexDirection="row">
          <Box>
            <IconButton onClick={() => history.goBack()}>
              <ChevronLeft />
            </IconButton>
          </Box>
          <Box ml={1}>
            <Typography variant="h5">{`${capitalize(
              level
            )} / ${days}`}</Typography>
          </Box>
        </Box>
        <StyledButtonGradient
          className={classes.buttonResize}
          onClick={addWeek}
        >
          Add New Week
        </StyledButtonGradient>
      </Grid>
      <Grid
        className={classes.marginTop}
        container
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Typography variant="body1">Please select a week below:</Typography>
        {weeks.map((w, index) => (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            key={index}
            mt={2}
          >
            <StyledButtonGradient
              className={classes.buttonWidth}
              to={`${url}/${w}`} // links to week # with the index + 1 matching
              component={Link}
            >
              {`Week ${w}`}
            </StyledButtonGradient>
            <IconButton onClick={handleDeleteClick(w)}>
              <Delete />
            </IconButton>
          </Box>
        ))}
      </Grid>
      <DeleteWeekDialog
        weekIndex={deleteWeekIndex}
        removeWeek={removeWeek}
        open={open}
        setOpen={setOpen}
      />
    </Grid>
  );
};
