import React, { useState, useEffect } from "react";
import { values } from "lodash";
import {
  FormControl,
  Grid,
  Box,
  makeStyles,
  Select,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";

import { NavigationLink } from "../components/TabNavigationLink";
import { NoFormFileDisplay } from "../components/FormikFile";
import { StyledButtonGradient } from "../components/StyledButtons";
import NakedSelect from "../components/NakedSelect";
import {
  HOME_TYPE,
  GYM_TYPE,
  useAutomated,
} from "../../modules/popups/effects";
import { useSelector } from "react-redux";
import { selectAutomatedPopups } from "../../modules/popups";

const useStyles = makeStyles((theme) => ({
  titleMargin: {
    marginTop: 20,
  },
  dropdownMenu: {
    height: 48,
    marginBottom: 40,
    marginTop: 40,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
  select: {
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: "25ch",
    borderBottomWidth: 0,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "flex-start",
  },
  selectInput: {
    background: 0,
    padding: "6px 0 7px",
    borderRadius: 0,
    margin: 0,
    minHeight: 0,
  },
}));

export default ({ parentUrl }) => {
  useAutomated();
  const classes = useStyles();
  const url = parentUrl;
  const [categories, setCategories] = useState([GYM_TYPE, HOME_TYPE]);
  const [category, setCategory] = useState(GYM_TYPE);
  const popups = useSelector(selectAutomatedPopups);

  return (
    <>
      <Grid
        className={classes.titleMargin}
        direction="row"
        container
        justify="flex-start"
      >
        <NavigationLink to={`${url}/automated`} title={"Automated"} />

        <Box ml={2}>
          <NavigationLink to={`${url}/promotions`} title={"Promotions"} />
        </Box>
      </Grid>
      {/* Automated type select */}
      <Grid>
        <FormControl className={classes.dropdownMenu}>
          <Select
            className={classes.select}
            id="select-user-filter"
            value={category || "N/A"}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
            input={<NakedSelect />}
            inputProps={{ className: classes.selectInput }}
          >
            {categories.map((v) => (
              <MenuItem key={v} value={v}>
                {v.charAt(0).toUpperCase() + v.slice(1)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid container direction="column">
        {/* Map out each category, currently single */}
        {popups
          .filter((p) => p.type === category)
          .map((popup, index) => (
            <PopUpAutomated key={index} popup={popup} />
          ))}
      </Grid>
    </>
  );
};

const useFormStyles = makeStyles({
  section: {
    padding: 16,
  },
  marginTop: {
    marginTop: 20,
  },
  sectionBase: {
    backgroundColor: "#F8F8F8",
    borderRadius: 12,
  },
  minButtonWidth: {
    minWidth: 130,
  },
});
const PopUpAutomated = ({ popup }) => {
  const classes = useFormStyles();

  const {
    title,
    background_image,
    supporting_image,
    popup_title,
    button_text,
    message,
    to_date,
    from_date,
  } = popup;
  return (
    <Grid container direction="column" className={classes.marginTop}>
      <Grid container direction="row" alignItems="center" justify="flex-start">
        <Typography>{title}</Typography>
      </Grid>
      <Grid className={classes.sectionBase}>
        <Grid
          container
          direction="row"
          //   justify="space-between"
          className={classes.section}
        >
          <Box display="flex" flex={3}>
            <NoFormFileDisplay
              label={"Background Image"}
              name={background_image}
            />
          </Box>
          <Box justifyContent="center" display="flex" flex={2} ml={1}>
            <StyledButtonGradient
              className={classes.minButtonWidth}
              disabled
              type="submit"
            >
              Save
            </StyledButtonGradient>
          </Box>
        </Grid>
        <Grid direction="row" container className={classes.section}>
          <Box display="flex" flex={3}>
            <NoFormFileDisplay
              label={"Support Image"}
              name={supporting_image}
            />
          </Box>
          <Box display="flex" flex={2} ml={1}></Box>
        </Grid>
        <Grid
          alignItems="center"
          direction="row"
          container
          className={classes.section}
        >
          <Box display="flex" flex={3}>
            <TextField
              InputProps={{ disableUnderline: true }}
              fullWidth
              label="Pop Up Title"
              value={popup_title}
            />
          </Box>
          <Box display="flex" flex={3} ml={1}>
            <TextField
              InputProps={{ disableUnderline: true }}
              fullWidth
              label="Button Text"
              value={button_text}
            />
          </Box>
        </Grid>
        <Grid
          alignItems="center"
          direction="row"
          container
          className={classes.section}
        >
          <TextField
            InputProps={{ disableUnderline: true }}
            fullWidth
            label="Message"
            value={message}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
