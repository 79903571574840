import React, { useState } from "react";

import {
  MenuItem,
  Grid,
  makeStyles,
  Typography,
  FormControl,
  Select,
} from "@material-ui/core";
import CardAnalytic from "./CardAnalytics";
import { FilterTypes } from "./constants";
import NakedSelect from "../components/NakedSelect";
import { ParentSize } from "@vx/responsive";
// import { ResponsiveGraph as Chart } from "./chart";
import Chart from "./chart";
import { useStat } from "../../modules/dashboard/effects";
import supplements from "../shopping/supplements";

export const cardData = {
  count: 510,
  category: "Photo Uploads",
  updatedAt: new Date(),
};

const useStyles = makeStyles((theme) => ({
  dropdownMenu: {
    height: 48,
    marginBottom: 40,
    marginTop: 40,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
  select: {
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: "25ch",
    borderBottomWidth: 0,
    backgroundColor: "#F2F0F0",
    display: "flex",
    justifyContent: "flex-start",
  },
  graphContainer: {
    minWidth: 200,
    height: 240,
  },
  selectInput: {
    background: 0,
    padding: "6px 0 7px",
    borderRadius: 0,
    margin: 0,
    minHeight: 0,
  },
}));

export default () => {
  const classes = useStyles();
  const [filter, setFilter] = useState(FilterTypes.YEAR);
  const [generalChart, setGeneralChart] = useState({
    title: "Default title",
    data: {},
    show: false,
  });
  const [guidesChart, setGuidesChart] = useState({
    title: "Default title",
    data: {},
    show: false,
  });
  const [shareChart, setShareChart] = useState({
    title: "Default title",
    data: {},
    show: false,
  });
  const [shopChart, setShopChart] = useState({
    title: "Default title",
    data: {},
    show: false,
  });

  // General
  const personalRecordStats = useStat("exercise-tracked", filter);
  const photoStat = useStat("upload-photo", filter);
  const journalStat = useStat("upload-journal", filter);

  // Complete Guides
  const guideBeginner = useStat("guide-beginner", filter);
  const guideIntermediate = useStat("guide-intermediate", filter);
  const guideAdvanced = useStat("guide-advance", filter);

  // Users
  const shopSupplements = useStat("shop-supplements", filter);
  const shopAccessory = useStat("shop-accessory", filter);

  // Share
  const shareInstagram = useStat("share-instagram", filter);
  const shareFacebook = useStat("share-facebook", filter);
  return (
    <>
      <Grid>
        <FormControl className={classes.dropdownMenu}>
          <Select
            className={classes.select}
            id="select-user-filter"
            value={filter}
            onChange={(e) => {
              setFilter(e.target.value);
            }}
            input={<NakedSelect />}
            inputProps={{ className: classes.selectInput }}
          >
            <MenuItem value={FilterTypes.YEAR}>
              This {FilterTypes.YEAR}
            </MenuItem>
            <MenuItem value={FilterTypes.MONTH}>
              This {FilterTypes.MONTH}
            </MenuItem>
            <MenuItem value={FilterTypes.WEEK}>
              This {FilterTypes.WEEK}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid container alignItems="flex-start" direction="column">
        <Typography>General</Typography>
        <Grid container direction="row">
          <CardAnalytic
            onButtonClick={() => {
              setGeneralChart({
                show: !generalChart.show,
                title: "Personal Records",
                data: personalRecordStats.data,
              });
            }}
            category={"Personal Records"}
            count={personalRecordStats.count}
          />
          <CardAnalytic
            onButtonClick={() => {
              setGeneralChart({
                show: !generalChart.show,
                title: "Uploaded Photos",
                data: photoStat.data,
              });
            }}
            category={"Uploaded Photos"}
            count={photoStat.count}
          />
          <CardAnalytic
            onButtonClick={() => {
              setGeneralChart({
                show: !generalChart.show,
                title: "Uploaded Non-scale Victories",
                data: journalStat.data,
              });
            }}
            category={"Uploaded Non-scale Victories"}
            count={journalStat.count}
          />
        </Grid>
        {generalChart.show && (
          <Grid className={classes.graphContainer} container>
            <ParentSize>
              {({ width: w, height: h }) => {
                return (
                  <Chart
                    title={generalChart.title}
                    width={w}
                    height={h}
                    type={filter}
                    data={generalChart.data}
                    margin={{
                      top: 50,
                      left: 40,
                      right: 40,
                      bottom: 50,
                    }}
                  />
                );
              }}
            </ParentSize>
          </Grid>
        )}
        <Typography>Completed Guides</Typography>
        <Grid container direction="row">
          <CardAnalytic
            onButtonClick={() => {
              setGuidesChart({
                show: !guidesChart.show,
                title: "Completed Beginner Guides",
                data: guideBeginner.data,
              });
            }}
            category={"Beginner Guides Complete"}
            count={guideBeginner.count}
          />
          <CardAnalytic
            onButtonClick={() => {
              setGuidesChart({
                show: !guidesChart.show,
                title: "Completed Intermediate Guides",
                data: guideIntermediate.data,
              });
            }}
            category={"Intermediate Guides Complete"}
            count={guideIntermediate.count}
          />
          <CardAnalytic
            onButtonClick={() => {
              setGuidesChart({
                show: !guidesChart.show,
                title: "Completed Advanced Guides",
                data: guideAdvanced.data,
              });
            }}
            category={"Advanced Guides Complete"}
            count={guideAdvanced.count}
          />
        </Grid>
        {guidesChart.show && (
          <Grid className={classes.graphContainer} container>
            <ParentSize>
              {({ width: w, height: h }) => {
                return (
                  <Chart
                    title={guidesChart.title}
                    width={w}
                    height={h}
                    type={filter}
                    data={guidesChart.data}
                    margin={{
                      top: 50,
                      left: 40,
                      right: 40,
                      bottom: 50,
                    }}
                  />
                );
              }}
            </ParentSize>
          </Grid>
        )}
        <Typography>Users</Typography>
        <Grid container direction="row">
          <CardAnalytic
            onButtonClick={() => {
              setShopChart({
                show: !shopChart.show,
                title: "Visits to Bloom Shop: Accessories",
                data: shopAccessory.data,
              });
            }}
            category={"Visits to Bloom Shop: Accessories"}
            count={shopAccessory.count}
          />
          <CardAnalytic
            onButtonClick={() => {
              setShopChart({
                show: !shopChart.show,
                title: "Visits to Bloom Shop: Supplements",
                data: shopSupplements.data,
              });
            }}
            category={"Visits to Bloom Shop: Supplements"}
            count={shopSupplements.count}
          />
        </Grid>
        {shopChart.show && (
          <Grid className={classes.graphContainer} container>
            <ParentSize>
              {({ width: w, height: h }) => {
                return (
                  <Chart
                    title={shopChart.title}
                    width={w}
                    height={h}
                    type={filter}
                    data={shopChart.data}
                    margin={{
                      top: 50,
                      left: 40,
                      right: 40,
                      bottom: 50,
                    }}
                  />
                );
              }}
            </ParentSize>
          </Grid>
        )}

        {/* <Typography>Subscriptions</Typography> */}

        <Typography>Social Media</Typography>
        <Grid container direction="row">
          <CardAnalytic
            onButtonClick={() => {
              setShareChart({
                show: !shareChart.show,
                title: "People shared to Facebook",
                data: shareFacebook.data,
              });
            }}
            category={"People shared to Facebook"}
            count={shareFacebook.count}
          />
          <CardAnalytic
            onButtonClick={() => {
              setShareChart({
                show: !shareChart.show,
                title: "People shared to Instagram",
                data: shareInstagram.data,
              });
            }}
            category={"People shared to Instagram"}
            count={shareInstagram.count}
          />
        </Grid>
        {shareChart.show && (
          <Grid className={classes.graphContainer} container>
            <ParentSize>
              {({ width: w, height: h }) => {
                return (
                  <Chart
                    title={shareChart.title}
                    width={w}
                    height={h}
                    type={filter}
                    data={shareChart.data}
                    margin={{
                      top: 50,
                      left: 40,
                      right: 40,
                      bottom: 50,
                    }}
                  />
                );
              }}
            </ParentSize>
          </Grid>
        )}
      </Grid>
    </>
  );
};
