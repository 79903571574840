import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import axios from "axios";

import reducer from "./rootreducer";
import saga from "./rootsaga";
import { cancelSource } from "./axiosConfig";

/* Persist Configure */
const persistConfig = {
  key: "root",
  storage,
};

/* Configure store */
let rehydrationComplete;
let rehydrationFailed;

const rehydrationPromise = new Promise((resolve, reject) => {
  rehydrationComplete = resolve;
  rehydrationFailed = reject;
});

export function rehydration() {
  return rehydrationPromise;
}

export default () => {
  const persistedReducer = persistReducer(persistConfig, reducer);

  // create the saga middleware
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [sagaMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const devCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const composedEnhancers = devCompose(...enhancers);

  const store = createStore(persistedReducer, undefined, composedEnhancers);
  let persistor = persistStore(store, null, () => {
    const state = store.getState();
    // console.log("reloaded", state.authentication.token);
    if (state.authentication.token) {
      axios.defaults.headers = {
        Authorization: `Bearer ${state.authentication.token}`,
      };
    }
    rehydrationComplete();
  });
  // then run the saga
  sagaMiddleware.run(saga);
  return { store, persistor };
};
