import { createSelector } from "reselect";
import { first } from "lodash";
import { put, all, call, delay, takeLatest } from "redux-saga/effects";
import moment from "moment";
import axios from "axios";
/**
 * Actions
 */

export const SET_YOUTUBE = "LEARNING/YOUTUBE_SET";
export const setYoutube = (links) => ({
  type: SET_YOUTUBE,
  links,
});

export const SET_TERMS = "LEARNING/TERMS_SET";
export const setTerms = (terms) => ({
  type: SET_TERMS,
  terms,
});

export const SET_EXCERCISES = "LEARNING/EXERCISES_SET";
export const setExercises = (exercises) => ({
  type: SET_EXCERCISES,
  exercises,
});

export const UPDATE_YOUTUBE = "LEARNING/YOUTUBE_UPDATE";
export const updateYoutube = (id, link) => ({
  type: UPDATE_YOUTUBE,
  id,
  link,
});

export const UPDATE_TERMS = "LEARNING/TERMS_UPDATE";
export const updateTerms = (id, term) => ({
  type: UPDATE_TERMS,
  id,
  term,
});

export const UPDATE_EXCERCISES = "LEARNING/EXERCISES_UPDATE";
export const updateExercises = (id, exercise) => ({
  type: UPDATE_EXCERCISES,
  id,
  exercise,
});

export const DELETE_YOUTUBE = "LEARNING/YOUTUBE_DELETE";
export const deleteYoutube = (id) => ({
  type: DELETE_YOUTUBE,
  id,
});

export const DELETE_TERMS = "LEARNING/TERMS_DELETE";
export const deleteTerms = (id) => ({
  type: DELETE_TERMS,
  id,
});

export const DELETE_EXCERCISES = "LEARNING/EXERCISES_DELETE";
export const deleteExercises = (id) => ({
  type: DELETE_EXCERCISES,
  id,
});

export const ADD_YOUTUBE = "LEARNING/YOUTUBE_ADD";
export const addYoutube = (link) => ({
  type: ADD_YOUTUBE,
  link,
});

export const ADD_TERMS = "LEARNING/TERMS_ADD";
export const addTerms = (term) => ({
  type: ADD_TERMS,
  term,
});

export const ADD_EXCERCISES = "LEARNING/EXERCISES_ADD";
export const addExercises = (exercise) => ({
  type: ADD_EXCERCISES,
  exercise,
});

export const SET_GLOSSARY_TYPES = "LEARNING/GLOSSARY_TYPES_SET";
export const setGlossaryTypes = (types) => ({
  type: SET_GLOSSARY_TYPES,
  types,
});

export const SET_GLOSSARY_CATEGORY = "LEARNING/SET_ACTIVE_CATEGORY";
export const setGlossaryCategory = (category) => ({
  type: SET_GLOSSARY_CATEGORY,
  category,
});

export const SET_GLOSSARY_CATEGORY_BY_ID = "LEARNING/SET_ACTIVE_CATEGORY_BY_ID";
export const setGlossaryCategoryById = (id) => ({
  type: SET_GLOSSARY_CATEGORY_BY_ID,
  id,
});

export const SET_OVERVIEW_IMAGES = "LEARNING/SET_OVERVIEW_IMAGES";
export const setOverviewImages = (images) => ({
  type: SET_OVERVIEW_IMAGES,
  images,
});

export const UPDATE_OVERVIEW_IMAGE = "LEARNING/UPDATE_OVERVIEW_IMAGE";
export const updateOverviewImage = (id, image) => ({
  type: UPDATE_OVERVIEW_IMAGE,
  id,
  image,
});

/**
 * Reducer
 */
const initState = {
  youtubeLinks: [],
  terms: [],
  exercises: [],
  glossaryTypes: [],
  overviewImages: [],
  category: null,
};

export const learnReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_YOUTUBE:
      return { ...state, youtubeLinks: action.links };
    case SET_TERMS:
      return { ...state, terms: action.terms };
    case SET_EXCERCISES:
      return { ...state, exercises: action.exercises };
    case SET_GLOSSARY_TYPES:
      return { ...state, glossaryTypes: action.types };
    case SET_GLOSSARY_CATEGORY:
      return { ...state, category: action.category };
    case SET_OVERVIEW_IMAGES:
      return { ...state, overviewImages: action.images };
    case SET_GLOSSARY_CATEGORY_BY_ID:
      const category = first(
        state.glossaryTypes.filter((c) => c.id === action.id)
      );
      return { ...state, category };

    case UPDATE_OVERVIEW_IMAGE:
      const newOverviewImages = state.overviewImages.map((image) =>
        image.id === action.id ? action.image : image
      );
      return { ...state, overviewImages: newOverviewImages };

    case UPDATE_YOUTUBE:
      const newLinks = state.youtubeLinks.map((l) =>
        l.id === action.id ? action.link : l
      );
      return { ...state, youtubeLinks: newLinks };
    case UPDATE_TERMS:
      const newTerms = state.terms.map((t) =>
        t.id === action.id ? action.term : t
      );
      return { ...state, terms: newTerms };
    case UPDATE_EXCERCISES:
      const newExercises = state.exercises.map((e) =>
        e.id === action.id ? action.exercise : e
      );
      return { ...state, exercises: newExercises };
    case DELETE_YOUTUBE:
      const filteredLinks = state.youtubeLinks.filter(
        (l) => l.id !== action.id
      );
      return { ...state, youtubeLinks: filteredLinks };
    case DELETE_TERMS:
      const filteredTerms = state.terms.filter((t) => t.id !== action.id);
      return { ...state, terms: filteredTerms };
    case DELETE_EXCERCISES:
      const filteredExercises = state.exercises.filter(
        (e) => e.id !== action.id
      );
      return { ...state, exercises: filteredExercises };
    case ADD_YOUTUBE:
      const addedLinks = [...state.youtubeLinks, action.link];
      return { ...state, youtubeLinks: addedLinks };
    case ADD_TERMS:
      const addedTerms = [...state.terms, action.term];
      return { ...state, terms: addedTerms };
    case ADD_EXCERCISES:
      const addedExercises = [...state.exercises, action.exercise];
      return { ...state, exercises: addedExercises };
    default:
      return state;
  }
};

/**
 * Sagas
 */
function* handleSetActiveUser() {
  yield delay(100);
  const userData = {
    subscriptionInfo: {
      frequency: "monthly",
      cost: 10.0,
      freeTrial: true,
      freeTrialEnd: "9/30",
    },
    joinInfo: {
      since: "06/19/2020",
    },
    paymentInfo: [
      {
        date: moment().calendar(),
        amount: 100.0,
        status: "Complete",
      },
      {
        date: moment().calendar(),
        amount: 100.0,
        status: "Failed",
      },
    ],
  };
  // yield put(setUserDetails(userData));
}

function* watchSelectUser() {
  yield takeLatest([], handleSetActiveUser);
}

function* handleGetUsers() {
  try {
    let params = {};
    const res = yield call(axios.get, "/admin/site-users", {
      params,
    });
  } catch (e) {
    console.log(e);
  }
}

function* watchGetUsers() {
  yield takeLatest([], handleGetUsers);
}

export function* rootSaga() {
  yield all([call(watchSelectUser), call(watchGetUsers)]);
}

/**
 *  Selectors
 */

export const selectYoutubeLinks = (state) => state.learning.youtubeLinks || [];
export const selectTerms = (state) => state.learning.terms || [];
export const selectExercises = (state) => state.learning.exercises || [];
export const selectGlossaryTypes = (state) =>
  state.learning.glossaryTypes || [];
export const selectActiveCategory = (state) => state.learning.category;
export const selectOverviewImages = (state) =>
  state.learning.overviewImages || [];

export const selectFilteredExercises = createSelector(
  [selectExercises, selectActiveCategory],
  (exercises, category) => {
    if (category === null) {
      return exercises;
    }
    return exercises.filter((e) => e.glossary_type_id === category.id);
  }
);
