import React, { useState, useEffect } from "react";
import {
  useHistory,
  useRouteMatch,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";
import clsx from "clsx";
import { Form, Formik, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  ButtonBase,
} from "@material-ui/core";
import axios from "axios";

import {
  StyledButton,
  StyledButtonGradient,
} from "../components/StyledButtons";
import { NavigationLink } from "../components/TabNavigationLink";
import { SimpleFileUpload } from "../components/FormikFile";
import { selectOverviewImages } from "../../modules/shopping";
import { createHandleUpdateOverviewImage } from "../../modules/shopping/effects";

const useStyles = makeStyles((theme) => ({
  titleMargin: {
    marginTop: 20,
  },
  marginTop: {
    marginTop: 40,
  },
  buttonResize: {
    height: 40,
  },
  spacing: { marginLeft: 24 },
  activeColor: {
    color: "#F54E55",
  },
  inactiveColor: {
    color: "#DDDDDD",
  },
  section: {
    padding: 16,
    paddingTop: 0,
  },
  sectionBase: {
    backgroundColor: "#F8F8F8",
    borderRadius: 12,
  },
}));

export default ({ parentUrl }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const url = parentUrl;
  const images = useSelector(selectOverviewImages);
  const handleSubmit = createHandleUpdateOverviewImage(dispatch);
  return (
    <>
      <Grid
        className={classes.titleMargin}
        direction="row"
        container
        justify="flex-start"
      >
        <NavigationLink to={`${url}/overview`} title={"Shop Overview"} />
        <Box ml={2}>
          <NavigationLink
            to={`${url}/supplements`}
            title={"Bloomu Supplements"}
          />
        </Box>
        <Box ml={2}>
          <NavigationLink
            to={`${url}/accessories`}
            title={"Slay Accessories"}
          />
        </Box>
      </Grid>

      {/* Shop overview images */}
      <Grid
        className={classes.marginTop}
        container
        direction="column"
        alignItems="flex-start"
      >
        {/* Supplement Banner Image */}
        {images.map(({ image, title, type, id, order }) => (
          <Grid
            key={id}
            container
            direction="column"
            className={classes.marginTop}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="flex-start"
            >
              <Typography>{title}</Typography>
            </Grid>
            <Grid className={classes.sectionBase}>
              <Formik
                key={JSON.stringify(id + image)}
                initialValues={{
                  image,
                  id,
                  type,
                  title,
                  order,
                }}
                onSubmit={handleSubmit}
              >
                {(props) => {
                  const disabled = !props.dirty;
                  return (
                    <Form>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        className={classes.section}
                      >
                        <Box display="flex" flex={4}>
                          <Field name="image" component={SimpleFileUpload} />
                        </Box>
                        <Box
                          justifyContent="flex-end"
                          display="flex"
                          flex={1}
                          ml={1}
                        >
                          <StyledButtonGradient
                            disabled={disabled}
                            type="submit"
                          >
                            Save
                          </StyledButtonGradient>
                        </Box>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
